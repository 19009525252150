import React, { useState } from 'react';
import { Building2, Plus, Search, Edit2, Trash2 } from 'lucide-react';
import useProperties from '../../hooks/useProperties';
import { useProperty } from '../../contexts/PropertyContext';
import type { Property } from '../../types/property';

const PropertyList: React.FC = () => {
  const { properties = [], isLoading, selectedPropertyId, setSelectedPropertyId, refreshProperties } = useProperty();
  const { deleteProperty } = useProperties();
  const [searchQuery, setSearchQuery] = useState('');
  const [isDeleting, setIsDeleting] = useState<string | null>(null);

  const filteredProperties = properties?.filter(property =>
    property.name.toLowerCase().includes(searchQuery.toLowerCase()) ||
    property.location?.toLowerCase().includes(searchQuery.toLowerCase())
  ) || [];

  const handleDelete = async (property: Property) => {
    if (!window.confirm(`Are you sure you want to delete ${property.name}? This action cannot be undone.`)) {
      return;
    }

    try {
      setIsDeleting(property.id);
      await deleteProperty(property.id);
      await refreshProperties();
      
      // If deleted property was selected, clear selection
      if (selectedPropertyId === property.id) {
        setSelectedPropertyId(null);
      }
    } catch (error) {
      console.error('Failed to delete property:', error);
    } finally {
      setIsDeleting(null);
    }
  };

  const getPropertyTypeDisplay = (type: string = 'residential'): string => {
    const typeMap: Record<string, string> = {
      residential: 'Residential',
      commercial: 'Commercial',
      mixed: 'Mixed Use'
    };
    return typeMap[type] || 'Residential';
  };

  if (isLoading) {
    return (
      <div className="flex justify-center items-center h-64">
        <div className="animate-spin rounded-full h-8 w-8 border-b-2 border-blue-500"></div>
      </div>
    );
  }

  return (
    <div className="space-y-4">
      <div className="relative">
        <Search className="absolute left-3 top-1/2 transform -translate-y-1/2 text-gray-400" />
        <input
          type="text"
          placeholder="Search properties..."
          value={searchQuery}
          onChange={(e) => setSearchQuery(e.target.value)}
          className="w-full pl-10 pr-4 py-2 border rounded-lg focus:ring-2 focus:ring-blue-500 focus:border-transparent"
        />
      </div>

      {filteredProperties.length === 0 ? (
        <div className="text-center py-12 bg-white rounded-lg border border-gray-200">
          <Building2 className="mx-auto h-12 w-12 text-gray-400" />
          <h3 className="mt-2 text-sm font-medium text-gray-900">No properties</h3>
          <p className="mt-1 text-sm text-gray-500">
            Get started by creating a new property.
          </p>
        </div>
      ) : (
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6">
          {filteredProperties.map((property) => (
            <div
              key={property.id}
              className={`bg-white rounded-lg shadow-sm border ${
                selectedPropertyId === property.id
                  ? 'border-blue-500'
                  : 'border-gray-200'
              } hover:border-blue-500 transition-colors cursor-pointer`}
              onClick={() => setSelectedPropertyId(property.id)}
            >
              <div className="p-6">
                <div className="flex justify-between items-start">
                  <div>
                    <h3 className="text-lg font-semibold">{property.name}</h3>
                    {property.location && (
                      <p className="text-sm text-gray-500 mt-1">{property.location}</p>
                    )}
                  </div>
                  <div className="flex items-center space-x-2">
                    <button
                      onClick={(e) => {
                        e.stopPropagation();
                        handleDelete(property);
                      }}
                      disabled={isDeleting === property.id}
                      className={`p-1 text-red-400 hover:text-red-600 ${
                        isDeleting === property.id ? 'opacity-50 cursor-not-allowed' : ''
                      }`}
                    >
                      <Trash2 className="w-4 h-4" />
                    </button>
                  </div>
                </div>
                <div className="mt-4 flex items-center text-sm text-gray-500">
                  <Building2 className="w-4 h-4 mr-1" />
                  <span>{getPropertyTypeDisplay(property.type)}</span>
                  {property.units !== undefined && property.units > 0 && (
                    <>
                      <span className="mx-1">•</span>
                      <span>{property.units} units</span>
                    </>
                  )}
                </div>
              </div>
            </div>
          ))}
        </div>
      )}
    </div>
  );
};

export default PropertyList;