import { useState, useCallback, useEffect } from 'react';
import { supabase } from '../lib/supabase';
import { useProperty } from '../contexts/PropertyContext';
import type { Lead } from '../types/leads';

export const useLeads = () => {
  const [leads, setLeads] = useState<Lead[]>([]);
  const [archivedLeads, setArchivedLeads] = useState<Lead[]>([]);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState<string | null>(null);
  const { selectedPropertyId } = useProperty();

  const fetchLeads = useCallback(async () => {
    if (!selectedPropertyId) {
      setLeads([]);
      setArchivedLeads([]);
      setIsLoading(false);
      return;
    }

    try {
      setIsLoading(true);
      setError(null);

      // Fetch active and inactive leads
      const { data: activeData, error: activeError } = await supabase
        .from('leads')
        .select('*')
        .eq('property_id', selectedPropertyId)
        .is('archived_at', null)
        .order('created_at', { ascending: false });

      if (activeError) throw activeError;

      // Fetch archived leads
      const { data: archivedData, error: archivedError } = await supabase
        .from('leads')
        .select('*')
        .eq('property_id', selectedPropertyId)
        .not('archived_at', 'is', null)
        .order('archived_at', { ascending: false });

      if (archivedError) throw archivedError;

      setLeads(activeData || []);
      setArchivedLeads(archivedData || []);
    } catch (err) {
      console.error('Error fetching leads:', err);
      setError('Failed to load leads');
    } finally {
      setIsLoading(false);
    }
  }, [selectedPropertyId]);

  const addLead = async (lead: Omit<Lead, 'id' | 'property_id' | 'created_at' | 'updated_at' | 'contact_count' | 'last_contact_date'>) => {
    if (!selectedPropertyId) throw new Error('No property selected');

    try {
      const { data, error } = await supabase
        .from('leads')
        .insert([{
          ...lead,
          property_id: selectedPropertyId,
          contact_count: 0,
          last_contact_date: null,
          created_at: new Date().toISOString(),
          updated_at: new Date().toISOString()
        }])
        .select()
        .single();

      if (error) throw error;

      setLeads(prev => [data, ...prev]);
      return data;
    } catch (err) {
      console.error('Error adding lead:', err);
      throw err;
    }
  };

  const updateLead = async (id: string, updates: Partial<Lead>) => {
    try {
      const { data, error } = await supabase
        .from('leads')
        .update({
          ...updates,
          updated_at: new Date().toISOString()
        })
        .eq('id', id)
        .select()
        .single();

      if (error) throw error;

      if (data.archived_at) {
        setLeads(prev => prev.filter(lead => lead.id !== id));
        setArchivedLeads(prev => [data, ...prev]);
      } else {
        setLeads(prev => prev.map(lead => 
          lead.id === id ? data : lead
        ));
      }

      return data;
    } catch (err) {
      console.error('Error updating lead:', err);
      throw err;
    }
  };

  const archiveLead = async (id: string, reason: string) => {
    try {
      const { data, error } = await supabase
        .from('leads')
        .update({
          status: 'archived',
          archived_at: new Date().toISOString(),
          archive_reason: reason,
          updated_at: new Date().toISOString()
        })
        .eq('id', id)
        .select()
        .single();

      if (error) throw error;

      setLeads(prev => prev.filter(lead => lead.id !== id));
      setArchivedLeads(prev => [data, ...prev]);

      return data;
    } catch (err) {
      console.error('Error archiving lead:', err);
      throw err;
    }
  };

  const restoreLead = async (id: string) => {
    try {
      const { data, error } = await supabase
        .from('leads')
        .update({
          status: 'active',
          archived_at: null,
          archive_reason: null,
          updated_at: new Date().toISOString()
        })
        .eq('id', id)
        .select()
        .single();

      if (error) throw error;

      setArchivedLeads(prev => prev.filter(lead => lead.id !== id));
      setLeads(prev => [data, ...prev]);

      return data;
    } catch (err) {
      console.error('Error restoring lead:', err);
      throw err;
    }
  };

  const convertToApplication = async (lead: Lead) => {
    try {
      // First, create the application
      const { data: application, error: applicationError } = await supabase
        .from('applications')
        .insert([{
          property_id: selectedPropertyId,
          name: lead.full_name,
          email: lead.email,
          phone: lead.phone,
          unit_size: lead.preferred_size,
          notes: lead.notes,
          status: 'pending_screening',
          date_received: new Date().toISOString()
        }])
        .select()
        .single();

      if (applicationError) throw applicationError;

      // Then, update the lead status to inactive
      const { error: leadError } = await supabase
        .from('leads')
        .update({
          status: 'inactive',
          updated_at: new Date().toISOString()
        })
        .eq('id', lead.id);

      if (leadError) throw leadError;

      // Update local state
      setLeads(prev => prev.map(l => 
        l.id === lead.id ? { ...l, status: 'inactive' } : l
      ));

      return application;
    } catch (err) {
      console.error('Error converting lead to application:', err);
      throw err;
    }
  };

  useEffect(() => {
    fetchLeads();

    if (selectedPropertyId) {
      const channel = supabase
        .channel('leads_changes')
        .on('postgres_changes', 
          { 
            event: '*', 
            schema: 'public',
            table: 'leads',
            filter: `property_id=eq.${selectedPropertyId}`
          }, 
          () => {
            fetchLeads();
          }
        )
        .subscribe();

      return () => {
        supabase.removeChannel(channel);
      };
    }
  }, [selectedPropertyId, fetchLeads]);

  return {
    leads,
    archivedLeads,
    isLoading,
    error,
    addLead,
    updateLead,
    archiveLead,
    restoreLead,
    convertToApplication,
    refreshLeads: fetchLeads
  };
};

export default useLeads;