import { useRef, useCallback, useEffect } from 'react';

export const useScrollPosition = () => {
  const scrollPositionRef = useRef(0);
  const isRestoringRef = useRef(false);
  const rafRef = useRef<number>();

  useEffect(() => {
    const handleScroll = () => {
      if (!isRestoringRef.current) {
        scrollPositionRef.current = window.scrollY;
      }
    };

    window.addEventListener('scroll', handleScroll, { passive: true });
    return () => window.removeEventListener('scroll', handleScroll);
  }, []);

  const updateScroll = useCallback((callback: () => void) => {
    if (isRestoringRef.current) return;

    // Store current scroll position
    scrollPositionRef.current = window.scrollY;
    
    // Cancel any pending animation frame
    if (rafRef.current) {
      cancelAnimationFrame(rafRef.current);
    }

    // Execute the update
    callback();

    // Use triple RAF for more reliable DOM updates
    rafRef.current = requestAnimationFrame(() => {
      rafRef.current = requestAnimationFrame(() => {
        rafRef.current = requestAnimationFrame(() => {
          isRestoringRef.current = true;
          window.scrollTo(0, scrollPositionRef.current);
          requestAnimationFrame(() => {
            isRestoringRef.current = false;
          });
        });
      });
    });
  }, []);

  // Cleanup function
  const cleanup = useCallback(() => {
    if (rafRef.current) {
      cancelAnimationFrame(rafRef.current);
    }
  }, []);

  return { updateScroll, cleanup };
};

export default useScrollPosition;