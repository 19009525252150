import { useState, useCallback, useEffect } from 'react';
import { supabase } from '../lib/supabase';
import { StorageError } from '@supabase/storage-js';

interface StorageState {
  isInitialized: boolean;
  isInitializing: boolean;
  error: string | null;
}

export const useStorage = () => {
  const [state, setState] = useState<StorageState>({
    isInitialized: false,
    isInitializing: false,
    error: null,
  });

  const initStorage = useCallback(async () => {
    if (state.isInitializing) return;

    setState(prev => ({ ...prev, isInitializing: true, error: null }));

    try {
      // Create or get staging bucket
      const { data: { publicUrl } = {}, error: bucketError } = await supabase.storage
        .from('staging')
        .getPublicUrl('test.txt');

      if (bucketError) {
        // Bucket might not exist, try to create it
        const { error: createError } = await supabase.storage.createBucket('staging', {
          public: true,
          fileSizeLimit: 5242880,
          allowedMimeTypes: ['image/jpeg', 'image/png']
        });

        if (createError && createError.message !== 'Bucket already exists') {
          throw createError;
        }
      }

      setState({
        isInitialized: true,
        isInitializing: false,
        error: null,
      });
    } catch (error) {
      console.error('Storage initialization failed:', error);
      setState({
        isInitialized: false,
        isInitializing: false,
        error: error instanceof Error ? error.message : 'Failed to initialize storage',
      });
    }
  }, [state.isInitializing]);

  useEffect(() => {
    initStorage();
  }, [initStorage]);

  const uploadFile = useCallback(async (file: File): Promise<string> => {
    if (!state.isInitialized) {
      await initStorage();
    }

    const fileExt = file.name.split('.').pop()?.toLowerCase();
    if (!fileExt || !['jpg', 'jpeg', 'png'].includes(fileExt)) {
      throw new Error('Invalid file type. Only JPG and PNG files are allowed.');
    }

    if (file.size > 5242880) {
      throw new Error('File size exceeds 5MB limit.');
    }

    const fileName = `${Date.now()}_${Math.random().toString(36).substring(2)}.${fileExt}`;
    const filePath = `avatars/${fileName}`;

    try {
      const { data, error } = await supabase.storage
        .from('staging')
        .upload(filePath, file, {
          cacheControl: '3600',
          contentType: file.type,
          upsert: true
        });

      if (error) throw error;
      if (!data?.path) throw new Error('Upload failed - no path returned');

      const { data: urlData } = supabase.storage
        .from('staging')
        .getPublicUrl(data.path);

      return urlData.publicUrl;
    } catch (error) {
      if (error instanceof StorageError) {
        throw new Error(`Upload failed: ${error.message}`);
      }
      throw new Error('Upload failed with unknown error');
    }
  }, [state.isInitialized, initStorage]);

  return {
    ...state,
    uploadFile,
    retryInitialization: initStorage
  };
};

export default useStorage;