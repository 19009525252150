import React, { useState } from 'react';
import { X, Search, Calendar, Clock, RefreshCw, User } from 'lucide-react';
import Toggle from '../ui/Toggle';
import EditableField from '../ui/EditableField';

interface CompletedTask {
  id: string;
  title: string;
  time?: string;
  priority: 'high' | 'medium' | 'low';
  completed: boolean;
  completedAt: string;
  assignee: string;
  type: 'daily' | 'todo';
}

interface CompletedTasksModalProps {
  isOpen: boolean;
  onClose: () => void;
  tasks: CompletedTask[];
  onRestore: (taskId: string) => void;
  assignees: string[];
}

const CompletedTasksModal: React.FC<CompletedTasksModalProps> = ({
  isOpen,
  onClose,
  tasks,
  onRestore,
  assignees,
}) => {
  const [searchQuery, setSearchQuery] = useState('');
  const [filter, setFilter] = useState<'all' | 'daily' | 'todo'>('all');
  const [sortBy, setSortBy] = useState<'date' | 'priority'>('date');
  const [error, setError] = useState<string | null>(null);
  const [isRestoring, setIsRestoring] = useState<string | null>(null);

  const filteredTasks = tasks
    .filter(task => {
      const matchesSearch = task.title.toLowerCase().includes(searchQuery.toLowerCase());
      const matchesFilter = filter === 'all' || task.type === filter;
      return matchesSearch && matchesFilter;
    })
    .sort((a, b) => {
      if (sortBy === 'date') {
        return new Date(b.completedAt).getTime() - new Date(a.completedAt).getTime();
      }
      const priorityOrder = { high: 3, medium: 2, low: 1 };
      return priorityOrder[b.priority] - priorityOrder[a.priority];
    });

  const handleRestore = async (taskId: string) => {
    try {
      setError(null);
      setIsRestoring(taskId);
      await onRestore(taskId);
    } catch (err) {
      setError('Failed to restore task. Please try again.');
    } finally {
      setIsRestoring(null);
    }
  };

  if (!isOpen) return null;

  return (
    <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50">
      <div className="bg-white rounded-lg p-6 w-full max-w-4xl max-h-[80vh] overflow-y-auto">
        <div className="flex justify-between items-center mb-6">
          <h2 className="text-2xl font-bold">Completed Tasks</h2>
          <button onClick={onClose} className="text-gray-500 hover:text-gray-700">
            <X size={24} />
          </button>
        </div>

        <div className="mb-6 space-y-4">
          <div className="flex items-center gap-4">
            <div className="flex-1">
              <div className="relative">
                <Search className="absolute left-3 top-1/2 transform -translate-y-1/2 text-gray-400" size={20} />
                <input
                  type="text"
                  placeholder="Search completed tasks..."
                  value={searchQuery}
                  onChange={(e) => setSearchQuery(e.target.value)}
                  className="w-full pl-10 pr-4 py-2 border rounded-lg focus:ring-2 focus:ring-blue-500 focus:border-transparent"
                />
              </div>
            </div>
            <select
              value={filter}
              onChange={(e) => setFilter(e.target.value as 'all' | 'daily' | 'todo')}
              className="border rounded-lg px-3 py-2 focus:ring-2 focus:ring-blue-500 focus:border-transparent"
            >
              <option value="all">All Tasks</option>
              <option value="daily">Daily Tasks</option>
              <option value="todo">Todo List</option>
            </select>
            <select
              value={sortBy}
              onChange={(e) => setSortBy(e.target.value as 'date' | 'priority')}
              className="border rounded-lg px-3 py-2 focus:ring-2 focus:ring-blue-500 focus:border-transparent"
            >
              <option value="date">Sort by Date</option>
              <option value="priority">Sort by Priority</option>
            </select>
          </div>
        </div>

        <div className="space-y-4">
          {filteredTasks.map((task) => (
            <div
              key={task.id}
              className="flex items-center justify-between p-4 bg-gray-50 rounded-lg border border-gray-200"
            >
              <div className="flex items-center space-x-4 flex-1">
                <Toggle checked={true} onChange={() => {}} disabled />
                <div>
                  <h3 className="font-medium text-gray-900 line-through">{task.title}</h3>
                  <div className="flex items-center gap-4 mt-1 text-sm text-gray-500">
                    <div className="flex items-center">
                      <User className="w-4 h-4 mr-1" />
                      <span>{task.assignee}</span>
                    </div>
                    {task.time && (
                      <div className="flex items-center">
                        <Clock className="w-4 h-4 mr-1" />
                        <span>{task.time}</span>
                      </div>
                    )}
                    <div className="flex items-center">
                      <Calendar className="w-4 h-4 mr-1" />
                      <span>Completed {new Date(task.completedAt).toLocaleString()}</span>
                    </div>
                  </div>
                </div>
              </div>
              <button
                onClick={() => handleRestore(task.id)}
                disabled={isRestoring === task.id}
                className={`flex items-center px-3 py-1 text-sm text-blue-600 hover:bg-blue-50 rounded-md transition-colors ${
                  isRestoring === task.id ? 'opacity-50 cursor-not-allowed' : ''
                }`}
              >
                <RefreshCw className={`w-4 h-4 mr-1 ${isRestoring === task.id ? 'animate-spin' : ''}`} />
                {isRestoring === task.id ? 'Restoring...' : 'Restore'}
              </button>
            </div>
          ))}

          {filteredTasks.length === 0 && (
            <div className="text-center py-8 text-gray-500">
              No completed tasks found
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default CompletedTasksModal;