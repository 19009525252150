import { z } from 'zod';

const envSchema = z.object({
  VITE_SUPABASE_URL: z.string().url(),
  VITE_SUPABASE_ANON_KEY: z.string(),
  VITE_SMTP_HOST: z.string(),
  VITE_SMTP_PORT: z.string().transform(Number),
  VITE_SMTP_USER: z.string().email(),
  VITE_SMTP_PASS: z.string(),
  VITE_SMTP_FROM_NAME: z.string()
});

// Load environment variables based on runtime environment
const loadEnvVars = () => {
  if (typeof window !== 'undefined') {
    // Browser environment - use import.meta.env
    return {
      VITE_SUPABASE_URL: import.meta.env.VITE_SUPABASE_URL,
      VITE_SUPABASE_ANON_KEY: import.meta.env.VITE_SUPABASE_ANON_KEY,
      VITE_SMTP_HOST: import.meta.env.VITE_SMTP_HOST,
      VITE_SMTP_PORT: import.meta.env.VITE_SMTP_PORT,
      VITE_SMTP_USER: import.meta.env.VITE_SMTP_USER,
      VITE_SMTP_PASS: import.meta.env.VITE_SMTP_PASS,
      VITE_SMTP_FROM_NAME: import.meta.env.VITE_SMTP_FROM_NAME
    };
  } else {
    // Node.js environment - use process.env
    return {
      VITE_SUPABASE_URL: process.env.VITE_SUPABASE_URL!,
      VITE_SUPABASE_ANON_KEY: process.env.VITE_SUPABASE_ANON_KEY!,
      VITE_SMTP_HOST: process.env.VITE_SMTP_HOST!,
      VITE_SMTP_PORT: process.env.VITE_SMTP_PORT!,
      VITE_SMTP_USER: process.env.VITE_SMTP_USER!,
      VITE_SMTP_PASS: process.env.VITE_SMTP_PASS!,
      VITE_SMTP_FROM_NAME: process.env.VITE_SMTP_FROM_NAME!
    };
  }
};

const validateEnv = () => {
  try {
    const envVars = loadEnvVars();
    return envSchema.parse(envVars);
  } catch (error) {
    if (error instanceof z.ZodError) {
      console.error('Environment validation failed:', error.errors);
      throw new Error('Invalid environment configuration');
    }
    throw error;
  }
};

export const env = validateEnv();