import { env } from './env';

export const emailConfig = {
  smtp: {
    host: env.VITE_SMTP_HOST,
    port: env.VITE_SMTP_PORT,
    secure: false, // true for 465, false for other ports
    auth: {
      user: env.VITE_SMTP_USER,
      pass: env.VITE_SMTP_PASS
    },
    // Additional security and timeout settings
    tls: {
      rejectUnauthorized: true
    },
    connectionTimeout: 5000, // 5 seconds
    greetingTimeout: 5000,
    socketTimeout: 10000
  },
  from: `"${env.VITE_SMTP_FROM_NAME}" <${env.VITE_SMTP_USER}>`
} as const;