import React, { useState, useRef, useEffect } from 'react';
import { ChevronDown, Plus, Loader2 } from 'lucide-react';
import { useNavigate } from 'react-router-dom';
import { useProperty } from '../../contexts/PropertyContext';
import useProperties from '../../hooks/useProperties';

const PropertySelector: React.FC = () => {
  const [isOpen, setIsOpen] = useState(false);
  const [isAdding, setIsAdding] = useState(false);
  const [newPropertyName, setNewPropertyName] = useState('');
  const [newPropertyLocation, setNewPropertyLocation] = useState('');
  const [error, setError] = useState<string | null>(null);
  const dropdownRef = useRef<HTMLDivElement>(null);
  const navigate = useNavigate();
  const { selectedPropertyId, setSelectedPropertyId, properties, isLoading, refreshProperties } = useProperty();
  const { createProperty, isCreating } = useProperties();

  const selectedProperty = properties.find(p => p.id === selectedPropertyId);

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target as Node)) {
        setIsOpen(false);
        setIsAdding(false);
      }
    };

    if (isOpen) {
      document.addEventListener('mousedown', handleClickOutside);
    }

    return () => window.removeEventListener('mousedown', handleClickOutside);
  }, [isOpen]);

  const handlePropertySelect = (propertyId: string) => {
    setSelectedPropertyId(propertyId);
    setIsOpen(false);
  };

  const handleAddProperty = async (e: React.FormEvent) => {
    e.preventDefault();
    if (!newPropertyName.trim()) return;

    try {
      setError(null);
      const newProperty = await createProperty(newPropertyName, newPropertyLocation);
      
      if (newProperty) {
        await refreshProperties();
        setSelectedPropertyId(newProperty.id);
        setNewPropertyName('');
        setNewPropertyLocation('');
        setIsAdding(false);
        setIsOpen(false);
      }
    } catch (err) {
      console.error('Error adding property:', err);
      setError('Failed to add property');
    }
  };

  if (isLoading) {
    return (
      <div className="flex items-center px-3 py-2">
        <Loader2 className="w-5 h-5 text-lightBlue animate-spin" />
      </div>
    );
  }

  return (
    <div className="relative" ref={dropdownRef}>
      <button
        onClick={() => setIsOpen(!isOpen)}
        className="flex items-center space-x-2 px-3 py-2 text-lightBlue hover:bg-sidebar-hover rounded-md transition-colors w-full"
        aria-expanded={isOpen}
        aria-haspopup="true"
      >
        <span className="max-w-[200px] truncate">
          {selectedProperty ? selectedProperty.name : 'Select Property'}
        </span>
        <ChevronDown className={`w-5 h-5 transform transition-transform ${isOpen ? 'rotate-180' : ''}`} />
      </button>

      {isOpen && (
        <div
          className="absolute left-0 mt-2 w-64 bg-sidebar-bg rounded-md shadow-lg z-50 border border-marianBlue/10"
          role="menu"
          aria-orientation="vertical"
        >
          <div className="py-1">
            {properties.map((property) => (
              <button
                key={property.id}
                onClick={() => handlePropertySelect(property.id)}
                className={`w-full text-left px-4 py-2 text-sm hover:bg-sidebar-hover ${
                  property.id === selectedPropertyId ? 'bg-sidebar-active text-lightBlue' : 'text-lightBlue/70'
                }`}
                role="menuitem"
              >
                {property.name}
                {property.location && (
                  <span className="text-lightBlue/50 text-xs block">
                    {property.location}
                  </span>
                )}
              </button>
            ))}

            <div className="border-t border-marianBlue/10 mt-1">
              {isAdding ? (
                <form onSubmit={handleAddProperty} className="p-4 space-y-3">
                  {error && (
                    <p className="text-xs text-red-400 mb-2">{error}</p>
                  )}
                  <input
                    type="text"
                    value={newPropertyName}
                    onChange={(e) => setNewPropertyName(e.target.value)}
                    placeholder="Property Name"
                    className="w-full px-3 py-1.5 text-sm bg-sidebar-hover text-lightBlue rounded border border-marianBlue/20 focus:border-lightBlue focus:ring-1 focus:ring-lightBlue"
                    autoFocus
                  />
                  <input
                    type="text"
                    value={newPropertyLocation}
                    onChange={(e) => setNewPropertyLocation(e.target.value)}
                    placeholder="Location (optional)"
                    className="w-full px-3 py-1.5 text-sm bg-sidebar-hover text-lightBlue rounded border border-marianBlue/20 focus:border-lightBlue focus:ring-1 focus:ring-lightBlue"
                  />
                  <div className="flex justify-end space-x-2">
                    <button
                      type="button"
                      onClick={() => setIsAdding(false)}
                      className="px-3 py-1 text-sm text-lightBlue/70 hover:text-lightBlue"
                      disabled={isCreating}
                    >
                      Cancel
                    </button>
                    <button
                      type="submit"
                      className="px-3 py-1 text-sm bg-lightBlue text-darkPurple rounded hover:bg-lightBlue/90 disabled:opacity-50"
                      disabled={isCreating}
                    >
                      {isCreating ? 'Adding...' : 'Add Property'}
                    </button>
                  </div>
                </form>
              ) : (
                <button
                  onClick={() => setIsAdding(true)}
                  className="w-full text-left px-4 py-2 text-sm text-lightBlue/70 hover:bg-sidebar-hover flex items-center"
                  role="menuitem"
                >
                  <Plus className="w-4 h-4 mr-2" />
                  Add New Property
                </button>
              )}
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default PropertySelector;