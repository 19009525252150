import React from 'react';
import { ArrowRight, Loader2 } from 'lucide-react';

interface SubmitButtonProps {
  isSubmitting: boolean;
  children: React.ReactNode;
}

const SubmitButton: React.FC<SubmitButtonProps> = ({ isSubmitting, children }) => {
  return (
    <button
      type="submit"
      disabled={isSubmitting}
      className="w-full flex justify-center items-center py-2 px-4 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-blue-600 hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500 disabled:opacity-50 disabled:cursor-not-allowed"
    >
      {isSubmitting ? (
        <Loader2 className="animate-spin h-5 w-5" />
      ) : (
        <>
          {children}
          <ArrowRight className="ml-2 h-5 w-5" />
        </>
      )}
    </button>
  );
};

export default SubmitButton;