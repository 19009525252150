import React, { useState, useCallback } from 'react';
import FilterBar from '../components/turn-board/FilterBar';
import ActionBar from '../components/turn-board/ActionBar';
import UnitList from '../components/turn-board/UnitList';
import AddUnitModal from '../components/AddUnitModal';
import { downloadCSV } from '../utils/csvExport';
import useTurnBoard from '../hooks/useTurnBoard';
import useFilteredUnits from '../hooks/useFilteredUnits';
import { useProperty } from '../contexts/PropertyContext';
import type { Unit } from '../types/units';

const TurnBoard: React.FC = () => {
  const { selectedPropertyId } = useProperty();
  const { 
    units, 
    isLoading, 
    error, 
    addUnit, 
    updateUnit,
    isEditing 
  } = useTurnBoard(selectedPropertyId);
  
  const [isAddModalOpen, setIsAddModalOpen] = useState(false);
  const [expandedUnits, setExpandedUnits] = useState<number[]>([]);
  const [filter, setFilter] = useState<string>('');
  const [sortBy, setSortBy] = useState<'aptNumber' | 'moveInDate'>('aptNumber');

  const filteredUnits = useFilteredUnits(units, filter, sortBy);

  const handleAddUnit = useCallback(async (newUnit: Omit<Unit, 'id'>) => {
    try {
      if (!selectedPropertyId) {
        console.error('Cannot add unit: No property selected');
        return;
      }

      await addUnit(newUnit);
      setIsAddModalOpen(false);
    } catch (err) {
      console.error('Failed to add unit:', err);
    }
  }, [selectedPropertyId, addUnit]);

  const handleUnitChange = useCallback(async (id: number, field: keyof Unit, value: any) => {
    try {
      await updateUnit(id, { [field]: value });
    } catch (err) {
      console.error('Failed to update unit:', err);
    }
  }, [updateUnit]);

  const handleToggleExpand = useCallback((id: number) => {
    setExpandedUnits(prev =>
      prev.includes(id) ? prev.filter(unitId => unitId !== id) : [...prev, id]
    );
  }, []);

  if (error) {
    return (
      <div className="bg-red-50 border border-red-200 rounded-lg p-4">
        <h2 className="text-red-800 font-semibold mb-2">Error Loading Turn Board</h2>
        <p className="text-red-600">{error}</p>
      </div>
    );
  }

  if (!selectedPropertyId) {
    return (
      <div className="text-center py-8 text-gray-500">
        Please select a property from the menu to view its turn board
      </div>
    );
  }

  return (
    <div className="space-y-4 max-w-7xl mx-auto">
      <div className="flex flex-wrap items-center gap-4 justify-between">
        <FilterBar
          filter={filter}
          sortBy={sortBy}
          onFilterChange={setFilter}
          onSortChange={setSortBy}
          disabled={isEditing}
        />
        <ActionBar
          onExport={downloadCSV}
          onAddUnit={() => setIsAddModalOpen(true)}
          units={units}
          disabled={isEditing}
        />
      </div>

      {/* Category Labels */}
      <div className="grid grid-cols-5 gap-4 px-4 py-3 bg-gray-100 rounded-t-lg font-bold text-base text-gray-700">
        <div>Apt Number</div>
        <div>Move-in Date</div>
        <div>Future Resident</div>
        <div>Remaining</div>
        <div>Progress</div>
      </div>

      {isLoading ? (
        <div className="flex justify-center items-center h-32">
          <div className="animate-spin rounded-full h-8 w-8 border-b-2 border-blue-500"></div>
        </div>
      ) : (
        <UnitList
          units={filteredUnits}
          expandedUnits={expandedUnits}
          onToggleExpand={handleToggleExpand}
          onUnitChange={handleUnitChange}
        />
      )}

      <AddUnitModal
        isOpen={isAddModalOpen}
        onClose={() => setIsAddModalOpen(false)}
        onAddUnit={handleAddUnit}
      />
    </div>
  );
};

export default TurnBoard;