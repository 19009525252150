import React, { useState } from 'react';
import { Search, RefreshCw, Calendar } from 'lucide-react';
import LeadCard from './LeadCard';
import type { Lead } from '../../types/leads';

interface ArchivedLeadsProps {
  leads: Lead[];
  onRestore: (id: string) => Promise<void>;
}

const ArchivedLeads: React.FC<ArchivedLeadsProps> = ({
  leads,
  onRestore
}) => {
  const [searchQuery, setSearchQuery] = useState('');
  const [isRestoring, setIsRestoring] = useState<string | null>(null);

  const filteredLeads = leads.filter(lead =>
    lead.full_name.toLowerCase().includes(searchQuery.toLowerCase()) ||
    lead.archive_reason?.toLowerCase().includes(searchQuery.toLowerCase())
  );

  const handleRestore = async (id: string) => {
    try {
      setIsRestoring(id);
      await onRestore(id);
    } catch (error) {
      console.error('Error restoring lead:', error);
    } finally {
      setIsRestoring(null);
    }
  };

  return (
    <div className="space-y-4">
      <div className="flex items-center gap-4">
        <div className="relative flex-1">
          <Search className="absolute left-3 top-1/2 transform -translate-y-1/2 text-gray-400" />
          <input
            type="text"
            placeholder="Search archived leads..."
            value={searchQuery}
            onChange={(e) => setSearchQuery(e.target.value)}
            className="w-full pl-10 pr-4 py-2 border rounded-lg focus:ring-2 focus:ring-blue-500 focus:border-transparent"
          />
        </div>
      </div>

      {filteredLeads.length === 0 ? (
        <div className="text-center py-8 text-gray-500">
          No archived leads found
        </div>
      ) : (
        <div className="space-y-4">
          {filteredLeads.map((lead) => (
            <div key={lead.id} className="relative">
              <LeadCard
                lead={lead}
                onUpdate={() => {}}
                onArchive={() => {}}
                onEdit={() => {}}
              />
              <div className="mt-2 p-4 bg-gray-50 rounded-lg border border-gray-200">
                <div className="flex items-center justify-between">
                  <div className="space-y-1">
                    <div className="flex items-center text-sm text-gray-500">
                      <Calendar className="w-4 h-4 mr-2" />
                      Archived on {new Date(lead.archived_at!).toLocaleDateString()}
                    </div>
                    <div className="text-sm text-gray-600">
                      <strong>Reason:</strong> {lead.archive_reason}
                    </div>
                  </div>
                  <button
                    onClick={() => handleRestore(lead.id)}
                    className="flex items-center px-3 py-1 text-sm text-blue-600 hover:bg-blue-50 rounded-md transition-colors"
                    disabled={isRestoring === lead.id}
                  >
                    <RefreshCw className={`w-4 h-4 mr-1 ${isRestoring === lead.id ? 'animate-spin' : ''}`} />
                    Restore
                  </button>
                </div>
              </div>
            </div>
          ))}
        </div>
      )}
    </div>
  );
};

export default ArchivedLeads;