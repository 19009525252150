import React, { useRef, useEffect } from 'react';
import { Menu, X } from 'lucide-react';
import SidebarNav from './SidebarNav';
import useScrollLock from '../../hooks/useScrollLock';

interface MobileNavProps {
  isOpen: boolean;
  onToggle: () => void;
}

const MobileNav: React.FC<MobileNavProps> = ({ isOpen, onToggle }) => {
  const menuRef = useRef<HTMLDivElement>(null);
  const { lockScroll, unlockScroll } = useScrollLock();

  useEffect(() => {
    const handleClickOutside = (e: MouseEvent) => {
      if (menuRef.current && !menuRef.current.contains(e.target as Node)) {
        onToggle();
      }
    };

    if (isOpen) {
      document.addEventListener('mousedown', handleClickOutside);
      lockScroll();
    }

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
      unlockScroll();
    };
  }, [isOpen, onToggle, lockScroll, unlockScroll]);

  return (
    <>
      {/* Hamburger Menu Button */}
      <button
        onClick={onToggle}
        className="fixed top-4 right-4 z-50 p-3 rounded-lg bg-spaceCadet text-lightBlue hover:bg-marianBlue transition-colors md:hidden"
        aria-label="Toggle menu"
        aria-expanded={isOpen}
      >
        {isOpen ? <X size={24} /> : <Menu size={24} />}
      </button>

      {/* Mobile Menu */}
      <div
        ref={menuRef}
        className={`
          fixed inset-y-0 right-0 w-[280px]
          bg-spaceCadet shadow-lg 
          transform transition-transform duration-300 ease-in-out 
          md:hidden z-40
          ${isOpen ? 'translate-x-0' : 'translate-x-full'}
        `}
      >
        <div className="h-full overflow-y-auto">
          <SidebarNav 
            isExpanded={true}
            onToggleExpand={() => {}}
            isMobile={true}
          />
        </div>
      </div>

      {/* Backdrop */}
      {isOpen && (
        <div 
          className="fixed inset-0 bg-black/50 backdrop-blur-sm z-30 md:hidden"
          aria-hidden="true"
          onClick={onToggle}
        />
      )}
    </>
  );
};

export default MobileNav;