import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Plus, Search, Filter, Archive } from 'lucide-react';
import useLeads from '../hooks/useLeads';
import { useProperty } from '../contexts/PropertyContext';
import NewLeadModal from '../components/leads/NewLeadModal';
import EditLeadModal from '../components/leads/EditLeadModal';
import ArchiveLeadModal from '../components/leads/ArchiveLeadModal';
import ArchivedLeads from '../components/leads/ArchivedLeads';
import LeadCard from '../components/leads/LeadCard';
import ImportLeadsButton from '../components/leads/ImportLeadsButton';
import type { Lead } from '../types/leads';

const Leads: React.FC = () => {
  const navigate = useNavigate();
  const { selectedPropertyId } = useProperty();
  const {
    leads,
    archivedLeads,
    isLoading,
    error,
    addLead,
    updateLead,
    archiveLead,
    restoreLead,
    convertToApplication
  } = useLeads();
  
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [editingLead, setEditingLead] = useState<Lead | null>(null);
  const [archivingLead, setArchivingLead] = useState<Lead | null>(null);
  const [searchQuery, setSearchQuery] = useState('');
  const [statusFilter, setStatusFilter] = useState<'all' | 'active' | 'inactive'>('all');
  const [showArchived, setShowArchived] = useState(false);
  const [sortBy, setSortBy] = useState<'name' | 'lastContact' | 'createdAt'>('lastContact');

  const filteredAndSortedLeads = React.useMemo(() => {
    return leads
      .filter(lead => {
        const matchesSearch = 
          lead.full_name.toLowerCase().includes(searchQuery.toLowerCase()) ||
          (lead.email?.toLowerCase().includes(searchQuery.toLowerCase())) ||
          (lead.phone?.includes(searchQuery));
        const matchesStatus = statusFilter === 'all' || lead.status === statusFilter;
        return matchesSearch && matchesStatus;
      })
      .sort((a, b) => {
        switch (sortBy) {
          case 'name':
            return a.full_name.localeCompare(b.full_name);
          case 'createdAt':
            return new Date(b.created_at).getTime() - new Date(a.created_at).getTime();
          case 'lastContact':
            // Sort by last contact date, with null dates at the end
            if (!a.last_contact_date) return 1;
            if (!b.last_contact_date) return -1;
            return new Date(b.last_contact_date).getTime() - new Date(a.last_contact_date).getTime();
          default:
            return 0;
        }
      });
  }, [leads, searchQuery, statusFilter, sortBy]);

  const handleEditLead = async (id: string, updates: Partial<Lead>) => {
    try {
      await updateLead(id, updates);
      setEditingLead(null);
    } catch (error) {
      console.error('Error updating lead:', error);
    }
  };

  const handleArchiveLead = async (id: string, reason: string) => {
    try {
      await archiveLead(id, reason);
      setArchivingLead(null);
    } catch (error) {
      console.error('Error archiving lead:', error);
    }
  };

  const handleConvertToApplication = async (lead: Lead) => {
    try {
      await convertToApplication(lead);
      setEditingLead(null);
      navigate('/applications');
    } catch (error) {
      console.error('Error converting lead to application:', error);
    }
  };

  if (error) {
    return (
      <div className="p-4 bg-red-50 border border-red-200 rounded-lg">
        <p className="text-red-600">{error}</p>
      </div>
    );
  }

  return (
    <div className="space-y-6">
      <div className="flex justify-between items-center">
        <div className="flex items-center space-x-4">
          <h1 className="text-2xl font-bold">Leads</h1>
          <button
            onClick={() => setShowArchived(!showArchived)}
            className={`flex items-center px-3 py-1 text-sm rounded-md transition-colors ${
              showArchived
                ? 'bg-gray-100 text-gray-700'
                : 'text-gray-600 hover:bg-gray-50'
            }`}
          >
            <Archive className="w-4 h-4 mr-1" />
            {showArchived ? 'Show Active' : 'Show Archived'}
          </button>
        </div>
        <div className="flex items-center space-x-2">
          <ImportLeadsButton onImport={addLead} />
          <button
            onClick={() => setIsModalOpen(true)}
            className="flex items-center px-4 py-2 bg-blue-600 text-white rounded-lg hover:bg-blue-700 transition-colors"
          >
            <Plus className="w-5 h-5 mr-2" />
            Add Lead
          </button>
        </div>
      </div>

      {!showArchived && (
        <div className="flex flex-col sm:flex-row gap-4">
          <div className="flex-1 relative">
            <Search className="absolute left-3 top-1/2 transform -translate-y-1/2 text-gray-400" />
            <input
              type="text"
              placeholder="Search leads..."
              value={searchQuery}
              onChange={(e) => setSearchQuery(e.target.value)}
              className="w-full pl-10 pr-4 py-2 border rounded-lg focus:ring-2 focus:ring-blue-500 focus:border-transparent"
            />
          </div>
          <select
            value={statusFilter}
            onChange={(e) => setStatusFilter(e.target.value as 'all' | 'active' | 'inactive')}
            className="px-3 py-2 border rounded-lg focus:ring-2 focus:ring-blue-500 focus:border-transparent"
          >
            <option value="all">All Status</option>
            <option value="active">Active</option>
            <option value="inactive">Inactive</option>
          </select>
          <select
            value={sortBy}
            onChange={(e) => setSortBy(e.target.value as 'name' | 'lastContact' | 'createdAt')}
            className="px-3 py-2 border rounded-lg focus:ring-2 focus:ring-blue-500 focus:border-transparent"
          >
            <option value="lastContact">Sort by Last Contact</option>
            <option value="name">Sort by Name</option>
            <option value="createdAt">Sort by Creation Date</option>
          </select>
        </div>
      )}

      {isLoading ? (
        <div className="flex justify-center items-center h-64">
          <div className="animate-spin rounded-full h-8 w-8 border-b-2 border-blue-500"></div>
        </div>
      ) : showArchived ? (
        <ArchivedLeads
          leads={archivedLeads}
          onRestore={restoreLead}
        />
      ) : (
        <div className="space-y-4">
          {filteredAndSortedLeads.map((lead) => (
            <LeadCard
              key={lead.id}
              lead={lead}
              onEdit={setEditingLead}
              onArchive={setArchivingLead}
            />
          ))}
          {filteredAndSortedLeads.length === 0 && (
            <div className="text-center py-8 text-gray-500">
              No leads found
            </div>
          )}
        </div>
      )}

      <NewLeadModal
        isOpen={isModalOpen}
        onClose={() => setIsModalOpen(false)}
        onSubmit={addLead}
      />

      {editingLead && (
        <EditLeadModal
          isOpen={true}
          onClose={() => setEditingLead(null)}
          onSubmit={handleEditLead}
          onConvertToApplication={handleConvertToApplication}
          lead={editingLead}
        />
      )}

      {archivingLead && (
        <ArchiveLeadModal
          isOpen={true}
          onClose={() => setArchivingLead(null)}
          onArchive={handleArchiveLead}
          lead={archivingLead}
        />
      )}
    </div>
  );
};

export default Leads;