import React, { useState } from 'react';
import { startOfWeek, format } from 'date-fns';
import { Calendar, ArrowLeft, ArrowRight, Download } from 'lucide-react';
import WeeklyScorecard from '../components/performance/WeeklyScorecard';
import { downloadWeeklySummary } from '../utils/weeklyExport';
import { useProperty } from '../contexts/PropertyContext';
import useWeeklyMetrics from '../hooks/useWeeklyMetrics';

const WeeklyPerformance: React.FC = () => {
  const [selectedDate, setSelectedDate] = useState(new Date());
  const { selectedPropertyId } = useProperty();
  const startDate = startOfWeek(selectedDate, { weekStartsOn: 1 });
  
  const {
    metrics,
    isLoading,
    error,
    updateMetric
  } = useWeeklyMetrics(selectedPropertyId, selectedDate);

  const handlePreviousWeek = () => {
    setSelectedDate(date => {
      const newDate = new Date(date);
      newDate.setDate(date.getDate() - 7);
      return newDate;
    });
  };

  const handleNextWeek = () => {
    setSelectedDate(date => {
      const newDate = new Date(date);
      newDate.setDate(date.getDate() + 7);
      return newDate;
    });
  };

  const handleExport = () => {
    downloadWeeklySummary(metrics, startDate);
  };

  if (!selectedPropertyId) {
    return (
      <div className="text-center py-8 text-gray-500">
        Please select a property to view weekly performance metrics
      </div>
    );
  }

  if (error) {
    return (
      <div className="bg-red-50 border border-red-200 rounded-lg p-4">
        <p className="text-red-800">{error}</p>
      </div>
    );
  }

  return (
    <div className="space-y-6">
      <div className="flex justify-between items-center">
        <h1 className="text-2xl font-bold">Weekly Performance</h1>
        <button
          onClick={handleExport}
          className="flex items-center px-4 py-2 bg-blue-600 text-white rounded-lg hover:bg-blue-700 transition-colors"
        >
          <Download className="w-5 h-5 mr-2" />
          Export Week
        </button>
      </div>

      <div className="flex items-center justify-between bg-white p-4 rounded-lg shadow-sm">
        <button
          onClick={handlePreviousWeek}
          className="p-2 hover:bg-gray-100 rounded-full"
        >
          <ArrowLeft className="w-5 h-5" />
        </button>
        
        <div className="flex items-center">
          <Calendar className="w-5 h-5 mr-2 text-blue-600" />
          <span className="text-lg font-semibold">
            Week of {format(startDate, 'MMM d, yyyy')}
          </span>
        </div>

        <button
          onClick={handleNextWeek}
          className="p-2 hover:bg-gray-100 rounded-full"
        >
          <ArrowRight className="w-5 h-5" />
        </button>
      </div>

      <WeeklyScorecard
        startDate={startDate}
        metrics={metrics}
        onMetricChange={updateMetric}
        isLoading={isLoading}
      />
    </div>
  );
};

export default WeeklyPerformance;