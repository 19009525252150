import React, { useState } from 'react';
import { Save, X, Plus, Minus } from 'lucide-react';

interface ContactCountEditorProps {
  count: number;
  onUpdate: (newCount: number) => Promise<void>;
}

const ContactCountEditor: React.FC<ContactCountEditorProps> = ({ count, onUpdate }) => {
  const [isEditing, setIsEditing] = useState(false);
  const [editValue, setEditValue] = useState(count.toString());
  const [isUpdating, setIsUpdating] = useState(false);
  const [error, setError] = useState<string | null>(null);

  const handleSubmit = async () => {
    const newCount = parseInt(editValue, 10);
    
    if (isNaN(newCount) || newCount < 0) {
      setError('Please enter a valid number');
      return;
    }

    try {
      setIsUpdating(true);
      setError(null);
      await onUpdate(newCount);
      setIsEditing(false);
    } catch (err) {
      setError('Failed to update contact count');
    } finally {
      setIsUpdating(false);
    }
  };

  const handleIncrement = async () => {
    try {
      setIsUpdating(true);
      await onUpdate(count + 1);
    } catch (err) {
      setError('Failed to increment count');
    } finally {
      setIsUpdating(false);
    }
  };

  const handleDecrement = async () => {
    if (count > 0) {
      try {
        setIsUpdating(true);
        await onUpdate(count - 1);
      } catch (err) {
        setError('Failed to decrement count');
      } finally {
        setIsUpdating(false);
      }
    }
  };

  if (isEditing) {
    return (
      <div className="flex items-center space-x-2">
        <div className="relative">
          <input
            type="number"
            value={editValue}
            onChange={(e) => {
              setEditValue(e.target.value);
              setError(null);
            }}
            min="0"
            className={`w-20 px-2 py-1 border rounded text-sm focus:outline-none focus:ring-2 focus:ring-blue-500 ${
              error ? 'border-red-300' : 'border-gray-300'
            }`}
            disabled={isUpdating}
          />
          {error && (
            <p className="absolute left-0 top-full mt-1 text-xs text-red-500">
              {error}
            </p>
          )}
        </div>
        <div className="flex items-center space-x-1">
          <button
            onClick={handleSubmit}
            disabled={isUpdating}
            className={`p-1 text-green-600 hover:text-green-700 rounded ${
              isUpdating ? 'opacity-50 cursor-not-allowed' : ''
            }`}
            title="Save"
          >
            <Save className="w-4 h-4" />
          </button>
          <button
            onClick={() => {
              setEditValue(count.toString());
              setIsEditing(false);
              setError(null);
            }}
            disabled={isUpdating}
            className="p-1 text-red-600 hover:text-red-700 rounded"
            title="Cancel"
          >
            <X className="w-4 h-4" />
          </button>
        </div>
      </div>
    );
  }

  return (
    <div className="flex items-center space-x-2">
      <span className="text-sm">{count} contacts</span>
      <div className="flex items-center space-x-1">
        <button
          onClick={handleDecrement}
          disabled={isUpdating || count === 0}
          className={`p-1 text-gray-500 hover:text-gray-700 rounded ${
            count === 0 ? 'opacity-50 cursor-not-allowed' : ''
          }`}
          title="Decrease"
        >
          <Minus className="w-4 h-4" />
        </button>
        <button
          onClick={handleIncrement}
          disabled={isUpdating}
          className="p-1 text-gray-500 hover:text-gray-700 rounded"
          title="Increase"
        >
          <Plus className="w-4 h-4" />
        </button>
        <button
          onClick={() => setIsEditing(true)}
          className="text-sm text-blue-600 hover:text-blue-700 px-2 py-1 rounded"
        >
          Edit
        </button>
      </div>
      {error && <p className="text-xs text-red-500 ml-2">{error}</p>}
    </div>
  );
};

export default ContactCountEditor;