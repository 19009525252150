import { useState, useCallback, useEffect } from 'react';
import { supabase } from '../lib/supabase';
import { useProperty } from '../contexts/PropertyContext';
import type { KeyLog } from '../types/keys';

export const useKeyLog = () => {
  const [keyLogs, setKeyLogs] = useState<KeyLog[]>([]);
  const [archivedLogs, setArchivedLogs] = useState<KeyLog[]>([]);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState<string | null>(null);
  const { selectedPropertyId } = useProperty();

  const fetchKeyLogs = useCallback(async () => {
    if (!selectedPropertyId) {
      setKeyLogs([]);
      setArchivedLogs([]);
      setIsLoading(false);
      return;
    }

    try {
      setIsLoading(true);
      setError(null);

      const { data: activeData, error: activeError } = await supabase
        .from('key_logs')
        .select('*')
        .eq('property_id', selectedPropertyId)
        .eq('status', 'checked_out')
        .order('checkout_time', { ascending: false });

      if (activeError) throw activeError;

      const { data: archivedData, error: archivedError } = await supabase
        .from('key_logs')
        .select('*')
        .eq('property_id', selectedPropertyId)
        .eq('status', 'returned')
        .order('return_time', { ascending: false });

      if (archivedError) throw archivedError;

      setKeyLogs(activeData || []);
      setArchivedLogs(archivedData || []);
    } catch (err) {
      console.error('Error fetching key logs:', err);
      setError('Failed to load key logs');
    } finally {
      setIsLoading(false);
    }
  }, [selectedPropertyId]);

  const addKeyLog = async (keyLog: Omit<KeyLog, 'id' | 'property_id' | 'status' | 'created_at' | 'updated_at'>) => {
    if (!selectedPropertyId) throw new Error('No property selected');

    try {
      const { data, error } = await supabase
        .from('key_logs')
        .insert([{
          ...keyLog,
          property_id: selectedPropertyId,
          status: 'checked_out'
        }])
        .select()
        .single();

      if (error) throw error;

      setKeyLogs(prev => [data, ...prev]);
      return data;
    } catch (err) {
      console.error('Error adding key log:', err);
      throw err;
    }
  };

  const returnKey = async (id: string) => {
    if (!selectedPropertyId) throw new Error('No property selected');

    try {
      const returnTime = new Date().toISOString();
      const { data, error } = await supabase
        .from('key_logs')
        .update({
          status: 'returned',
          return_time: returnTime
        })
        .eq('id', id)
        .eq('property_id', selectedPropertyId)
        .select()
        .single();

      if (error) throw error;

      setKeyLogs(prev => prev.filter(log => log.id !== id));
      setArchivedLogs(prev => [data, ...prev]);

      return data;
    } catch (err) {
      console.error('Error returning key:', err);
      throw err;
    }
  };

  const revertKey = async (id: string) => {
    if (!selectedPropertyId) throw new Error('No property selected');

    try {
      const { data, error } = await supabase
        .from('key_logs')
        .update({
          status: 'checked_out',
          return_time: null
        })
        .eq('id', id)
        .eq('property_id', selectedPropertyId)
        .select()
        .single();

      if (error) throw error;

      setArchivedLogs(prev => prev.filter(log => log.id !== id));
      setKeyLogs(prev => [data, ...prev]);

      return data;
    } catch (err) {
      console.error('Error reverting key:', err);
      throw err;
    }
  };

  useEffect(() => {
    fetchKeyLogs();

    if (selectedPropertyId) {
      const channel = supabase
        .channel('key_logs_changes')
        .on('postgres_changes',
          {
            event: '*',
            schema: 'public',
            table: 'key_logs',
            filter: `property_id=eq.${selectedPropertyId}`
          },
          () => {
            fetchKeyLogs();
          }
        )
        .subscribe();

      return () => {
        supabase.removeChannel(channel);
      };
    }
  }, [selectedPropertyId, fetchKeyLogs]);

  return {
    keyLogs,
    archivedLogs,
    isLoading,
    error,
    addKeyLog,
    returnKey,
    revertKey,
    refreshKeyLogs: fetchKeyLogs
  };
};

export default useKeyLog;