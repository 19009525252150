import React from 'react';
import { motion } from 'framer-motion';
import { Users, Zap, Target } from 'lucide-react';

interface FeaturesProps {
  inView: boolean;
}

const Features: React.FC<FeaturesProps> = ({ inView }) => {
  const features = [
    { icon: Users, title: 'Team Collaboration', description: 'Work seamlessly with your team' },
    { icon: Zap, title: 'Fast & Efficient', description: 'Automate repetitive tasks' },
    { icon: Target, title: 'Goal Oriented', description: 'Track and achieve your targets' }
  ];

  return (
    <div className="grid grid-cols-1 md:grid-cols-3 gap-8">
      {features.map((feature, index) => (
        <motion.div
          key={index}
          className="p-6 bg-darkPurple/20 rounded-lg"
          initial={{ opacity: 0, y: 20 }}
          animate={inView ? { opacity: 1, y: 0 } : {}}
          transition={{ delay: index * 0.2, duration: 0.6 }}
        >
          <feature.icon className="w-12 h-12 mx-auto mb-4 text-lightBlue" />
          <h3 className="text-xl font-semibold mb-2">{feature.title}</h3>
          <p className="text-gray-300">{feature.description}</p>
        </motion.div>
      ))}
    </div>
  );
};

export default Features;