import { useState, useCallback, useEffect, useRef } from 'react';
import { supabase, supabaseQuery } from '../lib/supabase';
import type { Unit } from '../types/units';

// Increase debounce delay to 3 seconds for better UX
const DEBOUNCE_DELAY = 3000;
// Minimum time between updates
const MIN_UPDATE_INTERVAL = 500;

interface UpdateError extends Error {
  code?: string;
  details?: string;
}

export const useTurnBoard = (propertyId: string | null) => {
  const [units, setUnits] = useState<Unit[]>([]);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState<string | null>(null);
  const [isEditing, setIsEditing] = useState(false);
  const updateTimeoutRef = useRef<NodeJS.Timeout>();
  const lastUpdateRef = useRef<number>(0);
  const optimisticUpdatesRef = useRef<Record<number, Partial<Unit>>>({});
  const pendingUpdatesRef = useRef<Map<string, NodeJS.Timeout>>(new Map());
  const batchUpdatesRef = useRef<Map<number, Partial<Unit>>>(new Map());

  const fetchUnits = useCallback(async () => {
    if (!propertyId || isEditing) {
      return;
    }

    try {
      setIsLoading(true);
      setError(null);

      const data = await supabaseQuery(async () => 
        supabase
          .from('turn_board_units')
          .select('*')
          .eq('property_id', propertyId)
          .order('aptNumber', { ascending: true })
      );

      if (!data) {
        throw new Error('Failed to fetch units');
      }

      const updatedData = data.map(unit => ({
        ...unit,
        ...optimisticUpdatesRef.current[unit.id]
      }));

      setUnits(updatedData);
    } catch (err) {
      const error = err as UpdateError;
      console.error('Error fetching units:', error);
      setError(error.message || 'Failed to load units');
      setUnits([]);
    } finally {
      setIsLoading(false);
    }
  }, [propertyId, isEditing]);

  const addUnit = useCallback(async (newUnit: Omit<Unit, 'id'>) => {
    if (!propertyId) {
      throw new Error('No property selected');
    }

    try {
      const data = await supabaseQuery(async () => 
        supabase
          .from('turn_board_units')
          .insert([{ ...newUnit, property_id: propertyId }])
          .select()
          .single()
      );

      if (!data) {
        throw new Error('Failed to add unit');
      }

      setUnits(prev => [...prev, data]);
      return data;
    } catch (err) {
      const error = err as UpdateError;
      console.error('Error adding unit:', error);
      throw new Error(error.message || 'Failed to add unit');
    }
  }, [propertyId]);

  const updateUnit = useCallback(async (id: number, updates: Partial<Unit>) => {
    if (!propertyId) {
      throw new Error('No property selected');
    }

    try {
      setIsEditing(true);

      // Clear any existing timeout for this unit
      const existingTimeout = pendingUpdatesRef.current.get(`${id}`);
      if (existingTimeout) {
        clearTimeout(existingTimeout);
      }

      // Store optimistic update
      optimisticUpdatesRef.current[id] = {
        ...optimisticUpdatesRef.current[id],
        ...updates
      };

      // Batch updates
      batchUpdatesRef.current.set(id, {
        ...batchUpdatesRef.current.get(id),
        ...updates
      });

      // Update local state immediately
      setUnits(prev =>
        prev.map(unit =>
          unit.id === id ? { ...unit, ...updates } : unit
        )
      );

      // Throttle updates
      const now = Date.now();
      const timeSinceLastUpdate = now - lastUpdateRef.current;
      const delay = Math.max(DEBOUNCE_DELAY, MIN_UPDATE_INTERVAL - timeSinceLastUpdate);

      // Create new debounced update
      const timeout = setTimeout(async () => {
        try {
          const batchedUpdates = batchUpdatesRef.current.get(id);
          if (!batchedUpdates) return;

          const { error: updateError } = await supabase
            .from('turn_board_units')
            .update(batchedUpdates)
            .eq('id', id)
            .eq('property_id', propertyId);

          if (updateError) {
            throw updateError;
          }

          // Clear optimistic update and batch after successful save
          delete optimisticUpdatesRef.current[id];
          batchUpdatesRef.current.delete(id);
          pendingUpdatesRef.current.delete(`${id}`);
          lastUpdateRef.current = Date.now();

          // Only set isEditing to false if no more pending updates
          if (pendingUpdatesRef.current.size === 0) {
            setIsEditing(false);
          }
        } catch (err) {
          const error = err as UpdateError;
          console.error('Error updating unit:', error);
          
          // Revert optimistic update on error
          delete optimisticUpdatesRef.current[id];
          batchUpdatesRef.current.delete(id);
          pendingUpdatesRef.current.delete(`${id}`);
          
          if (pendingUpdatesRef.current.size === 0) {
            setIsEditing(false);
          }
          
          // Refresh the data to ensure consistency
          await fetchUnits();
          
          throw new Error(error.message || 'Failed to update unit');
        }
      }, delay);

      // Store the timeout
      pendingUpdatesRef.current.set(`${id}`, timeout);

    } catch (err) {
      const error = err as UpdateError;
      console.error('Error updating unit:', error);
      throw new Error(error.message || 'Failed to update unit');
    }
  }, [propertyId, fetchUnits]);

  useEffect(() => {
    fetchUnits();

    if (propertyId) {
      const channel = supabase
        .channel('turn_board_changes')
        .on('postgres_changes',
          {
            event: '*',
            schema: 'public',
            table: 'turn_board_units',
            filter: `property_id=eq.${propertyId}`
          },
          (payload) => {
            // Only fetch if the change wasn't from this client and we're not editing
            if (!optimisticUpdatesRef.current[payload.new?.id] && !isEditing) {
              fetchUnits();
            }
          }
        )
        .subscribe();

      return () => {
        // Clear all pending timeouts
        pendingUpdatesRef.current.forEach(timeout => clearTimeout(timeout));
        pendingUpdatesRef.current.clear();
        batchUpdatesRef.current.clear();
        
        if (updateTimeoutRef.current) {
          clearTimeout(updateTimeoutRef.current);
        }
        supabase.removeChannel(channel);
      };
    }
  }, [propertyId, fetchUnits, isEditing]);

  return {
    units,
    isLoading,
    error,
    addUnit,
    updateUnit,
    isEditing
  };
};

export default useTurnBoard;