import React, { memo } from 'react';
import { Download, PlusCircle } from 'lucide-react';
import { Button } from '../ui/TurnBoardComponents';
import type { Unit } from '../../types/units';

interface ActionBarProps {
  onExport: (units: Unit[]) => void;
  onAddUnit: () => void;
  units: Unit[];
  disabled?: boolean;
}

const ActionBar = memo(({ onExport, onAddUnit, units, disabled = false }: ActionBarProps) => {
  return (
    <div className="flex gap-2">
      <Button 
        onClick={() => onExport(units)} 
        className="whitespace-nowrap"
        disabled={disabled}
      >
        <Download className="w-4 h-4 mr-2" />
        Export
      </Button>
      <Button 
        onClick={onAddUnit} 
        className="whitespace-nowrap"
        disabled={disabled}
      >
        <PlusCircle className="w-4 h-4 mr-2" />
        Add Unit
      </Button>
    </div>
  );
});

ActionBar.displayName = 'ActionBar';

export default ActionBar;