import React from 'react';
import { useForm } from 'react-hook-form';
import { X } from 'lucide-react';
import type { KeyLog } from '../../types/keys';

interface NewKeyLogModalProps {
  isOpen: boolean;
  onClose: () => void;
  onSubmit: (keyLog: Omit<KeyLog, 'id' | 'property_id' | 'status' | 'created_at' | 'updated_at'>) => Promise<void>;
}

interface FormInputs {
  apt_number: string;
  checked_out_by: string;
  reason: string;
  notes?: string;
}

const NewKeyLogModal: React.FC<NewKeyLogModalProps> = ({
  isOpen,
  onClose,
  onSubmit
}) => {
  const { 
    register, 
    handleSubmit, 
    reset, 
    formState: { errors, isSubmitting } 
  } = useForm<FormInputs>();

  const handleFormSubmit = async (data: FormInputs) => {
    try {
      await onSubmit({
        ...data,
        checkout_time: new Date().toISOString()
      });
      reset();
      onClose();
    } catch (error) {
      console.error('Error submitting key log:', error);
    }
  };

  if (!isOpen) return null;

  return (
    <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50">
      <div className="bg-white rounded-lg p-6 w-full max-w-md">
        <div className="flex justify-between items-center mb-4">
          <h2 className="text-xl font-bold">Check Out Key</h2>
          <button 
            onClick={onClose} 
            className="text-gray-500 hover:text-gray-700"
            type="button"
          >
            <X size={24} />
          </button>
        </div>

        <form onSubmit={handleSubmit(handleFormSubmit)} className="space-y-4">
          <div>
            <label className="block text-sm font-medium text-gray-700">
              Apartment Number *
            </label>
            <input
              type="text"
              {...register('apt_number', { 
                required: 'Apartment number is required',
                pattern: {
                  value: /^[A-Za-z0-9-]+$/,
                  message: 'Invalid apartment number format'
                }
              })}
              className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-blue-500 focus:ring-blue-500"
              placeholder="e.g., 101"
            />
            {errors.apt_number && (
              <p className="mt-1 text-sm text-red-600">
                {errors.apt_number.message}
              </p>
            )}
          </div>

          <div>
            <label className="block text-sm font-medium text-gray-700">
              Checked Out By *
            </label>
            <input
              type="text"
              {...register('checked_out_by', { 
                required: 'Name is required',
                minLength: {
                  value: 2,
                  message: 'Name must be at least 2 characters'
                }
              })}
              className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-blue-500 focus:ring-blue-500"
              placeholder="Enter full name"
            />
            {errors.checked_out_by && (
              <p className="mt-1 text-sm text-red-600">
                {errors.checked_out_by.message}
              </p>
            )}
          </div>

          <div>
            <label className="block text-sm font-medium text-gray-700">
              Reason *
            </label>
            <input
              type="text"
              {...register('reason', { 
                required: 'Reason is required',
                minLength: {
                  value: 3,
                  message: 'Reason must be at least 3 characters'
                }
              })}
              className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-blue-500 focus:ring-blue-500"
              placeholder="e.g., Maintenance inspection"
            />
            {errors.reason && (
              <p className="mt-1 text-sm text-red-600">
                {errors.reason.message}
              </p>
            )}
          </div>

          <div>
            <label className="block text-sm font-medium text-gray-700">
              Notes
            </label>
            <textarea
              {...register('notes')}
              rows={3}
              className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-blue-500 focus:ring-blue-500"
              placeholder="Any additional notes..."
            />
          </div>

          <div className="flex justify-end space-x-2 pt-4">
            <button
              type="button"
              onClick={onClose}
              className="px-4 py-2 text-sm font-medium text-gray-700 hover:text-gray-800 border border-gray-300 rounded-md hover:bg-gray-50"
              disabled={isSubmitting}
            >
              Cancel
            </button>
            <button
              type="submit"
              disabled={isSubmitting}
              className="px-4 py-2 text-sm font-medium text-white bg-blue-600 rounded-md hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500 disabled:opacity-50"
            >
              {isSubmitting ? 'Checking Out...' : 'Check Out Key'}
            </button>
          </div>
        </form>
      </div>
    </div>
  );
};

export default NewKeyLogModal;