import React from 'react';

interface InputProps extends React.InputHTMLAttributes<HTMLInputElement> {
  className?: string;
}

export const Input = React.forwardRef<HTMLInputElement, InputProps>(
  ({ className = '', value = '', ...props }, ref) => (
    <input
      {...props}
      ref={ref}
      value={value ?? ''}
      className={`block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm text-sm focus:outline-none focus:ring-1 focus:ring-blue-500 focus:border-blue-500 ${className}`}
    />
  )
);

Input.displayName = 'Input';

interface ButtonProps extends React.ButtonHTMLAttributes<HTMLButtonElement> {
  children: React.ReactNode;
  className?: string;
}

export const Button: React.FC<ButtonProps> = ({ children, className = '', ...props }) => (
  <button
    {...props}
    className={`inline-flex items-center px-4 py-2 border border-transparent text-sm font-medium rounded-md shadow-sm text-white bg-blue-600 hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500 ${className}`}
  >
    {children}
  </button>
);

interface DropdownProps {
  value: string;
  onChange: (e: React.ChangeEvent<HTMLSelectElement>) => void;
  options: string[];
  onVendorChange?: (vendor: string) => void;
  onScheduledDateChange?: (date: Date | null) => void;
  onCostChange?: (cost: number) => void;
  vendorName?: string;
  scheduledDate?: string | null;
  estimatedCost?: number;
  className?: string;
}

export const Dropdown: React.FC<DropdownProps> = ({
  value = '',
  onChange,
  options,
  onVendorChange,
  onScheduledDateChange,
  onCostChange,
  vendorName = '',
  scheduledDate = '',
  estimatedCost,
  className = ''
}) => {
  const [isDetailsExpanded, setIsDetailsExpanded] = React.useState(false);

  React.useEffect(() => {
    if (value === 'Scheduled') {
      setIsDetailsExpanded(true);
    }
  }, [value]);

  const getStatusColor = (status: string) => {
    switch (status) {
      case 'Done':
        return 'bg-green-100 text-green-800';
      case 'Scheduled':
        return 'bg-yellow-100 text-yellow-800';
      default:
        return 'bg-gray-100 text-gray-800';
    }
  };

  return (
    <div className="space-y-2">
      <select
        value={value}
        onChange={onChange}
        className={`block w-full px-3 py-2 border rounded-md shadow-sm text-sm focus:outline-none focus:ring-1 focus:ring-blue-500 focus:border-blue-500 ${getStatusColor(value)} ${className}`}
      >
        {options.map((option) => (
          <option key={option} value={option}>
            {option}
          </option>
        ))}
      </select>

      {(value === 'Scheduled' || value === 'Done' || Boolean(vendorName)) && 
       onVendorChange && onScheduledDateChange && onCostChange && (
        <div className="mt-2 space-y-2 bg-gray-50 p-2 rounded-md">
          <Input
            type="text"
            value={vendorName}
            onChange={(e) => onVendorChange(e.target.value)}
            placeholder="Vendor name"
            disabled={value === 'Done'}
          />
          <Input
            type="date"
            value={scheduledDate || ''}
            onChange={(e) => {
              const date = e.target.value ? new Date(e.target.value) : null;
              onScheduledDateChange(date);
            }}
            disabled={value === 'Done'}
          />
          <Input
            type="number"
            value={estimatedCost?.toString() || ''}
            onChange={(e) => onCostChange(parseFloat(e.target.value) || 0)}
            placeholder="Estimated cost"
            disabled={value === 'Done'}
          />
        </div>
      )}
    </div>
  );
};