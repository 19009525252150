import React, { createContext, useContext, useState, useEffect, useCallback } from 'react';
import { useAuth } from './AuthContext';
import { supabase } from '../lib/supabase';
import type { Property } from '../types/property';

interface PropertyContextType {
  selectedPropertyId: string | null;
  setSelectedPropertyId: (id: string | null) => void;
  properties: Property[];
  isLoading: boolean;
  error: string | null;
  refreshProperties: () => Promise<void>;
}

const PropertyContext = createContext<PropertyContextType | null>(null);

export const PropertyProvider: React.FC<{ children: React.ReactNode }> = ({ children }) => {
  const { user } = useAuth();
  const [selectedPropertyId, setSelectedPropertyId] = useState<string | null>(() => {
    return localStorage.getItem('selectedPropertyId');
  });
  const [properties, setProperties] = useState<Property[]>([]);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState<string | null>(null);

  const fetchProperties = useCallback(async () => {
    if (!user?.id) {
      setProperties([]);
      setSelectedPropertyId(null);
      localStorage.removeItem('selectedPropertyId');
      setIsLoading(false);
      return;
    }

    try {
      setIsLoading(true);
      setError(null);

      // Fetch owned properties
      const { data: ownedProperties, error: ownedError } = await supabase
        .from('properties')
        .select('*')
        .eq('owner_id', user.id);

      if (ownedError) throw ownedError;

      // Fetch collaborative properties
      const { data: collaborations, error: collabError } = await supabase
        .from('property_collaborators')
        .select(`
          property:property_id (*)
        `)
        .eq('user_id', user.id)
        .neq('role', 'owner');

      if (collabError) throw collabError;

      // Combine and deduplicate properties
      const collaborativeProperties = collaborations
        ?.map(c => c.property)
        .filter((p): p is Property => p !== null);

      const allProperties = [
        ...(ownedProperties || []),
        ...(collaborativeProperties || [])
      ];

      const uniqueProperties = Array.from(
        new Map(allProperties.map(p => [p.id, p])).values()
      );

      setProperties(uniqueProperties);

      // Handle selected property
      if (selectedPropertyId) {
        const propertyExists = uniqueProperties.some(p => p.id === selectedPropertyId);
        if (!propertyExists) {
          setSelectedPropertyId(null);
          localStorage.removeItem('selectedPropertyId');
        }
      } else if (uniqueProperties.length > 0) {
        setSelectedPropertyId(uniqueProperties[0].id);
        localStorage.setItem('selectedPropertyId', uniqueProperties[0].id);
      }
    } catch (err) {
      console.error('Error fetching properties:', err);
      setError('Failed to load properties');
      setProperties([]);
      setSelectedPropertyId(null);
      localStorage.removeItem('selectedPropertyId');
    } finally {
      setIsLoading(false);
    }
  }, [user?.id, selectedPropertyId]);

  useEffect(() => {
    fetchProperties();

    if (user?.id) {
      const channel = supabase
        .channel('property_changes')
        .on('postgres_changes', 
          { 
            event: '*', 
            schema: 'public',
            table: 'properties'
          }, 
          () => {
            fetchProperties();
          }
        )
        .on('postgres_changes',
          {
            event: '*',
            schema: 'public',
            table: 'property_collaborators'
          },
          () => {
            fetchProperties();
          }
        )
        .subscribe();

      return () => {
        supabase.removeChannel(channel);
      };
    }
  }, [user?.id, fetchProperties]);

  return (
    <PropertyContext.Provider value={{
      selectedPropertyId,
      setSelectedPropertyId: (id) => {
        if (id) {
          localStorage.setItem('selectedPropertyId', id);
        } else {
          localStorage.removeItem('selectedPropertyId');
        }
        setSelectedPropertyId(id);
      },
      properties,
      isLoading,
      error,
      refreshProperties: fetchProperties
    }}>
      {children}
    </PropertyContext.Provider>
  );
};

export const useProperty = () => {
  const context = useContext(PropertyContext);
  if (!context) {
    throw new Error('useProperty must be used within a PropertyProvider');
  }
  return context;
};

export default PropertyContext;