import { Unit } from '../types/units';

interface UnitSummary {
  aptNumber: string;
  size: string;
  moveInDate: string;
  futureResident: string;
  completedTasks: number;
  totalTasks: number;
  completionRate: string;
  totalCost: number;
  nextScheduledTask: string;
  nextScheduledDate: string;
}

const getNextScheduledTask = (unit: Unit): { task: string; date: string } => {
  const tasks = [
    { name: 'Bulk Trash', date: unit.bulkTrashScheduled },
    { name: 'Paint', date: unit.paintScheduled },
    { name: 'Carpet/Flooring', date: unit.carpetFlooringScheduled },
    { name: 'Countertops', date: unit.countertopsScheduled },
    { name: 'Cabinets', date: unit.cabinetsScheduled },
    { name: 'Tub Reglaze', date: unit.tubReglazeScheduled },
    { name: 'Appliances', date: unit.appliancesScheduled },
    { name: 'Clean', date: unit.cleanScheduled }
  ].filter(t => t.date);

  if (!tasks.length) return { task: '', date: '' };

  const sortedTasks = tasks.sort((a, b) => 
    new Date(a.date!).getTime() - new Date(b.date!).getTime()
  );

  return {
    task: sortedTasks[0].name,
    date: sortedTasks[0].date!
  };
}

const calculateUnitSummary = (unit: Unit): UnitSummary => {
  const tasks = ['bulkTrash', 'paint', 'carpetFlooring', 'countertops', 'cabinets', 'tubReglaze', 'appliances', 'clean'];
  const completedTasks = tasks.filter(task => unit[task as keyof Unit] === 'Done').length;
  const totalCost = [
    unit.bulkTrashCost,
    unit.paintCost,
    unit.carpetFlooringCost,
    unit.countertopsCost,
    unit.cabinetsCost,
    unit.tubReglazeCost,
    unit.appliancesCost,
    unit.cleanCost
  ].reduce((sum, cost) => sum + (cost || 0), 0);

  const nextTask = getNextScheduledTask(unit);

  return {
    aptNumber: unit.aptNumber,
    size: unit.size,
    moveInDate: unit.moveInDate,
    futureResident: unit.futureResident,
    completedTasks,
    totalTasks: tasks.length,
    completionRate: `${Math.round((completedTasks / tasks.length) * 100)}%`,
    totalCost,
    nextScheduledTask: nextTask.task,
    nextScheduledDate: nextTask.date
  };
};

export const convertToCSV = (units: Unit[]): string => {
  const headers = [
    'Apartment Number',
    'Size',
    'Move-in Date',
    'Future Resident',
    'Completed Tasks',
    'Total Tasks',
    'Completion Rate',
    'Total Cost',
    'Next Scheduled Task',
    'Next Scheduled Date',
    'Notes'
  ];

  const summaries = units.map(calculateUnitSummary);

  const rows = summaries.map(summary => [
    summary.aptNumber,
    summary.size,
    summary.moveInDate,
    summary.futureResident,
    summary.completedTasks,
    summary.totalTasks,
    summary.completionRate,
    summary.totalCost.toFixed(2),
    summary.nextScheduledTask,
    summary.nextScheduledDate,
    units.find(u => u.aptNumber === summary.aptNumber)?.notes?.replace(/"/g, '""').replace(/\n/g, ' ') || ''
  ]);

  const csvContent = [
    headers.join(','),
    ...rows.map(row => 
      row.map(cell => 
        cell ? `"${cell}"` : '""'
      ).join(',')
    )
  ].join('\n');

  return csvContent;
};

export const downloadCSV = (units: Unit[]) => {
  const csvContent = convertToCSV(units);
  const blob = new Blob([csvContent], { type: 'text/csv;charset=utf-8;' });
  const link = document.createElement('a');
  const url = URL.createObjectURL(blob);
  const timestamp = new Date().toISOString().split('T')[0];
  
  link.setAttribute('href', url);
  link.setAttribute('download', `turn-board-summary-${timestamp}.csv`);
  link.style.visibility = 'hidden';
  
  document.body.appendChild(link);
  link.click();
  document.body.removeChild(link);
};