import React, { useMemo } from 'react';
import { KeyRound } from 'lucide-react';
import type { KeyLog } from '../../types/keys';

interface KeyLogListProps {
  logs: KeyLog[];
  onReturnKey: (id: string) => Promise<void>;
  searchQuery: string;
  sortBy: 'apt_number' | 'checkout_time';
}

const KeyLogList: React.FC<KeyLogListProps> = ({
  logs,
  onReturnKey,
  searchQuery,
  sortBy
}) => {
  const [returningKey, setReturningKey] = React.useState<string | null>(null);

  const filteredAndSortedLogs = useMemo(() => {
    return logs
      .filter(log =>
        log.apt_number.toLowerCase().includes(searchQuery.toLowerCase()) ||
        log.checked_out_by.toLowerCase().includes(searchQuery.toLowerCase())
      )
      .sort((a, b) => {
        if (sortBy === 'apt_number') {
          return a.apt_number.localeCompare(b.apt_number, undefined, { numeric: true });
        }
        return new Date(b.checkout_time).getTime() - new Date(a.checkout_time).getTime();
      });
  }, [logs, searchQuery, sortBy]);

  const handleReturnKey = async (id: string) => {
    try {
      setReturningKey(id);
      await onReturnKey(id);
    } catch (error) {
      console.error('Error returning key:', error);
    } finally {
      setReturningKey(null);
    }
  };

  if (filteredAndSortedLogs.length === 0) {
    return (
      <div className="text-center py-8 text-gray-500 bg-white rounded-lg border border-gray-200">
        No active key checkouts found
      </div>
    );
  }

  return (
    <div className="bg-white rounded-lg border border-gray-200 divide-y divide-gray-200">
      {filteredAndSortedLogs.map((log) => (
        <div key={log.id} className="p-4">
          <div className="flex items-center justify-between">
            <div>
              <div className="flex items-center space-x-2">
                <span className="font-medium">Apt #{log.apt_number}</span>
                <span className="text-gray-500">•</span>
                <span>{log.checked_out_by}</span>
              </div>
              <div className="mt-1 text-sm text-gray-500">
                Checked out: {new Date(log.checkout_time).toLocaleString()}
              </div>
              {log.reason && (
                <div className="mt-2 text-sm text-gray-600">
                  Reason: {log.reason}
                </div>
              )}
              {log.notes && (
                <div className="mt-1 text-sm text-gray-500">
                  Notes: {log.notes}
                </div>
              )}
            </div>
            <button
              onClick={() => handleReturnKey(log.id)}
              disabled={returningKey === log.id}
              className={`flex items-center px-3 py-1 text-sm text-blue-600 hover:bg-blue-50 rounded-md transition-colors ${
                returningKey === log.id ? 'opacity-50 cursor-not-allowed' : ''
              }`}
            >
              <KeyRound className="w-4 h-4 mr-1" />
              {returningKey === log.id ? 'Returning...' : 'Return Key'}
            </button>
          </div>
        </div>
      ))}
    </div>
  );
};

export default KeyLogList;