import React, { useState } from 'react';
import { Search, RefreshCw, Calendar, Trash2 } from 'lucide-react';
import ApplicationCard from './ApplicationCard';
import type { Application } from '../../types/applications';

interface ArchivedApplicationsProps {
  applications: Application[];
  onRestore: (id: string) => Promise<void>;
  onUpdate: (id: string, data: Partial<Application>) => Promise<void>;
  onDelete: (id: string) => Promise<void>;
}

const ArchivedApplications: React.FC<ArchivedApplicationsProps> = ({
  applications,
  onRestore,
  onUpdate,
  onDelete
}) => {
  const [searchQuery, setSearchQuery] = useState('');
  const [isDeleting, setIsDeleting] = useState<string | null>(null);

  const filteredApplications = applications.filter(app =>
    app.name.toLowerCase().includes(searchQuery.toLowerCase()) ||
    app.archive_reason?.toLowerCase().includes(searchQuery.toLowerCase())
  );

  const handleDelete = async (id: string) => {
    if (!window.confirm('Are you sure you want to permanently delete this application? This action cannot be undone.')) {
      return;
    }

    try {
      setIsDeleting(id);
      await onDelete(id);
    } catch (error) {
      console.error('Error deleting application:', error);
    } finally {
      setIsDeleting(null);
    }
  };

  return (
    <div className="space-y-4">
      <div className="flex items-center gap-4">
        <div className="relative flex-1">
          <Search className="absolute left-3 top-1/2 transform -translate-y-1/2 text-gray-400" />
          <input
            type="text"
            placeholder="Search archived applications..."
            value={searchQuery}
            onChange={(e) => setSearchQuery(e.target.value)}
            className="w-full pl-10 pr-4 py-2 border rounded-lg focus:ring-2 focus:ring-blue-500 focus:border-transparent"
          />
        </div>
      </div>

      {filteredApplications.length === 0 ? (
        <div className="text-center py-8 text-gray-500">
          No archived applications found
        </div>
      ) : (
        <div className="space-y-4">
          {filteredApplications.map((application) => (
            <div key={application.id} className="relative">
              <ApplicationCard
                application={application}
                onUpdate={onUpdate}
                isArchived
              />
              <div className="mt-2 p-4 bg-gray-50 rounded-lg border border-gray-200">
                <div className="flex items-center justify-between">
                  <div className="space-y-1">
                    <div className="flex items-center text-sm text-gray-500">
                      <Calendar className="w-4 h-4 mr-2" />
                      Archived on {new Date(application.archived_at!).toLocaleDateString()}
                    </div>
                    <div className="text-sm text-gray-600">
                      <strong>Reason:</strong> {application.archive_reason}
                    </div>
                  </div>
                  <div className="flex items-center space-x-2">
                    <button
                      onClick={() => onRestore(application.id)}
                      className="flex items-center px-3 py-1 text-sm text-blue-600 hover:bg-blue-50 rounded-md transition-colors"
                      disabled={isDeleting === application.id}
                    >
                      <RefreshCw className="w-4 h-4 mr-1" />
                      Restore
                    </button>
                    <button
                      onClick={() => handleDelete(application.id)}
                      className="flex items-center px-3 py-1 text-sm text-red-600 hover:bg-red-50 rounded-md transition-colors"
                      disabled={isDeleting === application.id}
                    >
                      <Trash2 className="w-4 h-4 mr-1" />
                      Delete
                    </button>
                  </div>
                </div>
              </div>
            </div>
          ))}
        </div>
      )}
    </div>
  );
};

export default ArchivedApplications;