import React, { useState, useEffect } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { useAuth } from '../contexts/AuthContext';
import LoginForm from '../components/auth/LoginForm';
import RegisterForm from '../components/auth/RegisterForm';
import BackButton from '../components/ui/BackButton';

const Auth: React.FC = () => {
  const [isLogin, setIsLogin] = useState(true);
  const [serverError, setServerError] = useState<string | null>(null);
  const { signIn, signUp, user } = useAuth();
  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    // If user is already authenticated, redirect to dashboard
    if (user) {
      const destination = location.state?.from?.pathname || '/dashboard';
      navigate(destination, { replace: true });
    }
  }, [user, navigate, location]);

  const onLogin = async (data: { email: string; password: string }) => {
    try {
      setServerError(null);
      await signIn(data.email, data.password);
      // Navigation will be handled by AuthContext
    } catch (error) {
      console.error('Login error:', error);
      setServerError(error instanceof Error ? error.message : 'Failed to login. Please try again.');
    }
  };

  const onRegister = async (data: { email: string; password: string }) => {
    try {
      setServerError(null);
      await signUp(data.email, data.password);
      // Navigation will be handled by AuthContext
    } catch (error) {
      console.error('Registration error:', error);
      setServerError(error instanceof Error ? error.message : 'Failed to create account. Please try again.');
    }
  };

  return (
    <div className="min-h-screen bg-gray-50 flex flex-col justify-center py-12 sm:px-6 lg:px-8">
      <BackButton />
      <div className="sm:mx-auto sm:w-full sm:max-w-md">
        <h2 className="mt-6 text-center text-3xl font-extrabold text-gray-900">
          {isLogin ? 'Sign in to your account' : 'Create your account'}
        </h2>
        <p className="mt-2 text-center text-sm text-gray-600">
          {isLogin ? "Don't have an account? " : 'Already have an account? '}
          <button
            onClick={() => {
              setIsLogin(!isLogin);
              setServerError(null);
            }}
            className="font-medium text-blue-600 hover:text-blue-500 focus:outline-none focus:underline transition ease-in-out duration-150"
          >
            {isLogin ? 'Sign up' : 'Sign in'}
          </button>
        </p>
      </div>

      <div className="mt-8 sm:mx-auto sm:w-full sm:max-w-md">
        <div className="bg-white py-8 px-4 shadow sm:rounded-lg sm:px-10">
          {serverError && (
            <div className="mb-4 px-4 py-3 rounded relative bg-red-50 border border-red-200 text-red-600" role="alert">
              <span className="block sm:inline">{serverError}</span>
            </div>
          )}

          {isLogin ? (
            <LoginForm onSubmit={onLogin} />
          ) : (
            <RegisterForm onSubmit={onRegister} />
          )}
        </div>
      </div>
    </div>
  );
};

export default Auth;