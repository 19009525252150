import React, { useState } from 'react';
import { Calendar, Mail, Phone, MessageSquare, ChevronDown, ChevronUp, GripVertical, Home, Save, X, Archive } from 'lucide-react';
import { useForm } from 'react-hook-form';
import type { Application } from '../../types/applications';

interface ApplicationCardProps {
  application: Application;
  onUpdate: (id: string, data: Partial<Application>) => Promise<void>;
  onArchive?: (application: Application) => void;
  isArchived?: boolean;
}

const ApplicationCard: React.FC<ApplicationCardProps> = ({
  application,
  onUpdate,
  onArchive,
  isArchived = false
}) => {
  const [isExpanded, setIsExpanded] = useState(false);
  const [isEditing, setIsEditing] = useState(false);
  const [isEditingNotes, setIsEditingNotes] = useState(false);
  const [notes, setNotes] = useState(application.notes || '');

  const { register, handleSubmit, formState: { errors, isDirty }, reset } = useForm({
    defaultValues: {
      name: application.name,
      email: application.email || '',
      phone: application.phone || '',
      unit_number: application.unit_number || '',
      unit_size: application.unit_size || ''
    }
  });

  const handleNotesSubmit = async () => {
    await onUpdate(application.id, { notes });
    setIsEditingNotes(false);
  };

  const handleEdit = () => {
    setIsEditing(true);
    reset({
      name: application.name,
      email: application.email || '',
      phone: application.phone || '',
      unit_number: application.unit_number || '',
      unit_size: application.unit_size || ''
    });
  };

  const handleCancel = () => {
    setIsEditing(false);
    reset();
  };

  const onSubmit = async (data: any) => {
    try {
      await onUpdate(application.id, data);
      setIsEditing(false);
    } catch (error) {
      console.error('Error updating application:', error);
    }
  };

  return (
    <div className="bg-white rounded-lg shadow-sm border border-gray-200 overflow-hidden transition-all duration-200">
      <div
        className="p-4 cursor-pointer hover:bg-gray-50 transition-colors flex items-center justify-between"
        onClick={() => !isEditing && setIsExpanded(!isExpanded)}
      >
        <div className="flex items-center space-x-3">
          <GripVertical className="w-5 h-5 text-gray-400" />
          {isEditing ? (
            <input
              {...register('name', { required: 'Name is required' })}
              className="px-2 py-1 border rounded focus:ring-2 focus:ring-blue-500 focus:border-transparent"
              onClick={(e) => e.stopPropagation()}
            />
          ) : (
            <>
              <h3 className="font-semibold">{application.name}</h3>
              {(application.unit_size || application.unit_number) && (
                <span className="text-sm text-gray-500">
                  {application.unit_number && `#${application.unit_number}`}
                  {application.unit_size && application.unit_number && ' · '}
                  {application.unit_size}
                </span>
              )}
            </>
          )}
        </div>
        <div className="flex items-center space-x-2">
          {isEditing ? (
            <>
              <button
                onClick={(e) => {
                  e.stopPropagation();
                  handleSubmit(onSubmit)();
                }}
                className="p-1 text-green-600 hover:text-green-700"
                disabled={!isDirty}
              >
                <Save size={20} />
              </button>
              <button
                onClick={(e) => {
                  e.stopPropagation();
                  handleCancel();
                }}
                className="p-1 text-red-600 hover:text-red-700"
              >
                <X size={20} />
              </button>
            </>
          ) : (
            <button className="text-gray-400 hover:text-gray-600 transition-colors">
              {isExpanded ? <ChevronUp size={20} /> : <ChevronDown size={20} />}
            </button>
          )}
        </div>
      </div>
      
      <div
        className={`overflow-hidden transition-all duration-200 ${
          isExpanded ? 'max-h-[800px]' : 'max-h-0'
        }`}
      >
        <div className="p-4 border-t border-gray-100 space-y-4">
          <div className="space-y-2 text-sm text-gray-600">
            <div className="flex items-center">
              <Mail className="w-4 h-4 mr-2 flex-shrink-0" />
              {isEditing ? (
                <div className="flex-1">
                  <input
                    {...register('email', {
                      pattern: {
                        value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                        message: 'Invalid email address'
                      }
                    })}
                    className="w-full px-2 py-1 border rounded focus:ring-2 focus:ring-blue-500 focus:border-transparent"
                  />
                  {errors.email && (
                    <p className="mt-1 text-xs text-red-600">
                      {errors.email.message as string}
                    </p>
                  )}
                </div>
              ) : (
                application.email && (
                  <a
                    href={`mailto:${application.email}`}
                    className="hover:text-blue-600 truncate"
                    onClick={(e) => e.stopPropagation()}
                  >
                    {application.email}
                  </a>
                )
              )}
            </div>
            
            <div className="flex items-center">
              <Phone className="w-4 h-4 mr-2 flex-shrink-0" />
              {isEditing ? (
                <div className="flex-1">
                  <input
                    {...register('phone', {
                      pattern: {
                        value: /^[\d\s-+()]*$/,
                        message: 'Invalid phone number format'
                      }
                    })}
                    className="w-full px-2 py-1 border rounded focus:ring-2 focus:ring-blue-500 focus:border-transparent"
                  />
                  {errors.phone && (
                    <p className="mt-1 text-xs text-red-600">
                      {errors.phone.message as string}
                    </p>
                  )}
                </div>
              ) : (
                application.phone && (
                  <a
                    href={`tel:${application.phone}`}
                    className="hover:text-blue-600"
                    onClick={(e) => e.stopPropagation()}
                  >
                    {application.phone}
                  </a>
                )
              )}
            </div>

            <div className="flex items-center">
              <Home className="w-4 h-4 mr-2 flex-shrink-0" />
              {isEditing ? (
                <div className="flex gap-2">
                  <div>
                    <input
                      {...register('unit_number', {
                        pattern: {
                          value: /^[A-Za-z0-9-]*$/,
                          message: 'Invalid unit number format'
                        }
                      })}
                      placeholder="Unit #"
                      className="w-24 px-2 py-1 border rounded focus:ring-2 focus:ring-blue-500 focus:border-transparent"
                    />
                    {errors.unit_number && (
                      <p className="mt-1 text-xs text-red-600">
                        {errors.unit_number.message as string}
                      </p>
                    )}
                  </div>
                  <input
                    {...register('unit_size')}
                    placeholder="Size (e.g., 2BR/2BA)"
                    className="w-32 px-2 py-1 border rounded focus:ring-2 focus:ring-blue-500 focus:border-transparent"
                  />
                </div>
              ) : (
                <span>
                  {application.unit_number && `Unit #${application.unit_number}`}
                  {application.unit_size && application.unit_number && ' · '}
                  {application.unit_size && `${application.unit_size}`}
                </span>
              )}
            </div>
            
            <div className="flex items-center">
              <Calendar className="w-4 h-4 mr-2 flex-shrink-0" />
              <span>
                {new Date(application.date_received).toLocaleDateString()}
              </span>
            </div>
          </div>

          <div className="pt-2">
            <div className="flex items-center justify-between mb-2">
              <div className="flex items-center text-sm text-gray-600">
                <MessageSquare className="w-4 h-4 mr-2" />
                <span>Notes</span>
              </div>
              {!isEditingNotes && !isEditing && (
                <button
                  onClick={(e) => {
                    e.stopPropagation();
                    setIsEditingNotes(true);
                  }}
                  className="text-sm text-blue-600 hover:text-blue-700"
                >
                  Edit Notes
                </button>
              )}
            </div>

            {isEditingNotes ? (
              <div className="space-y-2" onClick={(e) => e.stopPropagation()}>
                <textarea
                  value={notes}
                  onChange={(e) => setNotes(e.target.value)}
                  className="w-full p-2 border rounded-md text-sm focus:ring-2 focus:ring-blue-500 focus:border-transparent"
                  rows={3}
                  placeholder="Add notes..."
                />
                <div className="flex justify-end space-x-2">
                  <button
                    onClick={() => {
                      setNotes(application.notes || '');
                      setIsEditingNotes(false);
                    }}
                    className="px-3 py-1 text-sm text-gray-600 hover:text-gray-700"
                  >
                    Cancel
                  </button>
                  <button
                    onClick={handleNotesSubmit}
                    className="px-3 py-1 text-sm bg-blue-600 text-white rounded-md hover:bg-blue-700"
                  >
                    Save Notes
                  </button>
                </div>
              </div>
            ) : (
              <p className="text-sm text-gray-600 min-h-[1.5rem]">
                {application.notes || 'No notes added'}
              </p>
            )}
          </div>

          {!isEditing && !isEditingNotes && (
            <div className="flex justify-end space-x-2">
              <button
                onClick={(e) => {
                  e.stopPropagation();
                  handleEdit();
                }}
                className="px-3 py-1 text-sm text-blue-600 hover:text-blue-700 rounded-md hover:bg-blue-50"
              >
                Edit Details
              </button>
              {!isArchived && onArchive && (
                <button
                  onClick={(e) => {
                    e.stopPropagation();
                    onArchive(application);
                  }}
                  className="px-3 py-1 text-sm text-gray-600 hover:text-gray-700 rounded-md hover:bg-gray-50 flex items-center"
                >
                  <Archive className="w-4 h-4 mr-1" />
                  Archive
                </button>
              )}
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default ApplicationCard;