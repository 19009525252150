import React, { useState } from 'react';
import { Plus, Search, Archive, Loader2 } from 'lucide-react';
import useKeyLog from '../hooks/useKeyLog';
import { useProperty } from '../contexts/PropertyContext';
import NewKeyLogModal from '../components/key-log/NewKeyLogModal';
import KeyLogList from '../components/key-log/KeyLogList';
import ArchivedKeyLogs from '../components/key-log/ArchivedKeyLogs';

const KeyLog: React.FC = () => {
  const { selectedPropertyId } = useProperty();
  const {
    keyLogs,
    archivedLogs,
    isLoading,
    error,
    addKeyLog,
    returnKey,
    revertKey
  } = useKeyLog();

  const [isModalOpen, setIsModalOpen] = useState(false);
  const [showArchived, setShowArchived] = useState(false);
  const [searchQuery, setSearchQuery] = useState('');
  const [sortBy, setSortBy] = useState<'apt_number' | 'checkout_time'>('checkout_time');

  if (error) {
    return (
      <div className="bg-red-50 border border-red-200 rounded-lg p-4">
        <p className="text-red-800">{error}</p>
      </div>
    );
  }

  if (!selectedPropertyId) {
    return (
      <div className="text-center py-8 text-gray-500">
        Please select a property to view key logs
      </div>
    );
  }

  if (isLoading) {
    return (
      <div className="flex justify-center items-center h-64">
        <Loader2 className="w-8 h-8 animate-spin text-blue-500" />
      </div>
    );
  }

  return (
    <div className="space-y-6">
      <div className="flex justify-between items-center">
        <div className="flex items-center space-x-4">
          <h1 className="text-2xl font-bold">Key Log</h1>
          <button
            onClick={() => setShowArchived(!showArchived)}
            className={`flex items-center px-3 py-1 text-sm rounded-md transition-colors ${
              showArchived
                ? 'bg-gray-100 text-gray-700'
                : 'text-gray-600 hover:bg-gray-50'
            }`}
          >
            <Archive className="w-4 h-4 mr-1" />
            {showArchived ? 'Show Active' : 'Show Archived'}
          </button>
        </div>
        <button
          onClick={() => setIsModalOpen(true)}
          className="flex items-center px-4 py-2 bg-blue-600 text-white rounded-lg hover:bg-blue-700 transition-colors"
        >
          <Plus className="w-5 h-5 mr-2" />
          Check Out Key
        </button>
      </div>

      {!showArchived && (
        <div className="flex flex-col sm:flex-row gap-4">
          <div className="flex-1 relative">
            <Search className="absolute left-3 top-1/2 transform -translate-y-1/2 text-gray-400" />
            <input
              type="text"
              placeholder="Search by apartment number or name..."
              value={searchQuery}
              onChange={(e) => setSearchQuery(e.target.value)}
              className="w-full pl-10 pr-4 py-2 border rounded-lg focus:ring-2 focus:ring-blue-500 focus:border-transparent"
            />
          </div>
          <select
            value={sortBy}
            onChange={(e) => setSortBy(e.target.value as 'apt_number' | 'checkout_time')}
            className="px-3 py-2 border rounded-lg focus:ring-2 focus:ring-blue-500 focus:border-transparent"
          >
            <option value="checkout_time">Sort by Checkout Time</option>
            <option value="apt_number">Sort by Apartment Number</option>
          </select>
        </div>
      )}

      {showArchived ? (
        <ArchivedKeyLogs
          logs={archivedLogs}
          searchQuery={searchQuery}
          sortBy={sortBy}
          onRevert={revertKey}
        />
      ) : (
        <KeyLogList
          logs={keyLogs}
          onReturnKey={returnKey}
          searchQuery={searchQuery}
          sortBy={sortBy}
        />
      )}

      <NewKeyLogModal
        isOpen={isModalOpen}
        onClose={() => setIsModalOpen(false)}
        onSubmit={addKeyLog}
      />
    </div>
  );
};

export default KeyLog;