import React, { useState, useRef, useEffect } from 'react';
import { Plus, Archive, User, ChevronDown } from 'lucide-react';
import Toggle from '../ui/Toggle';
import EditableField from '../ui/EditableField';
import useLocalStorage from '../../hooks/useLocalStorage';
import CompletedTasksModal from './CompletedTasksModal';
import type { TodoTask } from '../../types/tasks';

interface QuickTasksProps {
  tasks: TodoTask[];
  onTaskUpdate: (id: string, updates: Partial<TodoTask>) => void;
  onTaskCreate: (task: Omit<TodoTask, 'id' | 'type'>) => void;
  onTaskDelete: (id: string) => void;
}

const QuickTasks: React.FC<QuickTasksProps> = ({
  tasks,
  onTaskUpdate,
  onTaskCreate,
  onTaskDelete
}) => {
  const [assignees] = useLocalStorage<Array<{ id: string; name: string }>>('assignees', []);
  const [showCompletedModal, setShowCompletedModal] = useState(false);
  const [showQuickTaskDetails, setShowQuickTaskDetails] = useState(false);
  const [newTask, setNewTask] = useState<Omit<TodoTask, 'id' | 'type'>>({
    title: '',
    priority: 'medium',
    completed: false,
    status: 'active',
    assignee: assignees[0]?.name || ''
  });
  const inputRef = useRef<HTMLInputElement>(null);

  useEffect(() => {
    if (inputRef.current) {
      inputRef.current.focus();
    }
  }, []);

  const handleQuickCreate = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key === 'Enter' && newTask.title.trim()) {
      if (e.shiftKey || showQuickTaskDetails) {
        setShowQuickTaskDetails(true);
      } else {
        onTaskCreate(newTask);
        setNewTask({
          title: '',
          priority: 'medium',
          completed: false,
          status: 'active',
          assignee: assignees[0]?.name || ''
        });
        setShowQuickTaskDetails(false);
      }
    } else if (e.key === 'Escape') {
      setShowQuickTaskDetails(false);
      setNewTask({
        title: '',
        priority: 'medium',
        completed: false,
        status: 'active',
        assignee: assignees[0]?.name || ''
      });
    }
  };

  const getPriorityColor = (priority: string) => {
    switch (priority) {
      case 'high': return 'text-red-500';
      case 'medium': return 'text-yellow-500';
      case 'low': return 'text-green-500';
      default: return 'text-gray-500';
    }
  };

  const activeTasks = tasks.filter(task => !task.completed);
  const completedTasks = tasks.filter(task => task.completed);

  return (
    <div className="bg-white rounded-lg shadow-sm border border-gray-100 p-4">
      <div className="flex items-center justify-between mb-4">
        <h2 className="text-lg font-semibold">Quick Tasks</h2>
        <button
          onClick={() => setShowCompletedModal(true)}
          className="flex items-center px-3 py-1.5 text-sm text-gray-600 hover:text-gray-900 hover:bg-gray-100 rounded-md transition-colors"
        >
          <Archive className="w-4 h-4 mr-2" />
          View Completed
        </button>
      </div>

      <div className="space-y-3">
        <div className="flex items-center space-x-3 p-3 rounded-lg border border-blue-200 bg-blue-50">
          <Toggle checked={false} onChange={() => {}} disabled />
          <div className="flex-1">
            <input
              ref={inputRef}
              type="text"
              value={newTask.title}
              onChange={(e) => setNewTask({ ...newTask, title: e.target.value })}
              onKeyDown={handleQuickCreate}
              placeholder="Add a quick task (Press Enter to add, Shift+Enter for details)"
              className="w-full px-2 py-1 bg-white border rounded focus:outline-none focus:ring-2 focus:ring-blue-500"
            />
            {showQuickTaskDetails && (
              <div className="mt-2 space-y-2">
                <div className="flex items-center">
                  <User className="h-4 w-4 mr-2 text-gray-400" />
                  <select
                    value={newTask.assignee}
                    onChange={(e) => setNewTask({ ...newTask, assignee: e.target.value })}
                    className="flex-1 px-2 py-1 bg-white border rounded focus:outline-none focus:ring-2 focus:ring-blue-500"
                  >
                    {assignees.map((assignee) => (
                      <option key={assignee.id} value={assignee.name}>
                        {assignee.name}
                      </option>
                    ))}
                  </select>
                </div>
                <div className="flex justify-between items-center">
                  <select
                    value={newTask.priority}
                    onChange={(e) => setNewTask({ ...newTask, priority: e.target.value as TodoTask['priority'] })}
                    className={`px-2 py-1 border rounded ${getPriorityColor(newTask.priority)}`}
                  >
                    <option value="high">High Priority</option>
                    <option value="medium">Medium Priority</option>
                    <option value="low">Low Priority</option>
                  </select>
                  <button
                    onClick={() => {
                      if (newTask.title.trim()) {
                        onTaskCreate(newTask);
                        setNewTask({
                          title: '',
                          priority: 'medium',
                          completed: false,
                          status: 'active',
                          assignee: assignees[0]?.name || ''
                        });
                        setShowQuickTaskDetails(false);
                      }
                    }}
                    className="px-4 py-1 bg-blue-600 text-white rounded hover:bg-blue-700"
                  >
                    Add Task
                  </button>
                </div>
              </div>
            )}
          </div>
          <button
            onClick={() => setShowQuickTaskDetails(!showQuickTaskDetails)}
            className="p-2 text-gray-500 hover:text-gray-700 rounded-full hover:bg-blue-100"
          >
            <ChevronDown
              className={`h-4 w-4 transform transition-transform ${
                showQuickTaskDetails ? 'rotate-180' : ''
              }`}
            />
          </button>
        </div>

        {activeTasks.map((task) => (
          <div
            key={task.id}
            className={`flex items-center justify-between p-3 rounded-lg border ${
              task.completed ? 'bg-gray-50 border-gray-200' : 'bg-white border-gray-100'
            }`}
          >
            <div className="flex items-center space-x-3 flex-1">
              <Toggle
                checked={task.completed}
                onChange={(checked) => onTaskUpdate(task.id, { completed: checked })}
              />
              <div className={`flex-1 ${task.completed ? 'text-gray-400' : ''}`}>
                <EditableField
                  value={task.title}
                  onChange={(value) => onTaskUpdate(task.id, { title: value })}
                  className={task.completed ? 'line-through' : ''}
                />
                <div className="flex items-center mt-1">
                  <User className="h-4 w-4 mr-2 text-gray-400" />
                  <select
                    value={task.assignee}
                    onChange={(e) => onTaskUpdate(task.id, { assignee: e.target.value })}
                    className="text-sm text-gray-500 bg-transparent border-none focus:ring-0 hover:text-gray-700"
                  >
                    {assignees.map((assignee) => (
                      <option key={assignee.id} value={assignee.name}>
                        {assignee.name}
                      </option>
                    ))}
                  </select>
                </div>
              </div>
            </div>
            <div className="flex items-center space-x-4">
              <select
                value={task.priority}
                onChange={(e) => onTaskUpdate(task.id, { priority: e.target.value as TodoTask['priority'] })}
                className={`text-sm border-0 bg-transparent ${getPriorityColor(task.priority)} focus:ring-0`}
              >
                <option value="high">High</option>
                <option value="medium">Medium</option>
                <option value="low">Low</option>
              </select>
              <button
                onClick={() => onTaskDelete(task.id)}
                className="text-red-500 hover:text-red-700"
              >
                <Plus className="h-4 w-4 transform rotate-45" />
              </button>
            </div>
          </div>
        ))}

        {activeTasks.length === 0 && !showQuickTaskDetails && (
          <div className="text-center py-8 text-gray-500">
            No quick tasks yet. Add a task using the field above.
          </div>
        )}
      </div>

      <CompletedTasksModal
        isOpen={showCompletedModal}
        onClose={() => setShowCompletedModal(false)}
        tasks={completedTasks}
        onRestore={(taskId) => onTaskUpdate(taskId, { completed: false })}
        assignees={assignees.map(a => a.name)}
      />
    </div>
  );
};

export default QuickTasks;