import React, { useState } from 'react';
import { useForm } from 'react-hook-form';
import { X, ChevronDown } from 'lucide-react';
import type { Lead } from '../../types/leads';

interface NewLeadModalProps {
  isOpen: boolean;
  onClose: () => void;
  onSubmit: (lead: Omit<Lead, 'id' | 'property_id' | 'created_at' | 'updated_at' | 'contact_count' | 'last_contact_date'>) => Promise<void>;
}

const PREDEFINED_SOURCES = [
  'Property Website',
  'Walk-in',
  'Phone',
  'Email',
  'Referral',
  'Social Media',
  'Zillow',
  'Apartments.com',
  'Rent.com',
  'Google',
  'PERQ',
  'Other'
] as const;

const NewLeadModal: React.FC<NewLeadModalProps> = ({ isOpen, onClose, onSubmit }) => {
  const [isCustomSource, setIsCustomSource] = useState(false);
  const { register, handleSubmit, reset, watch, setValue, formState: { errors, isSubmitting } } = useForm({
    defaultValues: {
      full_name: '',
      email: '',
      phone: '',
      preferred_size: '',
      source: '',
      customSource: '',
      notes: '',
      status: 'active' as const
    }
  });

  const selectedSource = watch('source');

  const handleFormSubmit = async (data: any) => {
    try {
      const formData = {
        ...data,
        source: isCustomSource ? data.customSource : data.source
      };
      delete formData.customSource;
      
      await onSubmit(formData);
      reset();
      setIsCustomSource(false);
      onClose();
    } catch (error) {
      console.error('Error submitting lead:', error);
    }
  };

  const handleSourceChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
    const value = e.target.value;
    if (value === 'custom') {
      setIsCustomSource(true);
      setValue('source', '');
    } else {
      setIsCustomSource(false);
      setValue('source', value);
    }
  };

  if (!isOpen) return null;

  return (
    <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50">
      <div className="bg-white rounded-lg p-6 w-full max-w-md">
        <div className="flex justify-between items-center mb-4">
          <h2 className="text-xl font-bold">New Lead</h2>
          <button onClick={onClose} className="text-gray-500 hover:text-gray-700">
            <X size={24} />
          </button>
        </div>

        <form onSubmit={handleSubmit(handleFormSubmit)} className="space-y-4">
          <div>
            <label className="block text-sm font-medium text-gray-700">
              Full Name *
            </label>
            <input
              type="text"
              {...register('full_name', { required: 'Name is required' })}
              className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-blue-500 focus:ring-blue-500"
            />
            {errors.full_name && (
              <p className="mt-1 text-sm text-red-600">
                {errors.full_name.message as string}
              </p>
            )}
          </div>

          <div>
            <label className="block text-sm font-medium text-gray-700">
              Lead Source *
            </label>
            <div className="mt-1 space-y-2">
              <div className="relative">
                <select
                  value={isCustomSource ? 'custom' : selectedSource}
                  onChange={handleSourceChange}
                  className="block w-full rounded-md border-gray-300 shadow-sm focus:border-blue-500 focus:ring-blue-500 pr-10"
                >
                  <option value="">Select a source</option>
                  {PREDEFINED_SOURCES.map(source => (
                    <option key={source} value={source}>{source}</option>
                  ))}
                  <option value="custom">Other (Custom)</option>
                </select>
                <ChevronDown className="absolute right-3 top-1/2 transform -translate-y-1/2 text-gray-400 pointer-events-none" size={16} />
              </div>
              
              {isCustomSource && (
                <input
                  type="text"
                  {...register('customSource', { required: 'Custom source is required' })}
                  placeholder="Enter custom source"
                  className="block w-full rounded-md border-gray-300 shadow-sm focus:border-blue-500 focus:ring-blue-500"
                />
              )}
            </div>
            {errors.source && !isCustomSource && (
              <p className="mt-1 text-sm text-red-600">
                {errors.source.message as string}
              </p>
            )}
            {errors.customSource && isCustomSource && (
              <p className="mt-1 text-sm text-red-600">
                {errors.customSource.message as string}
              </p>
            )}
          </div>

          <div>
            <label className="block text-sm font-medium text-gray-700">
              Email
            </label>
            <input
              type="email"
              {...register('email', {
                pattern: {
                  value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                  message: 'Invalid email address'
                }
              })}
              className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-blue-500 focus:ring-blue-500"
            />
            {errors.email && (
              <p className="mt-1 text-sm text-red-600">
                {errors.email.message as string}
              </p>
            )}
          </div>

          <div>
            <label className="block text-sm font-medium text-gray-700">
              Phone
            </label>
            <input
              type="tel"
              {...register('phone', {
                pattern: {
                  value: /^[\d\s-+()]*$/,
                  message: 'Invalid phone number format'
                }
              })}
              className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-blue-500 focus:ring-blue-500"
            />
            {errors.phone && (
              <p className="mt-1 text-sm text-red-600">
                {errors.phone.message as string}
              </p>
            )}
          </div>

          <div>
            <label className="block text-sm font-medium text-gray-700">
              Preferred Size
            </label>
            <input
              type="text"
              {...register('preferred_size')}
              placeholder="e.g., 2BR/2BA"
              className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-blue-500 focus:ring-blue-500"
            />
          </div>

          <div>
            <label className="block text-sm font-medium text-gray-700">
              Notes
            </label>
            <textarea
              {...register('notes')}
              rows={3}
              className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-blue-500 focus:ring-blue-500"
            />
          </div>

          <div className="flex justify-end space-x-2">
            <button
              type="button"
              onClick={onClose}
              className="px-4 py-2 text-sm font-medium text-gray-700 hover:text-gray-800"
            >
              Cancel
            </button>
            <button
              type="submit"
              className="px-4 py-2 text-sm font-medium text-white bg-blue-600 rounded-md hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500 disabled:opacity-50"
            >
              {isSubmitting ? 'Adding...' : 'Add Lead'}
            </button>
          </div>
        </form>
      </div>
    </div>
  );
};

export default NewLeadModal;