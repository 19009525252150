import React from 'react';
import { Phone, Mail, Edit2, Archive, Calendar, MessageSquare, Clock } from 'lucide-react';
import type { Lead } from '../../types/leads';

interface LeadCardProps {
  lead: Lead;
  onEdit: (lead: Lead) => void;
  onArchive: (lead: Lead) => void;
}

const LeadCard: React.FC<LeadCardProps> = ({
  lead,
  onEdit,
  onArchive
}) => {
  const getContactStatusColor = (lastContactDate: string | null) => {
    if (!lastContactDate) return 'text-red-500';
    const days = Math.floor((Date.now() - new Date(lastContactDate).getTime()) / (1000 * 60 * 60 * 24));
    if (days <= 7) return 'text-green-500';
    if (days <= 30) return 'text-yellow-500';
    return 'text-red-500';
  };

  const formatDate = (date: string) => {
    return new Date(date).toLocaleDateString('en-US', {
      year: 'numeric',
      month: 'short',
      day: 'numeric',
      hour: '2-digit',
      minute: '2-digit'
    });
  };

  return (
    <div className={`bg-white rounded-lg shadow-sm border ${
      lead.status === 'inactive' ? 'border-gray-200 opacity-75' : 'border-gray-200'
    } p-4`}>
      <div className="flex items-center justify-between">
        <div className="flex-1 min-w-0">
          <div className="flex items-center justify-between">
            <h3 className="text-lg font-semibold truncate">{lead.full_name}</h3>
            <div className="flex items-center space-x-2 ml-4">
              <button
                onClick={() => onEdit(lead)}
                className="p-1 text-gray-500 hover:text-gray-700 rounded-full hover:bg-gray-100"
                title="Edit lead"
              >
                <Edit2 className="w-5 h-5" />
              </button>
              <button
                onClick={() => onArchive(lead)}
                className="p-1 text-gray-500 hover:text-gray-700 rounded-full hover:bg-gray-100"
                title="Archive lead"
              >
                <Archive className="w-5 h-5" />
              </button>
            </div>
          </div>
          
          <div className="mt-2 grid grid-cols-1 sm:grid-cols-2 gap-2">
            <div className="flex items-center text-sm text-gray-600">
              {lead.phone ? (
                <>
                  <Phone className="w-4 h-4 mr-2 flex-shrink-0" />
                  <a href={`tel:${lead.phone}`} className="hover:text-blue-600 truncate">
                    {lead.phone}
                  </a>
                </>
              ) : (
                <>
                  <Mail className="w-4 h-4 mr-2 flex-shrink-0" />
                  <a href={`mailto:${lead.email}`} className="hover:text-blue-600 truncate">
                    {lead.email}
                  </a>
                </>
              )}
            </div>
            
            <div className="flex items-center justify-end sm:justify-start text-sm">
              <MessageSquare className="w-4 h-4 mr-2 flex-shrink-0 text-gray-400" />
              <span className="text-gray-600">{lead.contact_count} contacts</span>
              {lead.last_contact_date && (
                <>
                  <Calendar className={`w-4 h-4 mx-2 flex-shrink-0 ${getContactStatusColor(lead.last_contact_date)}`} />
                  <span className={getContactStatusColor(lead.last_contact_date)}>
                    {new Date(lead.last_contact_date).toLocaleDateString()}
                  </span>
                </>
              )}
            </div>
          </div>

          <div className="mt-2 flex items-center text-sm text-gray-500">
            <Clock className="w-4 h-4 mr-2 flex-shrink-0" />
            <span>Created {formatDate(lead.created_at)}</span>
          </div>
        </div>
      </div>
    </div>
  );
};

export default LeadCard;