import React from 'react';
import { useNavigate } from 'react-router-dom';
import { Check, Crown } from 'lucide-react';
import type { PricingPlan } from './pricingData';

interface PricingTierProps extends PricingPlan {
  isPopular?: boolean;
}

const PricingTier: React.FC<PricingTierProps> = ({
  name,
  price,
  unitRange,
  features,
  buttonText,
  isPopular = false
}) => {
  const navigate = useNavigate();

  const handleClick = () => {
    navigate('/auth', { 
      state: { 
        isRegister: true,
        plan: { name, price, unitRange }
      }
    });
  };

  return (
    <div
      className={`relative rounded-2xl p-8 h-full transition-transform duration-300 hover:scale-105 ${
        isPopular
          ? 'bg-gradient-to-b from-lightBlue/20 to-mintGreen/20 border-2 border-lightBlue'
          : 'bg-darkPurple/40 border border-marianBlue/30'
      }`}
    >
      {isPopular && (
        <div className="absolute -top-4 left-1/2 transform -translate-x-1/2">
          <div className="flex items-center px-4 py-1 bg-lightBlue rounded-full">
            <Crown className="w-4 h-4 text-darkPurple mr-1" />
            <span className="text-sm font-semibold text-darkPurple">Most Popular</span>
          </div>
        </div>
      )}

      <div className="text-center mb-8">
        <h3 className="text-2xl font-bold text-white mb-2">{name}</h3>
        <div className="flex items-baseline justify-center mb-2">
          <span className="text-4xl font-bold text-lightBlue">${price}</span>
          <span className="text-lightBlue/80 ml-1">/month</span>
        </div>
        <p className="text-sm text-gray-300">{unitRange} units</p>
      </div>

      <ul className="space-y-4 mb-8">
        {features.map((feature, index) => (
          <li key={index} className="flex items-start">
            <Check className="w-5 h-5 text-mintGreen mr-3 flex-shrink-0 mt-0.5" />
            <span className="text-gray-300">{feature}</span>
          </li>
        ))}
      </ul>

      <button
        onClick={handleClick}
        className={`w-full py-3 px-6 rounded-lg font-semibold transition-colors ${
          isPopular
            ? 'bg-lightBlue text-darkPurple hover:bg-mintGreen'
            : 'bg-marianBlue/40 text-white hover:bg-marianBlue/60'
        }`}
      >
        {buttonText}
      </button>
    </div>
  );
};

export default PricingTier;