import React, { useState, useRef } from 'react';
import { Upload, Download, X, AlertCircle, CheckCircle } from 'lucide-react';
import * as XLSX from 'xlsx';
import { z } from 'zod';
import type { Lead } from '../../types/leads';

interface ImportLeadsButtonProps {
  onImport: (leads: Omit<Lead, 'id' | 'property_id' | 'created_at' | 'updated_at' | 'contact_count' | 'last_contact_date'>[]) => Promise<void>;
}

const leadSchema = z.object({
  full_name: z.string().min(1, 'Name is required'),
  email: z.string().email().optional().nullable(),
  phone: z.string().regex(/^[\d\s-+()]*$/, 'Invalid phone format').optional().nullable(),
  preferred_size: z.string().optional().nullable(),
  source: z.enum(['Website', 'Walk-in', 'Phone', 'Email', 'Referral', 'Social Media', 'Zillow', 'Apartments.com', 'Other']),
  notes: z.string().optional().nullable(),
  status: z.enum(['active', 'inactive']).default('active')
});

const ImportLeadsButton: React.FC<ImportLeadsButtonProps> = ({ onImport }) => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [errors, setErrors] = useState<string[]>([]);
  const [progress, setProgress] = useState(0);
  const [isProcessing, setIsProcessing] = useState(false);
  const [successCount, setSuccessCount] = useState(0);
  const fileInputRef = useRef<HTMLInputElement>(null);

  const downloadTemplate = () => {
    const template = XLSX.utils.book_new();
    const data = [
      ['full_name', 'email', 'phone', 'preferred_size', 'source', 'notes', 'status'],
      ['John Doe', 'john@example.com', '123-456-7890', '2BR/2BA', 'Website', 'Interested in pool view', 'active']
    ];
    
    const worksheet = XLSX.utils.aoa_to_sheet(data);
    XLSX.utils.book_append_sheet(template, worksheet, 'Template');
    XLSX.writeFile(template, 'leads_import_template.xlsx');
  };

  const processFile = async (file: File) => {
    setIsProcessing(true);
    setErrors([]);
    setProgress(0);
    setSuccessCount(0);

    try {
      const buffer = await file.arrayBuffer();
      const workbook = XLSX.read(buffer);
      const worksheet = workbook.Sheets[workbook.SheetNames[0]];
      const jsonData = XLSX.utils.sheet_to_json(worksheet);

      const validationErrors: string[] = [];
      const validLeads: any[] = [];

      jsonData.forEach((row: any, index) => {
        try {
          const result = leadSchema.safeParse(row);
          if (!result.success) {
            result.error.errors.forEach(error => {
              validationErrors.push(`Row ${index + 2}: ${error.path.join('.')} - ${error.message}`);
            });
          } else {
            validLeads.push(result.data);
          }
        } catch (err) {
          validationErrors.push(`Row ${index + 2}: Invalid data format`);
        }
        setProgress(((index + 1) / jsonData.length) * 100);
      });

      if (validationErrors.length > 0) {
        setErrors(validationErrors);
      } else if (validLeads.length === 0) {
        setErrors(['No valid leads found in file']);
      } else {
        await onImport(validLeads);
        setSuccessCount(validLeads.length);
      }
    } catch (err) {
      setErrors(['Failed to process file. Please ensure it\'s a valid Excel or CSV file.']);
    } finally {
      setIsProcessing(false);
      setProgress(100);
    }
  };

  return (
    <>
      <button
        onClick={() => setIsModalOpen(true)}
        className="flex items-center px-4 py-2 text-sm font-medium text-white bg-blue-600 rounded-lg hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500"
      >
        <Upload className="w-4 h-4 mr-2" />
        Import Leads
      </button>

      {isModalOpen && (
        <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50">
          <div className="bg-white rounded-lg p-6 w-full max-w-xl">
            <div className="flex justify-between items-center mb-4">
              <h2 className="text-xl font-bold">Import Leads</h2>
              <button
                onClick={() => setIsModalOpen(false)}
                className="text-gray-500 hover:text-gray-700"
              >
                <X size={24} />
              </button>
            </div>

            <div className="space-y-4">
              <div className="flex justify-between items-center">
                <button
                  onClick={downloadTemplate}
                  className="flex items-center px-3 py-1 text-sm text-blue-600 hover:bg-blue-50 rounded-md"
                >
                  <Download className="w-4 h-4 mr-1" />
                  Download Template
                </button>
                <input
                  ref={fileInputRef}
                  type="file"
                  accept=".xlsx,.csv"
                  onChange={(e) => {
                    const file = e.target.files?.[0];
                    if (file) processFile(file);
                  }}
                  className="hidden"
                />
                <button
                  onClick={() => fileInputRef.current?.click()}
                  disabled={isProcessing}
                  className="px-4 py-2 text-sm font-medium text-white bg-blue-600 rounded-md hover:bg-blue-700 disabled:opacity-50"
                >
                  Select File
                </button>
              </div>

              {isProcessing && (
                <div className="space-y-2">
                  <div className="h-2 bg-gray-200 rounded-full">
                    <div
                      className="h-2 bg-blue-600 rounded-full transition-all duration-300"
                      style={{ width: `${progress}%` }}
                    />
                  </div>
                  <p className="text-sm text-gray-500 text-center">
                    Processing file... {Math.round(progress)}%
                  </p>
                </div>
              )}

              {successCount > 0 && (
                <div className="flex items-center p-4 bg-green-50 text-green-700 rounded-lg">
                  <CheckCircle className="w-5 h-5 mr-2 flex-shrink-0" />
                  <p>Successfully imported {successCount} leads</p>
                </div>
              )}

              {errors.length > 0 && (
                <div className="p-4 bg-red-50 rounded-lg">
                  <div className="flex items-center mb-2">
                    <AlertCircle className="w-5 h-5 text-red-500 mr-2" />
                    <h3 className="font-medium text-red-800">Import Errors</h3>
                  </div>
                  <ul className="list-disc list-inside space-y-1">
                    {errors.map((error, index) => (
                      <li key={index} className="text-sm text-red-600">
                        {error}
                      </li>
                    ))}
                  </ul>
                </div>
              )}

              <div className="text-sm text-gray-500">
                <p>File requirements:</p>
                <ul className="list-disc list-inside">
                  <li>Excel (.xlsx) or CSV format</li>
                  <li>First row must contain column headers</li>
                  <li>Required fields: full_name, source</li>
                  <li>Optional fields: email, phone, preferred_size, notes, status</li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default ImportLeadsButton;