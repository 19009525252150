import React from 'react';
import { Facebook, Linkedin, Instagram, Rocket } from 'lucide-react';

const Footer: React.FC = () => {
  return (
    <footer className="py-12 bg-darkPurple border-t border-marianBlue/20">
      <div className="container mx-auto px-4">
        <div className="max-w-4xl mx-auto">
          <div className="flex flex-col md:flex-row justify-between items-center mb-8">
            <div className="flex items-center space-x-2 mb-4 md:mb-0">
              <Rocket className="w-6 h-6 text-lightBlue" />
              <span className="text-xl font-bold">MyOrbit</span>
            </div>
            <div className="flex space-x-6">
              <a href="#" className="text-gray-400 hover:text-lightBlue transition-colors">
                <Facebook className="w-6 h-6" />
              </a>
              <a href="#" className="text-gray-400 hover:text-lightBlue transition-colors">
                <Linkedin className="w-6 h-6" />
              </a>
              <a href="#" className="text-gray-400 hover:text-lightBlue transition-colors">
                <Instagram className="w-6 h-6" />
              </a>
            </div>
          </div>
          <div className="flex flex-col md:flex-row justify-between items-center text-sm text-gray-400">
            <div className="mb-4 md:mb-0">
              © 2024 MyOrbit. All rights reserved.
            </div>
            <div className="flex space-x-6">
              <a href="#" className="hover:text-lightBlue transition-colors">Privacy Policy</a>
              <a href="#" className="hover:text-lightBlue transition-colors">Terms of Service</a>
              <a href="#" className="hover:text-lightBlue transition-colors">Contact</a>
            </div>
          </div>
        </div>
      </div>
    </footer>
  );
};

export default Footer;