import React from 'react';
import { useForm, Controller } from 'react-hook-form';
import { X } from 'lucide-react';
import { Input, Button, Dropdown } from './TurnBoardComponents';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import type { Unit } from '../types/units';

interface AddUnitModalProps {
  isOpen: boolean;
  onClose: () => void;
  onAddUnit: (unit: Omit<Unit, 'id'>) => void;
}

const AddUnitModal: React.FC<AddUnitModalProps> = ({ isOpen, onClose, onAddUnit }) => {
  const { register, handleSubmit, control, reset } = useForm<Omit<Unit, 'id'>>();

  const onSubmit = (data: Omit<Unit, 'id'>) => {
    onAddUnit(data);
    reset();
  };

  if (!isOpen) return null;

  return (
    <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50 p-4">
      <div className="bg-white rounded-lg w-full max-w-3xl max-h-[90vh] overflow-y-auto">
        <div className="sticky top-0 bg-white px-6 py-4 border-b border-gray-200 flex justify-between items-center">
          <h2 className="text-xl sm:text-2xl font-bold">Add New Unit</h2>
          <button onClick={onClose} className="text-gray-500 hover:text-gray-700 p-2">
            <X size={24} />
          </button>
        </div>

        <form onSubmit={handleSubmit(onSubmit)} className="p-6 space-y-6">
          <div className="grid grid-cols-1 sm:grid-cols-2 gap-4">
            <div>
              <label className="block text-sm font-medium text-gray-700 mb-1">Apt Number</label>
              <Input {...register('aptNumber')} type="text" placeholder="Apt Number" />
            </div>
            <div>
              <label className="block text-sm font-medium text-gray-700 mb-1">Size</label>
              <Input {...register('size')} type="text" placeholder="Size" />
            </div>
          </div>

          <div className="grid grid-cols-1 sm:grid-cols-2 gap-4">
            <div>
              <label className="block text-sm font-medium text-gray-700 mb-1">Move-in Date</label>
              <Controller
                control={control}
                name="moveInDate"
                render={({ field }) => (
                  <DatePicker
                    selected={field.value ? new Date(field.value) : null}
                    onChange={(date: Date) => field.onChange(date.toISOString().split('T')[0])}
                    className="w-full p-2 border rounded"
                    dateFormat="MM/dd/yy"
                  />
                )}
              />
            </div>
            <div>
              <label className="block text-sm font-medium text-gray-700 mb-1">Future Resident</label>
              <Input {...register('futureResident')} type="text" placeholder="Future Resident" />
            </div>
          </div>

          <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-4">
            {['bulkTrash', 'paint', 'carpetFlooring', 'countertops', 'cabinets', 'tubReglaze', 'appliances', 'clean'].map((field) => (
              <div key={field}>
                <label className="block text-sm font-medium text-gray-700 mb-1">
                  {field.charAt(0).toUpperCase() + field.slice(1).replace(/([A-Z])/g, ' $1')}
                </label>
                <Controller
                  name={field as keyof Omit<Unit, 'id'>}
                  control={control}
                  defaultValue="Not Started"
                  render={({ field: { value, onChange } }) => (
                    <Dropdown
                      value={value}
                      onChange={(e) => onChange(e.target.value)}
                      options={['Not Started', 'Scheduled', 'Done']}
                    />
                  )}
                />
              </div>
            ))}
          </div>

          <div>
            <label className="block text-sm font-medium text-gray-700 mb-1">Notes</label>
            <textarea
              {...register('notes')}
              className="w-full p-2 border rounded"
              rows={3}
              placeholder="Additional notes..."
            ></textarea>
          </div>

          <div className="flex justify-end pt-4">
            <Button type="submit">Add Unit</Button>
          </div>
        </form>
      </div>
    </div>
  );
};

export default AddUnitModal;