import React from 'react';
import { motion } from 'framer-motion';
import { Star } from 'lucide-react';

interface ServicesProps {
  inView: boolean;
}

const Services: React.FC<ServicesProps> = ({ inView }) => {
  const services = [
    {
      title: 'Property Management',
      description: 'Streamline your property operations with our comprehensive management tools.',
      features: ['Unit tracking', 'Maintenance management', 'Financial reporting']
    },
    {
      title: 'Tenant Portal',
      description: 'Provide a seamless experience for your tenants with our user-friendly portal.',
      features: ['Online payments', 'Maintenance requests', 'Document management']
    },
    {
      title: 'Analytics Dashboard',
      description: 'Make data-driven decisions with our powerful analytics tools.',
      features: ['Real-time metrics', 'Custom reports', 'Performance tracking']
    },
    {
      title: 'Team Collaboration',
      description: 'Keep your team aligned and productive with our collaboration features.',
      features: ['Task management', 'Team chat', 'Role-based access']
    }
  ];

  return (
    <div className="grid grid-cols-1 md:grid-cols-2 gap-8">
      {services.map((service, index) => (
        <motion.div
          key={index}
          className="p-6 bg-darkPurple/20 rounded-lg"
          initial={{ opacity: 0, x: index % 2 === 0 ? -20 : 20 }}
          animate={inView ? { opacity: 1, x: 0 } : {}}
          transition={{ delay: index * 0.2, duration: 0.6 }}
        >
          <h3 className="text-xl font-semibold mb-4">{service.title}</h3>
          <p className="text-gray-300 mb-4">{service.description}</p>
          <ul className="space-y-2">
            {service.features.map((feature, i) => (
              <li key={i} className="flex items-center">
                <Star className="w-4 h-4 text-lightBlue mr-2" />
                <span>{feature}</span>
              </li>
            ))}
          </ul>
        </motion.div>
      ))}
    </div>
  );
};

export default Services;