import React from 'react';
import { getStatusColor } from '../utils/statusColors';
import VendorDetails from './VendorDetails';

interface TableProps {
  children: React.ReactNode;
  className?: string;
}

export const Table: React.FC<TableProps> = ({ children, className }) => (
  <div className="overflow-x-auto -mx-4 sm:mx-0">
    <div className="inline-block min-w-full align-middle">
      <table className={`min-w-full divide-y divide-gray-200 ${className}`}>
        {children}
      </table>
    </div>
  </div>
);

interface DropdownProps {
  value: string;
  onChange: (e: React.ChangeEvent<HTMLSelectElement>) => void;
  onVendorChange?: (vendor: string) => void;
  onScheduledDateChange?: (date: Date | null) => void;
  onCostChange?: (cost: number) => void;
  vendorName?: string;
  scheduledDate?: string | null;
  estimatedCost?: number;
  options: string[];
}

export const Dropdown: React.FC<DropdownProps> = ({
  value,
  onChange,
  options,
  onVendorChange,
  onScheduledDateChange,
  onCostChange,
  vendorName = '',
  scheduledDate,
  estimatedCost,
  ...props
}) => {
  const [isDetailsExpanded, setIsDetailsExpanded] = React.useState(false);

  React.useEffect(() => {
    if (value === 'Scheduled') {
      setIsDetailsExpanded(true);
    }
  }, [value]);

  const showVendorDetails = value === 'Scheduled' || value === 'Done' || Boolean(vendorName);

  return (
    <div className="space-y-2">
      <select
        value={value}
        onChange={onChange}
        className={`block w-full py-2 px-3 border rounded-md shadow-sm text-base sm:text-sm focus:outline-none focus:ring-1 focus:ring-blue-500 focus:border-blue-500 touch-manipulation ${getStatusColor(value)}`}
        {...props}
      >
        {options.map((option) => (
          <option key={option} value={option}>
            {option}
          </option>
        ))}
      </select>

      {showVendorDetails && onVendorChange && onScheduledDateChange && onCostChange && (
        <VendorDetails
          isExpanded={isDetailsExpanded}
          onToggle={() => setIsDetailsExpanded(!isDetailsExpanded)}
          vendorName={vendorName}
          scheduledDate={scheduledDate}
          estimatedCost={estimatedCost}
          onVendorChange={onVendorChange}
          onDateChange={onScheduledDateChange}
          onCostChange={onCostChange}
          status={value}
        />
      )}
    </div>
  );
};

interface InputProps extends React.InputHTMLAttributes<HTMLInputElement> {}

export const Input = React.forwardRef<HTMLInputElement, InputProps>((props, ref) => (
  <input
    {...props}
    ref={ref}
    className={`block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm text-base sm:text-sm focus:outline-none focus:ring-1 focus:ring-blue-500 focus:border-blue-500 touch-manipulation ${props.className}`}
  />
));

Input.displayName = 'Input';

interface ButtonProps extends React.ButtonHTMLAttributes<HTMLButtonElement> {
  children: React.ReactNode;
}

export const Button: React.FC<ButtonProps> = ({ children, ...props }) => (
  <button
    {...props}
    className={`inline-flex items-center px-4 py-2 border border-transparent text-base sm:text-sm font-medium rounded-md shadow-sm text-white bg-blue-600 hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500 touch-manipulation ${props.className}`}
  >
    {children}
  </button>
);