import { useState, useCallback } from 'react';
import { supabase } from '../lib/supabase';
import { useAuth } from '../contexts/AuthContext';
import type { Property } from '../types/property';

export const useProperties = () => {
  const { user } = useAuth();
  const [isCreating, setIsCreating] = useState(false);
  const [error, setError] = useState<string | null>(null);

  const fetchProperties = useCallback(async () => {
    if (!user?.id) return [];

    try {
      // First get properties where user is owner
      const { data: ownedProperties, error: ownedError } = await supabase
        .from('properties')
        .select('*')
        .eq('owner_id', user.id);

      if (ownedError) throw ownedError;

      // Then get properties where user is a collaborator
      const { data: collaborations, error: collabError } = await supabase
        .from('property_collaborators')
        .select(`
          property:property_id (*)
        `)
        .eq('user_id', user.id)
        .neq('role', 'owner');

      if (collabError) throw collabError;

      // Combine and deduplicate properties
      const collaborativeProperties = collaborations
        ?.map(c => c.property)
        .filter((p): p is Property => p !== null);

      const allProperties = [
        ...(ownedProperties || []),
        ...(collaborativeProperties || [])
      ];

      // Remove duplicates based on property id
      const uniqueProperties = Array.from(
        new Map(allProperties.map(p => [p.id, p])).values()
      );

      return uniqueProperties;
    } catch (err) {
      console.error('Error fetching properties:', err);
      throw err;
    }
  }, [user?.id]);

  const createProperty = useCallback(async (name: string, location?: string): Promise<Property | null> => {
    if (!user?.id) {
      setError('You must be logged in to create a property');
      return null;
    }

    try {
      setIsCreating(true);
      setError(null);

      const { data, error: createError } = await supabase
        .from('properties')
        .insert([{
          name: name.trim(),
          location: location?.trim(),
          type: 'residential',
          units: 0,
          owner_id: user.id
        }])
        .select()
        .single();

      if (createError) throw createError;
      return data;
    } catch (err) {
      console.error('Error creating property:', err);
      setError('Failed to create property');
      return null;
    } finally {
      setIsCreating(false);
    }
  }, [user?.id]);

  const deleteProperty = useCallback(async (id: string): Promise<void> => {
    if (!user?.id) {
      setError('You must be logged in to delete a property');
      return;
    }

    try {
      setError(null);
      const { error: deleteError } = await supabase
        .from('properties')
        .delete()
        .eq('id', id)
        .eq('owner_id', user.id);

      if (deleteError) throw deleteError;
    } catch (err) {
      console.error('Error deleting property:', err);
      setError('Failed to delete property');
      throw err;
    }
  }, [user?.id]);

  return {
    fetchProperties,
    createProperty,
    deleteProperty,
    isCreating,
    error
  };
};

export default useProperties;