import { useState, useCallback, useEffect } from 'react';
import { supabase } from '../lib/supabase';
import { useProperty } from '../contexts/PropertyContext';
import type { BaseTask, DailyTask, TodoTask, CompletedTask, TaskType } from '../types/tasks';

export const useTaskManagement = () => {
  const [dailyTasks, setDailyTasks] = useState<DailyTask[]>([]);
  const [todos, setTodos] = useState<TodoTask[]>([]);
  const [completedTasks, setCompletedTasks] = useState<CompletedTask[]>([]);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState<string | null>(null);
  const { selectedPropertyId } = useProperty();

  const fetchTasks = useCallback(async () => {
    if (!selectedPropertyId) {
      setDailyTasks([]);
      setTodos([]);
      setCompletedTasks([]);
      setIsLoading(false);
      return;
    }

    try {
      setIsLoading(true);
      setError(null);

      // Fetch active tasks
      const { data: activeTasks, error: activeError } = await supabase
        .from('tasks')
        .select('*')
        .eq('property_id', selectedPropertyId)
        .eq('status', 'active')
        .order('created_at', { ascending: false });

      if (activeError) throw activeError;

      // Fetch completed tasks
      const { data: completedData, error: completedError } = await supabase
        .from('completed_tasks')
        .select('*')
        .eq('property_id', selectedPropertyId)
        .order('completed_at', { ascending: false });

      if (completedError) throw completedError;

      // Split active tasks by type
      setDailyTasks(activeTasks?.filter(task => task.type === 'daily') || []);
      setTodos(activeTasks?.filter(task => task.type === 'todo') || []);
      setCompletedTasks(completedData || []);
    } catch (err) {
      console.error('Error fetching tasks:', err);
      setError('Failed to load tasks');
    } finally {
      setIsLoading(false);
    }
  }, [selectedPropertyId]);

  const addTask = async (task: Omit<BaseTask, 'id' | 'status' | 'created_at' | 'updated_at'>, type: TaskType) => {
    if (!selectedPropertyId) throw new Error('No property selected');

    try {
      const { data, error } = await supabase
        .from('tasks')
        .insert([{
          ...task,
          property_id: selectedPropertyId,
          type,
          status: 'active',
          completed: false
        }])
        .select()
        .single();

      if (error) throw error;

      if (type === 'daily') {
        setDailyTasks(prev => [data, ...prev]);
      } else {
        setTodos(prev => [data, ...prev]);
      }

      return data;
    } catch (err) {
      console.error('Error adding task:', err);
      throw err;
    }
  };

  const updateTask = async (id: string, updates: Partial<BaseTask>) => {
    try {
      const { data, error } = await supabase
        .from('tasks')
        .update(updates)
        .eq('id', id)
        .select()
        .single();

      if (error) throw error;

      // If task was completed, move it to completed_tasks
      if (updates.completed) {
        const taskType = data.type;
        if (taskType === 'daily') {
          setDailyTasks(prev => prev.filter(task => task.id !== id));
        } else {
          setTodos(prev => prev.filter(task => task.id !== id));
        }
        await fetchTasks(); // Refresh to get the new completed task
      } else {
        // Update local state for non-completion updates
        if (data.type === 'daily') {
          setDailyTasks(prev => prev.map(task => task.id === id ? data : task));
        } else {
          setTodos(prev => prev.map(task => task.id === id ? data : task));
        }
      }

      return data;
    } catch (err) {
      console.error('Error updating task:', err);
      throw err;
    }
  };

  const restoreTask = async (taskId: string) => {
    try {
      const task = completedTasks.find(t => t.id === taskId);
      if (!task) throw new Error('Task not found');

      // Move task back to active state
      const { error: restoreError } = await supabase
        .from('tasks')
        .update({
          completed: false,
          status: 'active',
          completed_at: null
        })
        .eq('id', task.original_task_id);

      if (restoreError) throw restoreError;

      // Remove from completed_tasks
      const { error: deleteError } = await supabase
        .from('completed_tasks')
        .delete()
        .eq('id', taskId);

      if (deleteError) throw deleteError;

      await fetchTasks();
    } catch (err) {
      console.error('Error restoring task:', err);
      throw err;
    }
  };

  const deleteTask = async (taskId: string) => {
    try {
      const { error } = await supabase
        .from('completed_tasks')
        .delete()
        .eq('id', taskId);

      if (error) throw error;

      setCompletedTasks(prev => prev.filter(task => task.id !== taskId));
    } catch (err) {
      console.error('Error deleting task:', err);
      throw err;
    }
  };

  useEffect(() => {
    fetchTasks();

    if (selectedPropertyId) {
      const channels = ['tasks', 'completed_tasks'].map(table => 
        supabase
          .channel(`${table}_changes`)
          .on('postgres_changes', 
            { 
              event: '*', 
              schema: 'public',
              table,
              filter: `property_id=eq.${selectedPropertyId}`
            }, 
            () => {
              fetchTasks();
            }
          )
          .subscribe()
      );

      return () => {
        channels.forEach(channel => supabase.removeChannel(channel));
      };
    }
  }, [selectedPropertyId, fetchTasks]);

  return {
    dailyTasks,
    todos,
    completedTasks,
    isLoading,
    error,
    addTask,
    updateTask,
    restoreTask,
    deleteTask,
    refreshTasks: fetchTasks
  };
};

export default useTaskManagement;