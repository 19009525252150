import { useState, useCallback, useEffect } from 'react';
import { format, startOfWeek, endOfWeek } from 'date-fns';
import { supabase } from '../lib/supabase';
import type { DailyMetrics } from '../types/performance';

export const useWeeklyMetrics = (propertyId: string | null, startDate: Date) => {
  const [metrics, setMetrics] = useState<Record<string, Record<string, number>>>({});
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState<string | null>(null);

  const fetchMetrics = useCallback(async () => {
    if (!propertyId) {
      setMetrics({});
      setIsLoading(false);
      return;
    }

    try {
      setIsLoading(true);
      setError(null);

      const weekStart = startOfWeek(startDate, { weekStartsOn: 1 });
      const weekEnd = endOfWeek(startDate, { weekStartsOn: 1 });

      const { data, error: fetchError } = await supabase
        .from('daily_metrics')
        .select('*')
        .eq('property_id', propertyId)
        .gte('date', format(weekStart, 'yyyy-MM-dd'))
        .lte('date', format(weekEnd, 'yyyy-MM-dd'));

      if (fetchError) throw fetchError;

      const formattedMetrics = (data || []).reduce((acc, metric) => ({
        ...acc,
        [metric.date]: {
          calls: metric.calls,
          emails: metric.emails,
          tours: metric.tours,
          applications: metric.applications
        }
      }), {});

      setMetrics(formattedMetrics);
    } catch (err) {
      console.error('Error fetching metrics:', err);
      setError('Failed to load metrics');
    } finally {
      setIsLoading(false);
    }
  }, [propertyId, startDate]);

  const updateMetric = useCallback(async (date: string, field: string, value: number) => {
    if (!propertyId) return;

    try {
      const { data: existing } = await supabase
        .from('daily_metrics')
        .select('id')
        .eq('property_id', propertyId)
        .eq('date', date)
        .single();

      if (existing) {
        await supabase
          .from('daily_metrics')
          .update({ [field]: value, updated_at: new Date().toISOString() })
          .eq('id', existing.id);
      } else {
        await supabase
          .from('daily_metrics')
          .insert([{
            property_id: propertyId,
            date,
            [field]: value,
            calls: field === 'calls' ? value : 0,
            emails: field === 'emails' ? value : 0,
            tours: field === 'tours' ? value : 0,
            applications: field === 'applications' ? value : 0
          }]);
      }

      // Update local state
      setMetrics(prev => ({
        ...prev,
        [date]: {
          ...(prev[date] || {}),
          [field]: value
        }
      }));
    } catch (err) {
      console.error('Error updating metric:', err);
      throw new Error('Failed to update metric');
    }
  }, [propertyId]);

  useEffect(() => {
    fetchMetrics();

    if (propertyId) {
      const channel = supabase
        .channel('daily_metrics_changes')
        .on('postgres_changes', 
          { 
            event: '*', 
            schema: 'public',
            table: 'daily_metrics',
            filter: `property_id=eq.${propertyId}`
          }, 
          () => {
            fetchMetrics();
          }
        )
        .subscribe();

      return () => {
        supabase.removeChannel(channel);
      };
    }
  }, [propertyId, fetchMetrics]);

  return {
    metrics,
    isLoading,
    error,
    updateMetric,
    refreshMetrics: fetchMetrics
  };
};

export default useWeeklyMetrics;