import React from 'react';
import { Routes, Route, Navigate } from 'react-router-dom';
import { AuthProvider } from './contexts/AuthContext';
import { PropertyProvider } from './contexts/PropertyContext';
import Auth from './pages/Auth';
import Landing from './pages/Landing';
import PrivateRoute from './components/PrivateRoute';
import Layout from './components/Layout';
import Dashboard from './pages/Dashboard';
import Applications from './pages/Applications';
import KeyLog from './pages/KeyLog';
import Leads from './pages/Leads';
import TurnBoard from './pages/TurnBoard';
import WeeklyPerformance from './pages/WeeklyPerformance';
import Settings from './pages/WorkspaceSettings';
import Profile from './pages/Profile';

const App: React.FC = () => {
  return (
    <AuthProvider>
      <PropertyProvider>
        <Routes>
          <Route path="/" element={<Landing />} />
          <Route path="/auth" element={<Auth />} />
          
          <Route element={<Layout />}>
            <Route path="/dashboard" element={
              <PrivateRoute>
                <Dashboard />
              </PrivateRoute>
            } />
            
            <Route path="/leads" element={
              <PrivateRoute>
                <Leads />
              </PrivateRoute>
            } />
            
            <Route path="/applications" element={
              <PrivateRoute>
                <Applications />
              </PrivateRoute>
            } />
            
            <Route path="/turn-board" element={
              <PrivateRoute>
                <TurnBoard />
              </PrivateRoute>
            } />
            
            <Route path="/key-log" element={
              <PrivateRoute>
                <KeyLog />
              </PrivateRoute>
            } />
            
            <Route path="/weekly-performance" element={
              <PrivateRoute>
                <WeeklyPerformance />
              </PrivateRoute>
            } />
            
            <Route path="/settings" element={
              <PrivateRoute>
                <Settings />
              </PrivateRoute>
            } />

            <Route path="/profile" element={
              <PrivateRoute>
                <Profile />
              </PrivateRoute>
            } />

            {/* Redirect any unknown routes to dashboard */}
            <Route path="*" element={<Navigate to="/dashboard" replace />} />
          </Route>
        </Routes>
      </PropertyProvider>
    </AuthProvider>
  );
};

export default App;