import React from 'react';

interface LogoProps {
  isExpanded: boolean;
}

const Logo: React.FC<LogoProps> = ({ isExpanded }) => {
  return (
    <div className={`
      flex items-center justify-center
      transition-all duration-300 ease-in-out
      ${isExpanded ? 'px-4' : 'px-2'}
    `}>
      <img
        src="/myorbit-logo-full.png"
        alt="MyOrbit"
        className={`
          transition-all duration-300 ease-in-out
          ${isExpanded ? 'w-40' : 'w-12'}
          object-contain
          opacity-80 hover:opacity-100
        `}
        style={{
          filter: 'brightness(0) invert(1)',
          maxHeight: isExpanded ? '40px' : '32px'
        }}
      />
    </div>
  );
};

export default Logo;