import { createClient } from '@supabase/supabase-js';
import { retryOperation } from '../utils/retry';

const supabaseUrl = import.meta.env.VITE_SUPABASE_URL;
const supabaseAnonKey = import.meta.env.VITE_SUPABASE_ANON_KEY;

if (!supabaseUrl || !supabaseAnonKey) {
  throw new Error('Missing Supabase environment variables');
}

export const supabase = createClient(supabaseUrl, supabaseAnonKey, {
  auth: {
    autoRefreshToken: true,
    persistSession: true,
    detectSessionInUrl: true
  }
});

let isInitialized = false;
let initializationPromise: Promise<void> | null = null;

export const initializeDatabase = async () => {
  if (isInitialized) return;
  
  if (!initializationPromise) {
    initializationPromise = (async () => {
      try {
        // Simple database health check
        const { data, error } = await supabase
          .from('properties')
          .select('id')
          .limit(1)
          .maybeSingle();

        // Only throw error if it's not a "no rows returned" error
        if (error && error.code !== 'PGRST116') {
          console.error('Database initialization error:', error);
          throw error;
        }
        
        isInitialized = true;
        console.log('Database connection initialized successfully');
      } catch (err) {
        console.error('Failed to initialize database:', err);
        throw new Error('Failed to initialize database connection');
      }
    })();
  }

  return initializationPromise;
};

export const supabaseQuery = async <T>(
  operation: () => Promise<{ data: T | null; error: any }>,
  retries = 3
): Promise<T> => {
  if (!isInitialized) {
    await initializeDatabase();
  }

  return retryOperation(async () => {
    const { data, error } = await operation();
    
    if (error) {
      // Log error details for debugging
      console.error('Query error:', {
        code: error.code,
        message: error.message,
        details: error.details,
        hint: error.hint
      });

      // Handle auth errors
      if (error.code === 'PGRST301' || error.code === 'JWT expired') {
        // Try to refresh the session
        const { data: { session }, error: refreshError } = await supabase.auth.refreshSession();
        if (!refreshError && session) {
          // Retry the operation after successful refresh
          const retryResult = await operation();
          if (retryResult.error) throw retryResult.error;
          return retryResult.data!;
        }
      }
      throw error;
    }
    
    if (!data) {
      throw new Error('No data returned from query');
    }
    
    return data;
  }, retries);
};

export default supabase;