import React from 'react';
import DailyTasks from '../components/dashboard/DailyTasks';
import QuickTasks from '../components/dashboard/QuickTasks';
import { useTaskManagement } from '../hooks/useTaskManagement';
import { useProperty } from '../contexts/PropertyContext';

const Dashboard: React.FC = () => {
  const { selectedPropertyId } = useProperty();
  const {
    dailyTasks,
    todos,
    completedTasks,
    isLoading,
    error,
    addTask,
    updateTask,
    deleteTask
  } = useTaskManagement();

  if (!selectedPropertyId) {
    return (
      <div className="text-center py-8 text-gray-500">
        Please select a property from the menu to view the dashboard
      </div>
    );
  }

  if (error) {
    return (
      <div className="bg-red-50 border border-red-200 rounded-lg p-4">
        <p className="text-red-800">{error}</p>
      </div>
    );
  }

  const handleDailyTaskCreate = async (task: any) => {
    await addTask(task, 'daily');
  };

  const handleDailyTaskUpdate = async (id: string, updates: any) => {
    await updateTask(id, updates, 'daily');
  };

  const handleTodoCreate = async (task: any) => {
    await addTask(task, 'todo');
  };

  const handleTodoUpdate = async (id: string, updates: any) => {
    await updateTask(id, updates, 'todo');
  };

  return (
    <div className="space-y-6">
      <h1 className="text-2xl font-bold">Dashboard</h1>

      <div className="grid grid-cols-1 gap-6">
        <DailyTasks
          tasks={dailyTasks}
          onTaskUpdate={handleDailyTaskUpdate}
          onTaskCreate={handleDailyTaskCreate}
          onTaskDelete={(id) => deleteTask(id)}
        />
        
        <QuickTasks
          tasks={todos}
          onTaskUpdate={handleTodoUpdate}
          onTaskCreate={handleTodoCreate}
          onTaskDelete={(id) => deleteTask(id)}
        />
      </div>
    </div>
  );
};

export default Dashboard;