import React from 'react';
import { ChevronDown, ChevronUp, DollarSign } from 'lucide-react';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';

interface VendorDetailsProps {
  isExpanded: boolean;
  onToggle: () => void;
  vendorName: string;
  scheduledDate: string | null;
  estimatedCost: number | undefined;
  onVendorChange: (value: string) => void;
  onDateChange: (date: Date | null) => void;
  onCostChange: (value: number) => void;
  status: string;
}

const VendorDetails: React.FC<VendorDetailsProps> = ({
  isExpanded,
  onToggle,
  vendorName,
  scheduledDate,
  estimatedCost,
  onVendorChange,
  onDateChange,
  onCostChange,
  status
}) => {
  const isReadOnly = status === 'Done';

  return (
    <div className="mt-2">
      <button
        onClick={onToggle}
        className="flex items-center text-base sm:text-sm text-gray-600 hover:text-gray-900 w-full justify-between bg-gray-50 p-3 sm:p-2 rounded-md touch-manipulation"
      >
        <div className="flex items-center">
          <span className="mr-2">Vendor Details</span>
          {vendorName && (
            <span className="text-sm sm:text-xs bg-blue-100 text-blue-800 px-2 py-1 rounded">
              {vendorName}
            </span>
          )}
        </div>
        {isExpanded ? <ChevronUp size={20} /> : <ChevronDown size={20} />}
      </button>

      {isExpanded && (
        <div className="mt-2 p-4 sm:p-3 bg-gray-50 rounded-md space-y-4 sm:space-y-3">
          <div>
            <label className="block text-base sm:text-sm font-medium text-gray-700 mb-1">
              Vendor Name
            </label>
            <input
              type="text"
              value={vendorName || ''}
              onChange={(e) => onVendorChange(e.target.value)}
              className={`block w-full px-4 sm:px-3 py-3 sm:py-2 border border-gray-300 rounded-md shadow-sm text-base sm:text-sm focus:outline-none focus:ring-1 focus:ring-blue-500 focus:border-blue-500 touch-manipulation ${
                isReadOnly ? 'bg-gray-100' : ''
              }`}
              placeholder="Enter vendor name"
              readOnly={isReadOnly}
            />
          </div>

          <div>
            <label className="block text-base sm:text-sm font-medium text-gray-700 mb-1">
              Scheduled Date
              {status === 'Scheduled' && !scheduledDate && (
                <span className="text-red-500 ml-1">*</span>
              )}
            </label>
            <DatePicker
              selected={scheduledDate ? new Date(scheduledDate) : null}
              onChange={(date: Date | null) => onDateChange(date)}
              className={`block w-full px-4 sm:px-3 py-3 sm:py-2 border border-gray-300 rounded-md shadow-sm text-base sm:text-sm focus:outline-none focus:ring-1 focus:ring-blue-500 focus:border-blue-500 touch-manipulation ${
                isReadOnly ? 'bg-gray-100' : ''
              }`}
              placeholderText="Select date"
              dateFormat="MM/dd/yyyy"
              readOnly={isReadOnly}
            />
          </div>

          <div>
            <label className="block text-base sm:text-sm font-medium text-gray-700 mb-1">
              Estimated Cost
              {status === 'Scheduled' && !estimatedCost && (
                <span className="text-red-500 ml-1">*</span>
              )}
            </label>
            <div className="relative rounded-md shadow-sm">
              <div className="absolute inset-y-0 left-0 pl-4 sm:pl-3 flex items-center pointer-events-none">
                <DollarSign className="h-5 w-5 sm:h-4 sm:w-4 text-gray-400" />
              </div>
              <input
                type="number"
                value={estimatedCost || ''}
                onChange={(e) => onCostChange(parseFloat(e.target.value) || 0)}
                className={`block w-full pl-10 sm:pl-8 pr-4 sm:pr-3 py-3 sm:py-2 border border-gray-300 rounded-md shadow-sm text-base sm:text-sm focus:outline-none focus:ring-1 focus:ring-blue-500 focus:border-blue-500 touch-manipulation ${
                  isReadOnly ? 'bg-gray-100' : ''
                }`}
                placeholder="0.00"
                step="0.01"
                min="0"
                readOnly={isReadOnly}
              />
            </div>
          </div>

          {status === 'Scheduled' && (!scheduledDate || !estimatedCost) && (
            <p className="text-sm text-red-500">
              * Required fields must be filled when status is "Scheduled"
            </p>
          )}

          {isReadOnly && (
            <div className="text-sm text-gray-500 bg-gray-100 p-3 rounded">
              This task is marked as done. Vendor details are read-only.
            </div>
          )}
        </div>
      )}
    </div>
  );
};

export default VendorDetails;