import { useState, useCallback, useEffect } from 'react';
import { supabase } from '../lib/supabase';
import { useProperty } from '../contexts/PropertyContext';
import { retryOperation } from '../utils/retry';

interface PropertySettings {
  lease_goal?: number;
  current_leases?: number;
}

export const usePropertySettings = () => {
  const [settings, setSettings] = useState<PropertySettings>({});
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState<string | null>(null);
  const { selectedPropertyId } = useProperty();

  const fetchSettings = useCallback(async () => {
    if (!selectedPropertyId) {
      setSettings({});
      setIsLoading(false);
      return;
    }

    try {
      setIsLoading(true);
      setError(null);

      const { data: existingSettings, error: fetchError } = await retryOperation(async () => 
        supabase
          .from('property_settings')
          .select('*')
          .eq('property_id', selectedPropertyId)
          .single()
      );

      if (fetchError && fetchError.code !== 'PGRST116') {
        throw fetchError;
      }

      // If no settings exist, create default settings
      if (!existingSettings) {
        const { data: newSettings, error: createError } = await retryOperation(async () =>
          supabase
            .from('property_settings')
            .insert([{
              property_id: selectedPropertyId,
              lease_goal: 10,
              current_leases: 0
            }])
            .select()
            .single()
        );

        if (createError) throw createError;
        setSettings(newSettings || {});
      } else {
        setSettings(existingSettings);
      }
    } catch (err) {
      console.error('Error fetching property settings:', err);
      setError('Failed to load property settings');
    } finally {
      setIsLoading(false);
    }
  }, [selectedPropertyId]);

  const updateSettings = async (updates: Partial<PropertySettings>) => {
    if (!selectedPropertyId) throw new Error('No property selected');

    try {
      const { error } = await retryOperation(async () =>
        supabase
          .from('property_settings')
          .upsert({
            property_id: selectedPropertyId,
            ...settings,
            ...updates,
            updated_at: new Date().toISOString()
          })
      );

      if (error) throw error;

      setSettings(prev => ({
        ...prev,
        ...updates
      }));
    } catch (err) {
      console.error('Error updating property settings:', err);
      throw err;
    }
  };

  useEffect(() => {
    fetchSettings();

    if (selectedPropertyId) {
      const channel = supabase
        .channel('property_settings_changes')
        .on('postgres_changes',
          {
            event: '*',
            schema: 'public',
            table: 'property_settings',
            filter: `property_id=eq.${selectedPropertyId}`
          },
          () => {
            fetchSettings();
          }
        )
        .subscribe();

      return () => {
        supabase.removeChannel(channel);
      };
    }
  }, [selectedPropertyId, fetchSettings]);

  return {
    settings,
    isLoading,
    error,
    updateSettings
  };
};

export default usePropertySettings;