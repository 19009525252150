import React, { memo } from 'react';
import CompactUnitCard from './CompactUnitCard';
import type { Unit } from '../../types/units';

interface UnitListProps {
  units: Unit[];
  expandedUnits: number[];
  onToggleExpand: (id: number) => void;
  onUnitChange: (id: number, field: keyof Unit, value: any) => void;
}

const UnitList = memo(({ 
  units, 
  expandedUnits, 
  onToggleExpand, 
  onUnitChange 
}: UnitListProps) => {
  if (units.length === 0) {
    return (
      <div className="bg-white rounded-b-lg shadow-sm border border-gray-200 p-8">
        <p className="text-center text-gray-500">No units found</p>
      </div>
    );
  }

  return (
    <div className="bg-white rounded-b-lg shadow-sm border border-gray-200 divide-y divide-gray-200">
      {units.map((unit) => (
        <CompactUnitCard
          key={unit.id}
          unit={unit}
          isExpanded={expandedUnits.includes(unit.id)}
          onToggleExpand={() => onToggleExpand(unit.id)}
          onUnitChange={(field, value) => onUnitChange(unit.id, field, value)}
        />
      ))}
    </div>
  );
}, (prevProps, nextProps) => {
  return (
    JSON.stringify(prevProps.units) === JSON.stringify(nextProps.units) &&
    JSON.stringify(prevProps.expandedUnits) === JSON.stringify(nextProps.expandedUnits)
  );
});

UnitList.displayName = 'UnitList';

export default UnitList;