import React, { useState } from 'react';
import { useForm } from 'react-hook-form';
import { zodResolver } from '@hookform/resolvers/zod';
import { z } from 'zod';
import { Mail, Lock } from 'lucide-react';
import FormInput from './FormInput';
import SubmitButton from './SubmitButton';

const registerSchema = z.object({
  email: z.string().email('Invalid email address'),
  password: z.string()
    .min(8, 'Password must be at least 8 characters')
    .regex(
      /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}$/,
      'Password must contain at least one uppercase letter, one lowercase letter, one number, and one special character'
    ),
  confirmPassword: z.string()
}).refine((data) => data.password === data.confirmPassword, {
  message: "Passwords don't match",
  path: ["confirmPassword"]
});

type RegisterFormData = z.infer<typeof registerSchema>;

interface RegisterFormProps {
  onSubmit: (data: { email: string; password: string }) => Promise<void>;
}

const RegisterForm: React.FC<RegisterFormProps> = ({ onSubmit }) => {
  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);
  
  const {
    register,
    handleSubmit,
    formState: { errors, isSubmitting }
  } = useForm<RegisterFormData>({
    resolver: zodResolver(registerSchema)
  });

  const handleFormSubmit = async (data: RegisterFormData) => {
    await onSubmit({
      email: data.email,
      password: data.password
    });
  };

  return (
    <form className="space-y-6" onSubmit={handleSubmit(handleFormSubmit)} noValidate>
      <FormInput
        id="register-email"
        type="email"
        label="Email address"
        error={errors.email?.message}
        Icon={Mail}
        register={register('email')}
        autoComplete="email"
      />

      <FormInput
        id="register-password"
        type="password"
        label="Password"
        error={errors.password?.message}
        Icon={Lock}
        showPassword={showPassword}
        togglePassword={() => setShowPassword(!showPassword)}
        register={register('password')}
        autoComplete="new-password"
      />

      <FormInput
        id="confirm-password"
        type="password"
        label="Confirm Password"
        error={errors.confirmPassword?.message}
        Icon={Lock}
        showPassword={showConfirmPassword}
        togglePassword={() => setShowConfirmPassword(!showConfirmPassword)}
        register={register('confirmPassword')}
        autoComplete="new-password"
      />

      <SubmitButton isSubmitting={isSubmitting}>
        Create account
      </SubmitButton>
    </form>
  );
};

export default RegisterForm;