import React from 'react';
import { Link } from 'react-router-dom';
import { motion } from 'framer-motion';
import { Rocket, ArrowRight } from 'lucide-react';

interface HeroProps {
  inView: boolean;
}

const Hero: React.FC<HeroProps> = ({ inView }) => {
  return (
    <motion.div 
      className="container mx-auto px-4 pt-20"
      initial={{ opacity: 0, y: 20 }}
      animate={inView ? { opacity: 1, y: 0 } : {}}
      transition={{ duration: 0.8 }}
    >
      <div className="max-w-4xl mx-auto text-center">
        <motion.div
          initial={{ scale: 0.8, opacity: 0 }}
          animate={inView ? { scale: 1, opacity: 1 } : {}}
          transition={{ delay: 0.2, duration: 0.8 }}
          className="mb-8"
        >
          <Rocket className="w-20 h-20 mx-auto text-lightBlue" />
        </motion.div>
        <h1 className="text-4xl md:text-6xl font-bold mb-6">
          🚀 Elevate Your Property Management Experience
        </h1>
        <p className="text-xl md:text-2xl text-lightBlue mb-8">
          Streamline Operations, Enhance Collaboration, and Drive Growth with Our All-in-One Platform
        </p>
        <Link
          to="/auth"
          className="inline-flex items-center px-8 py-4 bg-lightBlue text-darkPurple rounded-lg text-lg font-semibold hover:bg-mintGreen transition-all transform hover:scale-105"
        >
          Get Started Free
          <ArrowRight className="ml-2 w-5 h-5" />
        </Link>
      </div>
    </motion.div>
  );
};

export default Hero;