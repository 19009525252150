import { useState, useCallback } from 'react';
import { supabase } from '../lib/supabase';
import type { CollaboratorRole, JoinPropertyResponse } from '../types/collaboration';
import { isValidPropertyCode } from '../utils/propertyCode';

export const usePropertyCollaboration = (propertyId: string) => {
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState<string | null>(null);

  const getPropertyCode = useCallback(async () => {
    try {
      setIsLoading(true);
      setError(null);

      const { data, error: fetchError } = await supabase
        .from('properties')
        .select('property_code')
        .eq('id', propertyId)
        .single();

      if (fetchError) throw fetchError;
      return data?.property_code;
    } catch (err) {
      console.error('Error fetching property code:', err);
      setError('Failed to fetch property code');
      return null;
    } finally {
      setIsLoading(false);
    }
  }, [propertyId]);

  const joinProperty = async (code: string): Promise<JoinPropertyResponse> => {
    if (!isValidPropertyCode(code)) {
      return { success: false, error: 'Invalid property code format' };
    }

    try {
      setIsLoading(true);
      setError(null);

      // Get current user
      const { data: { user }, error: userError } = await supabase.auth.getUser();
      if (userError) throw userError;
      if (!user) return { success: false, error: 'Not authenticated' };

      // Find property by code
      const { data: property, error: propertyError } = await supabase
        .from('properties')
        .select('id')
        .eq('property_code', code)
        .single();

      if (propertyError) throw propertyError;
      if (!property) return { success: false, error: 'Invalid property code' };

      // Check if already a collaborator
      const { data: existing, error: existingError } = await supabase
        .from('property_collaborators')
        .select('id')
        .eq('property_id', property.id)
        .eq('user_id', user.id)
        .single();

      if (existingError && existingError.code !== 'PGRST116') throw existingError;
      if (existing) return { success: false, error: 'Already a collaborator' };

      // Add user as collaborator
      const { error: collaboratorError } = await supabase
        .from('property_collaborators')
        .insert([{
          property_id: property.id,
          user_id: user.id,
          role: 'editor' as CollaboratorRole
        }]);

      if (collaboratorError) throw collaboratorError;

      return { success: true, propertyId: property.id };
    } catch (err) {
      console.error('Error joining property:', err);
      return { success: false, error: 'Failed to join property' };
    } finally {
      setIsLoading(false);
    }
  };

  const getCollaborators = useCallback(async () => {
    if (!propertyId) return [];

    try {
      setIsLoading(true);
      setError(null);

      const { data, error: fetchError } = await supabase
        .from('property_collaborators')
        .select(`
          id,
          user_id,
          role,
          joined_at,
          users:user_id (
            email,
            user_metadata
          )
        `)
        .eq('property_id', propertyId)
        .order('joined_at', { ascending: false });

      if (fetchError) throw fetchError;
      return data || [];
    } catch (err) {
      console.error('Error fetching collaborators:', err);
      setError('Failed to fetch collaborators');
      return [];
    } finally {
      setIsLoading(false);
    }
  }, [propertyId]);

  return {
    getPropertyCode,
    joinProperty,
    getCollaborators,
    isLoading,
    error
  };
};

export default usePropertyCollaboration;