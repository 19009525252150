import React, { memo } from 'react';
import { Search } from 'lucide-react';
import { Input } from '../ui/TurnBoardComponents';

interface FilterBarProps {
  filter: string;
  sortBy: 'aptNumber' | 'moveInDate';
  onFilterChange: (value: string) => void;
  onSortChange: (value: 'aptNumber' | 'moveInDate') => void;
  disabled?: boolean;
}

const FilterBar = memo(({ 
  filter, 
  sortBy, 
  onFilterChange, 
  onSortChange,
  disabled = false 
}: FilterBarProps) => {
  return (
    <div className="flex items-center gap-4 flex-1">
      <div className="relative flex-1 max-w-md">
        <Search className="absolute left-3 top-1/2 transform -translate-y-1/2 text-gray-400" />
        <Input
          type="text"
          placeholder="Filter units..."
          value={filter}
          onChange={(e) => onFilterChange(e.target.value)}
          className="pl-10"
          disabled={disabled}
        />
      </div>
      <select
        value={sortBy}
        onChange={(e) => onSortChange(e.target.value as 'aptNumber' | 'moveInDate')}
        className="border rounded-md px-3 py-2 text-sm"
        disabled={disabled}
      >
        <option value="aptNumber">Sort by Apt #</option>
        <option value="moveInDate">Sort by Move-in Date</option>
      </select>
    </div>
  );
});

FilterBar.displayName = 'FilterBar';

export default FilterBar;