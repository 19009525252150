import React, { useState } from 'react';
import { useForm } from 'react-hook-form';
import { zodResolver } from '@hookform/resolvers/zod';
import { z } from 'zod';
import { Mail, Lock } from 'lucide-react';
import FormInput from './FormInput';
import SubmitButton from './SubmitButton';

const loginSchema = z.object({
  email: z.string().email('Invalid email address'),
  password: z.string().min(1, 'Password is required')
});

type LoginFormData = z.infer<typeof loginSchema>;

interface LoginFormProps {
  onSubmit: (data: LoginFormData) => Promise<void>;
}

const LoginForm: React.FC<LoginFormProps> = ({ onSubmit }) => {
  const [showPassword, setShowPassword] = useState(false);
  
  const {
    register,
    handleSubmit,
    formState: { errors, isSubmitting }
  } = useForm<LoginFormData>({
    resolver: zodResolver(loginSchema)
  });

  return (
    <form className="space-y-6" onSubmit={handleSubmit(onSubmit)} noValidate>
      <FormInput
        id="login-email"
        type="email"
        label="Email address"
        error={errors.email?.message}
        Icon={Mail}
        register={register('email')}
        autoComplete="email"
      />

      <FormInput
        id="login-password"
        type="password"
        label="Password"
        error={errors.password?.message}
        Icon={Lock}
        showPassword={showPassword}
        togglePassword={() => setShowPassword(!showPassword)}
        register={register('password')}
        autoComplete="current-password"
      />

      <SubmitButton isSubmitting={isSubmitting}>
        Sign in
      </SubmitButton>
    </form>
  );
};

export default LoginForm;