import { useState, useCallback, useEffect } from 'react';
import { supabase } from '../lib/supabase';
import { useProperty } from '../contexts/PropertyContext';
import type { Application } from '../types/applications';

export const useApplications = () => {
  const [applications, setApplications] = useState<Application[]>([]);
  const [archivedApplications, setArchivedApplications] = useState<Application[]>([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<string | null>(null);
  const { selectedPropertyId } = useProperty();

  const fetchApplications = useCallback(async () => {
    if (!selectedPropertyId) return;

    try {
      setLoading(true);
      setError(null);

      // Fetch active applications
      const { data: activeData, error: activeError } = await supabase
        .from('applications')
        .select('*')
        .eq('property_id', selectedPropertyId)
        .is('archived_at', null)
        .order('created_at', { ascending: false });

      if (activeError) throw activeError;

      // Fetch archived applications
      const { data: archivedData, error: archivedError } = await supabase
        .from('applications')
        .select('*')
        .eq('property_id', selectedPropertyId)
        .not('archived_at', 'is', null)
        .order('archived_at', { ascending: false });

      if (archivedError) throw archivedError;

      setApplications(activeData || []);
      setArchivedApplications(archivedData || []);
    } catch (err) {
      console.error('Error fetching applications:', err);
      setError('Failed to load applications');
    } finally {
      setLoading(false);
    }
  }, [selectedPropertyId]);

  const addApplication = async (data: Omit<Application, 'id' | 'property_id' | 'created_at' | 'updated_at'>) => {
    if (!selectedPropertyId) throw new Error('No property selected');

    try {
      const { data: newApplication, error } = await supabase
        .from('applications')
        .insert([{
          ...data,
          property_id: selectedPropertyId,
          created_at: new Date().toISOString(),
          updated_at: new Date().toISOString()
        }])
        .select()
        .single();

      if (error) throw error;

      setApplications(prev => [newApplication, ...prev]);
      return newApplication;
    } catch (err) {
      console.error('Error adding application:', err);
      throw err;
    }
  };

  const updateApplication = async (id: string, updates: Partial<Application>) => {
    if (!selectedPropertyId) throw new Error('No property selected');

    try {
      const { data, error } = await supabase
        .from('applications')
        .update({
          ...updates,
          updated_at: new Date().toISOString()
        })
        .eq('id', id)
        .eq('property_id', selectedPropertyId)
        .select()
        .single();

      if (error) throw error;

      if (data.archived_at) {
        setArchivedApplications(prev =>
          prev.map(app => app.id === id ? data : app)
        );
      } else {
        setApplications(prev =>
          prev.map(app => app.id === id ? data : app)
        );
      }

      return data;
    } catch (err) {
      console.error('Error updating application:', err);
      throw err;
    }
  };

  const archiveApplication = async (id: string, reason: string) => {
    if (!selectedPropertyId) throw new Error('No property selected');

    try {
      const now = new Date().toISOString();
      const { data, error } = await supabase
        .from('applications')
        .update({
          archived_at: now,
          archive_reason: reason,
          updated_at: now
        })
        .eq('id', id)
        .eq('property_id', selectedPropertyId)
        .select()
        .single();

      if (error) throw error;

      setApplications(prev => prev.filter(app => app.id !== id));
      setArchivedApplications(prev => [data, ...prev]);

      return data;
    } catch (err) {
      console.error('Error archiving application:', err);
      throw err;
    }
  };

  const restoreApplication = async (id: string) => {
    if (!selectedPropertyId) throw new Error('No property selected');

    try {
      const { data, error } = await supabase
        .from('applications')
        .update({
          archived_at: null,
          archive_reason: null,
          updated_at: new Date().toISOString()
        })
        .eq('id', id)
        .eq('property_id', selectedPropertyId)
        .select()
        .single();

      if (error) throw error;

      setArchivedApplications(prev => prev.filter(app => app.id !== id));
      setApplications(prev => [data, ...prev]);

      return data;
    } catch (err) {
      console.error('Error restoring application:', err);
      throw err;
    }
  };

  const deleteApplication = async (id: string) => {
    if (!selectedPropertyId) throw new Error('No property selected');

    try {
      const { error } = await supabase
        .from('applications')
        .delete()
        .eq('id', id)
        .eq('property_id', selectedPropertyId);

      if (error) throw error;

      setArchivedApplications(prev => prev.filter(app => app.id !== id));
    } catch (err) {
      console.error('Error deleting application:', err);
      throw err;
    }
  };

  useEffect(() => {
    fetchApplications();

    if (selectedPropertyId) {
      const channel = supabase
        .channel('applications_changes')
        .on('postgres_changes', 
          { 
            event: '*', 
            schema: 'public',
            table: 'applications',
            filter: `property_id=eq.${selectedPropertyId}`
          }, 
          () => {
            fetchApplications();
          }
        )
        .subscribe();

      return () => {
        supabase.removeChannel(channel);
      };
    }
  }, [selectedPropertyId, fetchApplications]);

  return {
    applications,
    archivedApplications,
    loading,
    error,
    addApplication,
    updateApplication,
    archiveApplication,
    restoreApplication,
    deleteApplication,
    refreshApplications: fetchApplications
  };
};

export default useApplications;