export const getStatusColor = (status: string | undefined): string => {
  if (!status) return 'bg-gray-100 text-gray-800 border-gray-300';
  
  switch (status.toLowerCase()) {
    case 'completed':
    case 'done':
      return 'bg-green-100 text-green-800 border-green-300';
    case 'in-progress':
    case 'scheduled':
      return 'bg-yellow-100 text-yellow-800 border-yellow-300';
    case 'not started':
    case 'not-started':
      return 'bg-red-100 text-red-800 border-red-300';
    case 'overdue':
      return 'bg-red-100 text-red-800 border-red-300';
    default:
      return 'bg-gray-100 text-gray-800 border-gray-300';
  }
};

export const getPriorityColor = (priority: string | undefined): string => {
  if (!priority) return 'bg-gray-100 text-gray-800 border-gray-300';
  
  switch (priority.toLowerCase()) {
    case 'high':
      return 'bg-red-100 text-red-800 border-red-300';
    case 'medium':
      return 'bg-yellow-100 text-yellow-800 border-yellow-300';
    case 'low':
      return 'bg-green-100 text-green-800 border-green-300';
    default:
      return 'bg-gray-100 text-gray-800 border-gray-300';
  }
};

export const getTaskTypeColor = (type: string | undefined): string => {
  if (!type) return 'bg-gray-100 text-gray-800 border-gray-300';
  
  switch (type.toLowerCase()) {
    case 'daily':
      return 'bg-blue-100 text-blue-800 border-blue-300';
    case 'todo':
      return 'bg-purple-100 text-purple-800 border-purple-300';
    default:
      return 'bg-gray-100 text-gray-800 border-gray-300';
  }
};