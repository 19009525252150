import React, { useState } from 'react';
import { useInView } from 'react-intersection-observer';
import { Menu, X, Rocket } from 'lucide-react';
import { Link } from 'react-router-dom';
import Hero from '../components/landing/Hero';
import Features from '../components/landing/Features';
import Services from '../components/landing/Services';
import PricingSection from '../components/pricing/PricingSection';
import ContactForm from '../components/contact/ContactForm';
import Footer from '../components/landing/Footer';

const Landing: React.FC = () => {
  const [isMobileMenuOpen, setIsMobileMenuOpen] = useState(false);
  const [heroRef, heroInView] = useInView({ triggerOnce: true });
  const [aboutRef, aboutInView] = useInView({ triggerOnce: true, threshold: 0.2 });
  const [servicesRef, servicesInView] = useInView({ triggerOnce: true, threshold: 0.2 });

  return (
    <div className="min-h-screen bg-gradient-to-b from-darkPurple via-federalBlue to-marianBlue text-white">
      <nav className="fixed top-0 left-0 right-0 bg-darkPurple/90 backdrop-blur-sm z-50">
        <div className="container mx-auto px-4 py-4">
          <div className="flex justify-between items-center">
            <div className="flex items-center space-x-2">
              <Rocket className="w-6 h-6 text-lightBlue" />
              <span className="text-xl font-bold">MyOrbit</span>
            </div>

            {/* Desktop Navigation */}
            <div className="hidden md:flex items-center space-x-8">
              <a href="#home" className="hover:text-lightBlue transition-colors">Home</a>
              <a href="#about" className="hover:text-lightBlue transition-colors">About</a>
              <a href="#services" className="hover:text-lightBlue transition-colors">Services</a>
              <a href="#pricing" className="hover:text-lightBlue transition-colors">Pricing</a>
              <a href="#contact" className="hover:text-lightBlue transition-colors">Contact</a>
              <Link 
                to="/auth" 
                className="px-4 py-2 bg-lightBlue text-darkPurple rounded-lg hover:bg-mintGreen transition-colors"
              >
                Sign In
              </Link>
            </div>

            {/* Mobile Menu Button */}
            <div className="flex items-center space-x-4 md:hidden">
              <Link
                to="/auth"
                className="px-4 py-2 bg-lightBlue text-darkPurple rounded-lg hover:bg-mintGreen transition-colors text-sm font-medium"
              >
                Sign In
              </Link>
              <button
                onClick={() => setIsMobileMenuOpen(!isMobileMenuOpen)}
                className="p-2 text-lightBlue hover:bg-marianBlue/20 rounded-lg transition-colors"
                aria-label="Toggle menu"
              >
                {isMobileMenuOpen ? <X size={24} /> : <Menu size={24} />}
              </button>
            </div>
          </div>

          {/* Mobile Menu */}
          <div className={`
            md:hidden 
            transition-all duration-300 ease-in-out
            ${isMobileMenuOpen ? 'max-h-64 opacity-100 mt-4' : 'max-h-0 opacity-0 overflow-hidden'}
          `}>
            <div className="flex flex-col space-y-4 py-4">
              <a href="#home" className="hover:text-lightBlue transition-colors">Home</a>
              <a href="#about" className="hover:text-lightBlue transition-colors">About</a>
              <a href="#services" className="hover:text-lightBlue transition-colors">Services</a>
              <a href="#pricing" className="hover:text-lightBlue transition-colors">Pricing</a>
              <a href="#contact" className="hover:text-lightBlue transition-colors">Contact</a>
            </div>
          </div>
        </div>
      </nav>

      {/* Hero Section */}
      <section id="home" ref={heroRef} className="min-h-screen pt-20 relative overflow-hidden">
        <Hero inView={heroInView} />
      </section>

      {/* About Section */}
      <section id="about" ref={aboutRef} className="py-20 bg-gradient-to-b from-federalBlue to-marianBlue">
        <div className="container mx-auto px-4">
          <div className="max-w-4xl mx-auto text-center">
            <h2 className="text-4xl font-bold mb-8">About Us</h2>
            <p className="text-lg mb-12 text-gray-300">
              We understand the challenges of managing properties in today's fast-paced business environment.
              Our platform streamlines your operations, giving you more time to focus on growth and innovation.
            </p>
            <Features inView={aboutInView} />
          </div>
        </div>
      </section>

      {/* Services Section */}
      <section id="services" ref={servicesRef} className="py-20 bg-gradient-to-b from-marianBlue to-federalBlue">
        <div className="container mx-auto px-4">
          <div className="max-w-4xl mx-auto">
            <h2 className="text-4xl font-bold mb-12 text-center">Our Services</h2>
            <Services inView={servicesInView} />
          </div>
        </div>
      </section>

      {/* Pricing Section */}
      <PricingSection />

      {/* Contact Section */}
      <section id="contact" className="py-20 bg-darkPurple">
        <div className="container mx-auto px-4">
          <div className="max-w-4xl mx-auto">
            <h2 className="text-4xl font-bold mb-12 text-center">Get Started Today</h2>
            <div className="bg-federalBlue/50 p-8 rounded-lg">
              <ContactForm />
            </div>
          </div>
        </div>
      </section>

      {/* Footer */}
      <Footer />
    </div>
  );
};

export default Landing;