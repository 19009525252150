import { format, eachDayOfInterval, startOfWeek, endOfWeek } from 'date-fns';

interface WeeklyMetrics {
  [date: string]: {
    [metric: string]: number;
  };
}

export const generateWeeklySummary = (metrics: WeeklyMetrics, startDate: Date) => {
  const days = eachDayOfInterval({
    start: startOfWeek(startDate, { weekStartsOn: 1 }),
    end: endOfWeek(startDate, { weekStartsOn: 1 })
  });

  const metricTypes = ['calls', 'emails', 'tours', 'applications'];
  
  // Calculate daily totals
  const dailyTotals = days.map(day => {
    const dateStr = format(day, 'yyyy-MM-dd');
    const dayMetrics = metrics[dateStr] || {};
    
    return {
      date: format(day, 'MM/dd/yyyy'),
      ...metricTypes.reduce((acc, metric) => ({
        ...acc,
        [metric]: dayMetrics[metric] || 0
      }), {})
    };
  });

  // Calculate weekly totals
  const weeklyTotals = metricTypes.reduce((acc, metric) => ({
    ...acc,
    [metric]: dailyTotals.reduce((sum, day) => sum + (day[metric] || 0), 0)
  }), {});

  // Generate CSV content
  const headers = ['Metric', 'Total', ...days.map(day => format(day, 'MM/dd/yyyy'))];
  
  const rows = metricTypes.map(metric => [
    metric.charAt(0).toUpperCase() + metric.slice(1),
    weeklyTotals[metric],
    ...dailyTotals.map(day => day[metric])
  ]);

  const csvContent = [
    headers.join(','),
    ...rows.map(row => row.join(','))
  ].join('\n');

  return csvContent;
};

export const downloadWeeklySummary = (metrics: WeeklyMetrics, startDate: Date) => {
  const csvContent = generateWeeklySummary(metrics, startDate);
  const blob = new Blob([csvContent], { type: 'text/csv;charset=utf-8;' });
  const link = document.createElement('a');
  const url = URL.createObjectURL(blob);
  
  link.setAttribute('href', url);
  link.setAttribute('download', `weekly-performance-${format(startDate, 'yyyy-MM-dd')}.csv`);
  link.style.visibility = 'hidden';
  
  document.body.appendChild(link);
  link.click();
  document.body.removeChild(link);
};