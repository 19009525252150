import React, { useState } from 'react';
import { X } from 'lucide-react';
import type { Lead } from '../../types/leads';

interface ArchiveLeadModalProps {
  isOpen: boolean;
  onClose: () => void;
  onArchive: (id: string, reason: string) => Promise<void>;
  lead: Lead;
}

const ArchiveLeadModal: React.FC<ArchiveLeadModalProps> = ({
  isOpen,
  onClose,
  onArchive,
  lead
}) => {
  const [reason, setReason] = useState('');
  const [error, setError] = useState('');
  const [isSubmitting, setIsSubmitting] = useState(false);

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    
    if (!reason.trim()) {
      setError('Please provide a reason for archiving');
      return;
    }

    try {
      setIsSubmitting(true);
      await onArchive(lead.id, reason);
      onClose();
    } catch (err) {
      setError('Failed to archive lead');
    } finally {
      setIsSubmitting(false);
    }
  };

  if (!isOpen) return null;

  return (
    <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50">
      <div className="bg-white rounded-lg p-6 w-full max-w-md">
        <div className="flex justify-between items-center mb-4">
          <h2 className="text-xl font-bold">Archive Lead</h2>
          <button onClick={onClose} className="text-gray-500 hover:text-gray-700">
            <X size={24} />
          </button>
        </div>

        <p className="mb-4 text-gray-600">
          You are about to archive the lead for <span className="font-medium">{lead.full_name}</span>.
          Please provide a reason for archiving.
        </p>

        <form onSubmit={handleSubmit} className="space-y-4">
          <div>
            <label className="block text-sm font-medium text-gray-700 mb-1">
              Archive Reason *
            </label>
            <textarea
              value={reason}
              onChange={(e) => {
                setReason(e.target.value);
                setError('');
              }}
              className="w-full p-2 border rounded-md focus:ring-2 focus:ring-blue-500 focus:border-transparent"
              rows={3}
              placeholder="Enter the reason for archiving..."
            />
            {error && (
              <p className="mt-1 text-sm text-red-600">{error}</p>
            )}
          </div>

          <div className="flex justify-end space-x-2">
            <button
              type="button"
              onClick={onClose}
              className="px-4 py-2 text-sm font-medium text-gray-700 hover:text-gray-800"
              disabled={isSubmitting}
            >
              Cancel
            </button>
            <button
              type="submit"
              disabled={isSubmitting}
              className="px-4 py-2 text-sm font-medium text-white bg-blue-600 rounded-md hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500 disabled:opacity-50"
            >
              {isSubmitting ? 'Archiving...' : 'Archive Lead'}
            </button>
          </div>
        </form>
      </div>
    </div>
  );
};

export default ArchiveLeadModal;