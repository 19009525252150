import React, { useState, useMemo } from 'react';
import { DragDropContext, Droppable, Draggable } from '@hello-pangea/dnd';
import { Plus, Loader2, Archive, Search, Filter } from 'lucide-react';
import useApplications from '../hooks/useApplications';
import ApplicationCard from '../components/applications/ApplicationCard';
import ArchivedApplications from '../components/applications/ArchivedApplications';
import NewApplicationModal from '../components/applications/NewApplicationModal';
import ArchiveModal from '../components/applications/ArchiveModal';
import type { Application, ApplicationStatus } from '../types/applications';

const Applications: React.FC = () => {
  const {
    applications,
    archivedApplications,
    loading,
    error,
    addApplication,
    updateApplication,
    archiveApplication,
    restoreApplication,
    deleteApplication
  } = useApplications();

  const [isModalOpen, setIsModalOpen] = useState(false);
  const [showArchived, setShowArchived] = useState(false);
  const [searchQuery, setSearchQuery] = useState('');
  const [statusFilter, setStatusFilter] = useState<ApplicationStatus | 'all'>('all');
  const [archiveModalData, setArchiveModalData] = useState<{
    isOpen: boolean;
    application: Application | null;
  }>({
    isOpen: false,
    application: null
  });

  const columns = useMemo(() => [
    { id: 'pending_screening', title: 'Pending Screening', borderColor: 'border-yellow-400' },
    { id: 'pre_approved', title: 'Pre-approved', borderColor: 'border-blue-400' },
    { id: 'approved', title: 'Approved', borderColor: 'border-green-400' }
  ] as const, []);

  const filteredApplications = useMemo(() => {
    return applications.filter(app => {
      const matchesSearch = 
        app.name.toLowerCase().includes(searchQuery.toLowerCase()) ||
        app.email.toLowerCase().includes(searchQuery.toLowerCase()) ||
        app.phone.includes(searchQuery);
      const matchesStatus = statusFilter === 'all' || app.status === statusFilter;
      return matchesSearch && matchesStatus;
    });
  }, [applications, searchQuery, statusFilter]);

  const getApplicationsByStatus = (status: ApplicationStatus) => {
    return filteredApplications.filter(app => app.status === status);
  };

  const handleDragEnd = async (result: any) => {
    if (!result.destination) return;

    const sourceStatus = result.source.droppableId as ApplicationStatus;
    const destinationStatus = result.destination.droppableId as ApplicationStatus;
    
    if (sourceStatus === destinationStatus) return;

    try {
      await updateApplication(result.draggableId, { status: destinationStatus });
    } catch (error) {
      console.error('Error updating application status:', error);
    }
  };

  const handleArchive = async (reason: string) => {
    if (archiveModalData.application) {
      try {
        await archiveApplication(archiveModalData.application.id, reason);
        setArchiveModalData({ isOpen: false, application: null });
      } catch (error) {
        console.error('Error archiving application:', error);
      }
    }
  };

  if (error) {
    return (
      <div className="bg-red-50 border border-red-200 rounded-lg p-4">
        <p className="text-red-800">{error}</p>
      </div>
    );
  }

  return (
    <div className="space-y-6">
      <div className="flex flex-col sm:flex-row justify-between items-start sm:items-center gap-4">
        <div className="flex items-center gap-4">
          <h1 className="text-2xl font-bold">Applications</h1>
          <button
            onClick={() => setShowArchived(!showArchived)}
            className={`flex items-center px-3 py-1 text-sm rounded-md transition-colors ${
              showArchived
                ? 'bg-gray-100 text-gray-700'
                : 'text-gray-600 hover:bg-gray-50'
            }`}
            aria-pressed={showArchived}
          >
            <Archive className="w-4 h-4 mr-1" />
            {showArchived ? 'Show Active' : 'Show Archived'}
          </button>
        </div>
        <button
          onClick={() => setIsModalOpen(true)}
          className="flex items-center px-4 py-2 bg-blue-600 text-white rounded-lg hover:bg-blue-700 transition-colors focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500"
        >
          <Plus className="w-5 h-5 mr-2" />
          New Application
        </button>
      </div>

      {!showArchived && (
        <div className="flex flex-col sm:flex-row gap-4">
          <div className="flex-1 relative">
            <Search className="absolute left-3 top-1/2 transform -translate-y-1/2 text-gray-400" />
            <input
              type="text"
              placeholder="Search applications..."
              value={searchQuery}
              onChange={(e) => setSearchQuery(e.target.value)}
              className="w-full pl-10 pr-4 py-2 border rounded-lg focus:ring-2 focus:ring-blue-500 focus:border-transparent"
            />
          </div>
          <select
            value={statusFilter}
            onChange={(e) => setStatusFilter(e.target.value as ApplicationStatus | 'all')}
            className="px-3 py-2 border rounded-lg focus:ring-2 focus:ring-blue-500 focus:border-transparent"
          >
            <option value="all">All Status</option>
            <option value="pending_screening">Pending Screening</option>
            <option value="pre_approved">Pre-approved</option>
            <option value="approved">Approved</option>
          </select>
        </div>
      )}

      {loading ? (
        <div className="flex justify-center items-center h-64">
          <Loader2 className="w-8 h-8 animate-spin text-blue-500" />
        </div>
      ) : showArchived ? (
        <ArchivedApplications
          applications={archivedApplications}
          onRestore={restoreApplication}
          onUpdate={updateApplication}
          onDelete={deleteApplication}
        />
      ) : (
        <DragDropContext onDragEnd={handleDragEnd}>
          <div className="grid grid-cols-1 md:grid-cols-3 gap-6">
            {columns.map(column => (
              <div
                key={column.id}
                className={`bg-white rounded-lg shadow-sm border-t-4 ${column.borderColor}`}
              >
                <div className="p-4 border-b">
                  <h2 className="text-lg font-semibold">{column.title}</h2>
                  <div className="text-sm text-gray-500 mt-1">
                    {getApplicationsByStatus(column.id).length} applications
                  </div>
                </div>
                
                <Droppable droppableId={column.id}>
                  {(provided, snapshot) => (
                    <div
                      ref={provided.innerRef}
                      {...provided.droppableProps}
                      className={`p-4 min-h-[500px] transition-colors ${
                        snapshot.isDraggingOver ? 'bg-gray-50' : 'bg-white'
                      }`}
                    >
                      <div className="space-y-4">
                        {getApplicationsByStatus(column.id).map((application, index) => (
                          <Draggable
                            key={application.id}
                            draggableId={application.id}
                            index={index}
                          >
                            {(provided, snapshot) => (
                              <div
                                ref={provided.innerRef}
                                {...provided.draggableProps}
                                {...provided.dragHandleProps}
                                className={`transform transition-all duration-200 ${
                                  snapshot.isDragging ? 'rotate-2 scale-105 shadow-lg' : ''
                                }`}
                              >
                                <ApplicationCard
                                  application={application}
                                  onUpdate={updateApplication}
                                  onArchive={() => setArchiveModalData({
                                    isOpen: true,
                                    application
                                  })}
                                />
                              </div>
                            )}
                          </Draggable>
                        ))}
                        {provided.placeholder}
                      </div>
                    </div>
                  )}
                </Droppable>
              </div>
            ))}
          </div>
        </DragDropContext>
      )}

      <NewApplicationModal
        isOpen={isModalOpen}
        onClose={() => setIsModalOpen(false)}
        onSubmit={addApplication}
      />

      {archiveModalData.application && (
        <ArchiveModal
          isOpen={archiveModalData.isOpen}
          onClose={() => setArchiveModalData({ isOpen: false, application: null })}
          onArchive={handleArchive}
          application={archiveModalData.application}
        />
      )}
    </div>
  );
};

export default Applications;