import React, { useState } from 'react';
import { Target, MapPin, Building2, Plus, Save, Loader2, Users } from 'lucide-react';
import { supabase } from '../lib/supabase';
import usePropertySettings from '../hooks/usePropertySettings';
import useProperties from '../hooks/useProperties';
import useUserProfile from '../hooks/useUserProfile';
import { useAuth } from '../contexts/AuthContext';
import { useProperty } from '../contexts/PropertyContext';
import AddPropertyModal from '../components/AddPropertyModal';
import PropertyList from '../components/properties/PropertyList';
import CollaborationSettings from '../components/settings/CollaborationSettings';

const WorkspaceSettings: React.FC = () => {
  const { selectedPropertyId } = useProperty();
  const { settings, updateSettings } = usePropertySettings();
  const { properties, isLoading: propertiesLoading } = useProperties();
  const { user } = useAuth();
  const { profile, updateProfile, isLoading: profileLoading } = useUserProfile();
  const [weatherLocation, setWeatherLocation] = useState(settings.weather_location || '');
  const [leaseGoal, setLeaseGoal] = useState(settings.lease_goal || 10);
  const [isAddModalOpen, setIsAddModalOpen] = useState(false);
  const [error, setError] = useState('');
  const [success, setSuccess] = useState('');

  const handleProfileSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    const formData = new FormData(e.currentTarget);
    
    try {
      setError('');
      await updateProfile({
        full_name: formData.get('fullName') as string,
        phone: formData.get('phone') as string
      });
      setSuccess('Profile updated successfully');
    } catch (err) {
      setError('Failed to update profile');
      console.error('Error updating profile:', err);
    }
  };

  const handleSaveSettings = async () => {
    try {
      await updateSettings({
        weather_location: weatherLocation,
        lease_goal: leaseGoal
      });
      setSuccess('Settings updated successfully');
      setTimeout(() => setSuccess(''), 3000);
    } catch (err) {
      setError('Failed to update settings');
      setTimeout(() => setError(''), 3000);
    }
  };

  if (!selectedPropertyId) {
    return (
      <div className="text-center py-8 text-gray-500">
        Please select a property to manage settings
      </div>
    );
  }

  return (
    <div className="max-w-4xl mx-auto space-y-8 pb-8">
      <h1 className="text-2xl font-bold">Settings</h1>

      {error && (
        <div className="p-4 bg-red-50 border border-red-200 rounded-lg text-red-700">
          {error}
        </div>
      )}

      {success && (
        <div className="p-4 bg-green-50 border border-green-200 rounded-lg text-green-700">
          {success}
        </div>
      )}

      {/* Profile Settings */}
      <div className="bg-white rounded-lg shadow-sm border border-gray-200 p-6">
        <h2 className="text-xl font-semibold mb-6">Profile Settings</h2>
        
        <div className="mb-6">
          <h3 className="text-lg font-semibold">{profile?.full_name || 'Set your name'}</h3>
          <p className="text-gray-500">{user?.email}</p>
        </div>

        <form onSubmit={handleProfileSubmit} className="space-y-4">
          <div>
            <label className="block text-sm font-medium text-gray-700 mb-1">
              Full Name
            </label>
            <input
              type="text"
              name="fullName"
              defaultValue={profile?.full_name || ''}
              className="w-full px-3 py-2 border border-gray-300 rounded-md focus:ring-2 focus:ring-blue-500 focus:border-transparent"
            />
          </div>

          <div>
            <label className="block text-sm font-medium text-gray-700 mb-1">
              Phone
            </label>
            <input
              type="tel"
              name="phone"
              defaultValue={profile?.phone || ''}
              className="w-full px-3 py-2 border border-gray-300 rounded-md focus:ring-2 focus:ring-blue-500 focus:border-transparent"
            />
          </div>

          <button
            type="submit"
            className="px-4 py-2 bg-blue-600 text-white rounded-md hover:bg-blue-700 transition-colors"
          >
            <Save className="w-4 h-4 inline-block mr-2" />
            Save Profile
          </button>
        </form>
      </div>

      {/* Property Management */}
      <div className="bg-white shadow-md rounded-lg p-6">
        <div className="flex justify-between items-center mb-6">
          <div className="flex items-center">
            <Building2 className="w-5 h-5 text-blue-600 mr-2" />
            <h2 className="text-xl font-semibold">Manage Properties</h2>
          </div>
          <button
            onClick={() => setIsAddModalOpen(true)}
            className="flex items-center px-4 py-2 bg-blue-600 text-white rounded-lg hover:bg-blue-700 transition-colors"
          >
            <Plus className="w-5 h-5 mr-2" />
            Add Property
          </button>
        </div>

        {propertiesLoading ? (
          <div className="flex justify-center py-8">
            <Loader2 className="w-8 h-8 animate-spin text-blue-500" />
          </div>
        ) : (
          <PropertyList />
        )}
      </div>

      {/* Collaboration Settings */}
      <CollaborationSettings />

      {/* Property Settings */}
      <div className="bg-white shadow-md rounded-lg p-6">
        <h2 className="text-xl font-semibold mb-6">Property Settings</h2>
        
        {/* Lease Goals */}
        <div className="mb-6">
          <label className="block text-sm font-medium text-gray-700 mb-2">
            <Target className="w-4 h-4 inline-block mr-2" />
            Monthly Lease Goal
          </label>
          <input
            type="number"
            min="1"
            value={leaseGoal}
            onChange={(e) => setLeaseGoal(Math.max(1, parseInt(e.target.value) || 1))}
            className="block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:ring-blue-500 focus:border-blue-500 sm:text-sm"
          />
        </div>

        {/* Weather Location */}
        <div className="mb-6">
          <label className="block text-sm font-medium text-gray-700 mb-2">
            <MapPin className="w-4 h-4 inline-block mr-2" />
            Weather Location
          </label>
          <input
            type="text"
            value={weatherLocation}
            onChange={(e) => setWeatherLocation(e.target.value)}
            placeholder="Enter city name or zip code"
            className="block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:ring-blue-500 focus:border-blue-500 sm:text-sm"
          />
        </div>

        <button
          onClick={handleSaveSettings}
          className="px-4 py-2 bg-blue-600 text-white rounded-md hover:bg-blue-700 transition-colors"
        >
          Save Settings
        </button>
      </div>

      <AddPropertyModal
        isOpen={isAddModalOpen}
        onClose={() => setIsAddModalOpen(false)}
      />
    </div>
  );
};

export default WorkspaceSettings;