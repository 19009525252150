import { useMemo } from 'react';
import type { Unit } from '../types/units';

export const useFilteredUnits = (
  units: Unit[],
  filter: string,
  sortBy: 'aptNumber' | 'moveInDate'
) => {
  return useMemo(() => {
    return units
      .filter(unit =>
        Object.values(unit).some(value =>
          value?.toString().toLowerCase().includes(filter.toLowerCase())
        )
      )
      .sort((a, b) => {
        if (sortBy === 'aptNumber') {
          return a.aptNumber.localeCompare(b.aptNumber, undefined, { numeric: true });
        }
        return new Date(a.moveInDate).getTime() - new Date(b.moveInDate).getTime();
      });
  }, [units, filter, sortBy]);
};

export default useFilteredUnits;