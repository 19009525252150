import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { KeyRound, Loader2 } from 'lucide-react';
import { usePropertyCollaboration } from '../../hooks/usePropertyCollaboration';
import { isValidPropertyCode } from '../../utils/propertyCode';

const JoinPropertyForm: React.FC = () => {
  const [code, setCode] = useState('');
  const [error, setError] = useState<string | null>(null);
  const navigate = useNavigate();
  const { joinProperty, isLoading } = usePropertyCollaboration('');

  const formatCode = (input: string) => {
    // Remove any non-alphanumeric characters
    const cleaned = input.replace(/[^A-Z0-9]/g, '');
    
    // Add hyphen after first 4 characters if length > 4
    if (cleaned.length > 4) {
      return `${cleaned.slice(0, 4)}-${cleaned.slice(4)}`;
    }
    
    return cleaned;
  };

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const input = e.target.value.toUpperCase();
    const formatted = formatCode(input);
    setCode(formatted);
    setError(null);
  };

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    setError(null);

    if (!isValidPropertyCode(code)) {
      setError('Please enter a valid property code (e.g., ABCD-1234)');
      return;
    }

    const result = await joinProperty(code);
    if (result.success && result.propertyId) {
      navigate(`/properties/${result.propertyId}`);
    } else {
      setError(result.error || 'Failed to join property');
    }
  };

  return (
    <form onSubmit={handleSubmit} className="space-y-4">
      <div>
        <label htmlFor="propertyCode" className="block text-sm font-medium text-gray-700">
          Property Code
        </label>
        <div className="mt-1 relative rounded-md shadow-sm">
          <div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
            <KeyRound className="h-5 w-5 text-gray-400" />
          </div>
          <input
            id="propertyCode"
            type="text"
            value={code}
            onChange={handleChange}
            placeholder="XXXX-XXXX"
            className="block w-full pl-10 pr-3 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-blue-500 text-lg font-mono tracking-wider"
            autoComplete="off"
            autoCapitalize="characters"
            spellCheck="false"
            disabled={isLoading}
          />
        </div>
        {error && (
          <p className="mt-2 text-sm text-red-600">
            {error}
          </p>
        )}
        <p className="mt-2 text-sm text-gray-500">
          Enter the property code shared with you to join the property
        </p>
      </div>

      <button
        type="submit"
        disabled={isLoading || code.length < 9} // 8 chars + 1 hyphen
        className="w-full flex justify-center py-2 px-4 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-blue-600 hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500 disabled:opacity-50 disabled:cursor-not-allowed"
      >
        {isLoading ? (
          <>
            <Loader2 className="animate-spin -ml-1 mr-2 h-5 w-5" />
            Joining...
          </>
        ) : (
          'Join Property'
        )}
      </button>
    </form>
  );
};

export default JoinPropertyForm;