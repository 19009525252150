export const MAX_RETRIES = 3;
const INITIAL_DELAY = 1000; // 1 second

export const retryOperation = async <T>(
  operation: () => Promise<T>,
  maxRetries: number = MAX_RETRIES,
  onRetry?: (attempt: number, delay: number) => void
): Promise<T> => {
  let lastError: Error;
  
  for (let attempt = 1; attempt <= maxRetries; attempt++) {
    try {
      return await operation();
    } catch (error) {
      lastError = error instanceof Error ? error : new Error(String(error));
      
      if (attempt === maxRetries) {
        throw lastError;
      }

      const delay = INITIAL_DELAY * Math.pow(2, attempt - 1);
      
      if (onRetry) {
        onRetry(attempt, delay);
      }

      await new Promise(resolve => setTimeout(resolve, delay));
    }
  }

  throw lastError!;
};