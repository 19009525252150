import React from 'react';
import { format, eachDayOfInterval, startOfWeek, endOfWeek, isToday } from 'date-fns';
import { ChevronUp, ChevronDown, Loader2 } from 'lucide-react';

interface WeeklyScorecardProps {
  startDate: Date;
  metrics: Record<string, Record<string, number>>;
  onMetricChange: (date: string, field: string, value: number) => void;
  isLoading: boolean;
}

const WeeklyScorecard: React.FC<WeeklyScorecardProps> = ({
  startDate,
  metrics = {},
  onMetricChange,
  isLoading
}) => {
  const days = eachDayOfInterval({
    start: startOfWeek(startDate, { weekStartsOn: 1 }),
    end: endOfWeek(startDate, { weekStartsOn: 1 })
  });

  const metricTypes = [
    { key: 'calls', label: 'Calls' },
    { key: 'emails', label: 'Emails' },
    { key: 'tours', label: 'Tours' },
    { key: 'applications', label: 'Applications' }
  ];

  const getMetricValue = (dateStr: string, field: string): number => {
    return metrics[dateStr]?.[field] || 0;
  };

  const handleIncrement = (date: Date, field: string) => {
    const dateStr = format(date, 'yyyy-MM-dd');
    const currentValue = getMetricValue(dateStr, field);
    onMetricChange(dateStr, field, currentValue + 1);
  };

  const handleDecrement = (date: Date, field: string) => {
    const dateStr = format(date, 'yyyy-MM-dd');
    const currentValue = getMetricValue(dateStr, field);
    if (currentValue > 0) {
      onMetricChange(dateStr, field, currentValue - 1);
    }
  };

  if (isLoading) {
    return (
      <div className="bg-white rounded-lg shadow-sm p-8 flex justify-center items-center min-h-[300px]">
        <Loader2 className="w-8 h-8 animate-spin text-blue-500" />
      </div>
    );
  }

  return (
    <div className="bg-white rounded-lg shadow-sm overflow-x-auto">
      <table className="min-w-full divide-y divide-gray-200">
        <thead>
          <tr>
            <th className="px-6 py-3 bg-gray-50 text-left text-sm font-semibold text-gray-900 uppercase tracking-wider sticky left-0 bg-gray-50 z-10">
              Metric
            </th>
            {days.map(day => (
              <th
                key={day.toISOString()}
                className={`px-6 py-3 text-center text-sm font-semibold text-gray-900 uppercase tracking-wider whitespace-nowrap ${
                  isToday(day) ? 'bg-blue-50' : 'bg-gray-50'
                }`}
              >
                <div>{format(day, 'EEE')}</div>
                <div>{format(day, 'MMM d')}</div>
              </th>
            ))}
          </tr>
        </thead>
        <tbody className="bg-white divide-y divide-gray-200">
          {metricTypes.map(({ key, label }, rowIndex) => (
            <tr key={key} className={rowIndex % 2 === 0 ? 'bg-gray-50' : ''}>
              <td className="px-6 py-4 whitespace-nowrap text-sm font-semibold text-gray-900 sticky left-0 bg-inherit">
                {label}
              </td>
              {days.map(day => {
                const dateStr = format(day, 'yyyy-MM-dd');
                const value = getMetricValue(dateStr, key);
                
                return (
                  <td
                    key={day.toISOString()}
                    className={`px-6 py-4 whitespace-nowrap text-sm text-gray-500 text-center ${
                      isToday(day) ? 'bg-blue-50' : ''
                    }`}
                  >
                    <div className="flex items-center justify-center space-x-2">
                      <div className="flex flex-col">
                        <button
                          onClick={() => handleIncrement(day, key)}
                          className="p-0.5 hover:bg-gray-100 rounded focus:outline-none focus:ring-1 focus:ring-blue-500"
                        >
                          <ChevronUp className="w-4 h-4 text-gray-400" />
                        </button>
                        <button
                          onClick={() => handleDecrement(day, key)}
                          className="p-0.5 hover:bg-gray-100 rounded focus:outline-none focus:ring-1 focus:ring-blue-500"
                          disabled={value === 0}
                        >
                          <ChevronDown className={`w-4 h-4 ${value === 0 ? 'text-gray-200' : 'text-gray-400'}`} />
                        </button>
                      </div>
                      <span className="w-8 text-center font-medium">{value}</span>
                    </div>
                  </td>
                );
              })}
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default WeeklyScorecard;