import React, { useState } from 'react';
import { useForm } from 'react-hook-form';
import { X, UserCheck, ChevronDown, Calendar } from 'lucide-react';
import ContactCountEditor from './ContactCountEditor';
import type { Lead } from '../../types/leads';

interface EditLeadModalProps {
  isOpen: boolean;
  onClose: () => void;
  onSubmit: (id: string, updates: Partial<Lead>) => Promise<void>;
  onConvertToApplication: (lead: Lead) => Promise<void>;
  lead: Lead;
}

const PREDEFINED_SOURCES = [
  'Property Website',
  'Walk-in',
  'Phone',
  'Email',
  'Referral',
  'Social Media',
  'Zillow',
  'Apartments.com',
  'Rent.com',
  'Google',
  'PERQ',
  'Other'
] as const;

const EditLeadModal: React.FC<EditLeadModalProps> = ({
  isOpen,
  onClose,
  onSubmit,
  onConvertToApplication,
  lead
}) => {
  const [isCustomSource, setIsCustomSource] = useState(!PREDEFINED_SOURCES.includes(lead.source as any));
  const { register, handleSubmit, watch, setValue, formState: { errors, isSubmitting } } = useForm({
    defaultValues: {
      full_name: lead.full_name,
      email: lead.email || '',
      phone: lead.phone || '',
      preferred_size: lead.preferred_size || '',
      source: PREDEFINED_SOURCES.includes(lead.source as any) ? lead.source : '',
      customSource: !PREDEFINED_SOURCES.includes(lead.source as any) ? lead.source : '',
      notes: lead.notes || '',
      contact_count: lead.contact_count,
      status: lead.status
    }
  });

  const selectedSource = watch('source');

  const handleFormSubmit = async (data: any) => {
    try {
      const formData = {
        ...data,
        source: isCustomSource ? data.customSource : data.source
      };
      delete formData.customSource;
      
      await onSubmit(lead.id, formData);
      onClose();
    } catch (error) {
      console.error('Error updating lead:', error);
    }
  };

  const handleSourceChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
    const value = e.target.value;
    if (value === 'custom') {
      setIsCustomSource(true);
      setValue('source', '');
    } else {
      setIsCustomSource(false);
      setValue('source', value);
    }
  };

  const handleContactCountUpdate = async (newCount: number) => {
    try {
      await onSubmit(lead.id, { contact_count: newCount });
    } catch (error) {
      console.error('Error updating contact count:', error);
      throw error;
    }
  };

  if (!isOpen) return null;

  return (
    <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50 p-4">
      <div className="bg-white rounded-lg w-full max-w-4xl max-h-[90vh] overflow-y-auto">
        <div className="sticky top-0 bg-white px-6 py-4 border-b border-gray-200 flex justify-between items-center">
          <h2 className="text-xl font-bold">Edit Lead</h2>
          <button onClick={onClose} className="text-gray-500 hover:text-gray-700">
            <X size={24} />
          </button>
        </div>

        <form onSubmit={handleSubmit(handleFormSubmit)} className="p-6 space-y-6">
          <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
            {/* Basic Information */}
            <div className="space-y-4">
              <h3 className="text-lg font-medium text-gray-900">Basic Information</h3>
              
              <div>
                <label className="block text-sm font-medium text-gray-700">
                  Full Name *
                </label>
                <input
                  type="text"
                  {...register('full_name', { required: 'Name is required' })}
                  className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-blue-500 focus:ring-blue-500"
                />
                {errors.full_name && (
                  <p className="mt-1 text-sm text-red-600">
                    {errors.full_name.message as string}
                  </p>
                )}
              </div>

              <div>
                <label className="block text-sm font-medium text-gray-700">
                  Email
                </label>
                <input
                  type="email"
                  {...register('email', {
                    pattern: {
                      value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                      message: 'Invalid email address'
                    }
                  })}
                  className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-blue-500 focus:ring-blue-500"
                />
                {errors.email && (
                  <p className="mt-1 text-sm text-red-600">
                    {errors.email.message as string}
                  </p>
                )}
              </div>

              <div>
                <label className="block text-sm font-medium text-gray-700">
                  Phone
                </label>
                <input
                  type="tel"
                  {...register('phone', {
                    pattern: {
                      value: /^[\d\s-+()]*$/,
                      message: 'Invalid phone number format'
                    }
                  })}
                  className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-blue-500 focus:ring-blue-500"
                />
                {errors.phone && (
                  <p className="mt-1 text-sm text-red-600">
                    {errors.phone.message as string}
                  </p>
                )}
              </div>

              <div>
                <label className="block text-sm font-medium text-gray-700">
                  Preferred Size
                </label>
                <input
                  type="text"
                  {...register('preferred_size')}
                  placeholder="e.g., 2BR/2BA"
                  className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-blue-500 focus:ring-blue-500"
                />
              </div>
            </div>

            {/* Lead Details */}
            <div className="space-y-4">
              <h3 className="text-lg font-medium text-gray-900">Lead Details</h3>

              <div>
                <label className="block text-sm font-medium text-gray-700">
                  Lead Source *
                </label>
                <div className="mt-1 space-y-2">
                  <div className="relative">
                    <select
                      value={isCustomSource ? 'custom' : selectedSource}
                      onChange={handleSourceChange}
                      className="block w-full rounded-md border-gray-300 shadow-sm focus:border-blue-500 focus:ring-blue-500 pr-10"
                    >
                      <option value="">Select a source</option>
                      {PREDEFINED_SOURCES.map(source => (
                        <option key={source} value={source}>{source}</option>
                      ))}
                      <option value="custom">Other (Custom)</option>
                    </select>
                    <ChevronDown className="absolute right-3 top-1/2 transform -translate-y-1/2 text-gray-400 pointer-events-none" size={16} />
                  </div>
                  
                  {isCustomSource && (
                    <input
                      type="text"
                      {...register('customSource', { required: 'Custom source is required' })}
                      placeholder="Enter custom source"
                      className="block w-full rounded-md border-gray-300 shadow-sm focus:border-blue-500 focus:ring-blue-500"
                    />
                  )}
                </div>
                {errors.source && !isCustomSource && (
                  <p className="mt-1 text-sm text-red-600">
                    {errors.source.message as string}
                  </p>
                )}
                {errors.customSource && isCustomSource && (
                  <p className="mt-1 text-sm text-red-600">
                    {errors.customSource.message as string}
                  </p>
                )}
              </div>

              <div>
                <label className="block text-sm font-medium text-gray-700">
                  Status
                </label>
                <select
                  {...register('status')}
                  className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-blue-500 focus:ring-blue-500"
                >
                  <option value="active">Active</option>
                  <option value="inactive">Inactive</option>
                </select>
              </div>

              <div>
                <label className="block text-sm font-medium text-gray-700">
                  Contact History
                </label>
                <div className="mt-1 space-y-2">
                  <ContactCountEditor
                    count={lead.contact_count}
                    onUpdate={handleContactCountUpdate}
                  />
                  {lead.last_contact_date && (
                    <div className="flex items-center text-sm text-gray-500">
                      <Calendar className="w-4 h-4 mr-2" />
                      Last Contact: {new Date(lead.last_contact_date).toLocaleDateString()}
                    </div>
                  )}
                </div>
              </div>

              <div>
                <label className="block text-sm font-medium text-gray-700">
                  Created
                </label>
                <div className="mt-1 text-sm text-gray-500">
                  {new Date(lead.created_at).toLocaleDateString()}
                </div>
              </div>
            </div>
          </div>

          {/* Notes Section */}
          <div className="pt-4 border-t border-gray-200">
            <label className="block text-sm font-medium text-gray-700">
              Notes
            </label>
            <textarea
              {...register('notes')}
              rows={4}
              className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-blue-500 focus:ring-blue-500"
            />
          </div>

          {/* Action Buttons */}
          <div className="flex justify-between items-center pt-4 border-t border-gray-200">
            <button
              type="button"
              onClick={() => onConvertToApplication(lead)}
              className="flex items-center px-4 py-2 text-sm font-medium text-white bg-green-600 rounded-md hover:bg-green-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-green-500"
            >
              <UserCheck className="w-4 h-4 mr-2" />
              Convert to Application
            </button>
            <div className="flex space-x-2">
              <button
                type="button"
                onClick={onClose}
                className="px-4 py-2 text-sm font-medium text-gray-700 hover:text-gray-800"
              >
                Cancel
              </button>
              <button
                type="submit"
                className="px-4 py-2 text-sm font-medium text-white bg-blue-600 rounded-md hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500"
              >
                {isSubmitting ? 'Saving...' : 'Save Changes'}
              </button>
            </div>
          </div>
        </form>
      </div>
    </div>
  );
};

export default EditLeadModal;