import React from 'react';
import { Link } from 'react-router-dom';
import { ArrowLeft } from 'lucide-react';

const BackButton: React.FC = () => {
  return (
    <Link
      to="/"
      className="absolute top-4 left-4 inline-flex items-center px-4 py-2 text-sm font-medium text-gray-700 bg-white rounded-lg hover:bg-gray-50 hover:text-gray-900 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500 transition-colors"
      aria-label="Return to landing page"
    >
      <ArrowLeft className="w-4 h-4 mr-2" />
      Back
    </Link>
  );
};

export default BackButton;