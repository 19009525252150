import { createRoot } from 'react-dom/client';
import { BrowserRouter } from 'react-router-dom';
import App from './App';
import './index.css';
import { initializeDatabase } from './lib/supabase';

// Initialize database and render app
initializeDatabase().then(() => {
  createRoot(document.getElementById('root')!).render(
    <BrowserRouter>
      <App />
    </BrowserRouter>
  );
}).catch(error => {
  console.error('Failed to initialize application:', error);
  const root = document.getElementById('root');
  if (root) {
    root.innerHTML = `
      <div style="padding: 20px; text-align: center;">
        <h1 style="color: #e11d48; margin-bottom: 16px;">Connection Error</h1>
        <p style="color: #4b5563;">Unable to connect to the database. Please check your connection and try again.</p>
        <pre style="margin-top: 16px; padding: 12px; background: #f3f4f6; border-radius: 4px; text-align: left; font-size: 12px;">
          ${error.message}
        </pre>
      </div>
    `;
  }
});