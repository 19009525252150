import React, { memo, useCallback, useEffect, useRef, useState } from 'react';
import { ChevronDown, ChevronUp, Trash2, Paintbrush2, Axe, ChefHat, DoorOpen, Bath, Refrigerator, Sparkles, Clock, Save } from 'lucide-react';
import { Input, Dropdown } from '../ui/TurnBoardComponents';
import DatePicker from 'react-datepicker';
import { useScrollPosition } from '../../hooks/useScrollPosition';
import type { Unit } from '../../types/units';

interface CompactUnitCardProps {
  unit: Unit;
  isExpanded: boolean;
  onToggleExpand: () => void;
  onUnitChange: (field: keyof Unit, value: any) => void;
}

const CompactUnitCard = memo(({ 
  unit, 
  isExpanded, 
  onToggleExpand, 
  onUnitChange 
}: CompactUnitCardProps) => {
  const { updateScroll, cleanup } = useScrollPosition();
  const inputTimeoutRef = useRef<NodeJS.Timeout>();
  const [isDirty, setIsDirty] = useState(false);
  const [isSaving, setIsSaving] = useState(false);
  const [localUnit, setLocalUnit] = useState(unit);

  useEffect(() => {
    setLocalUnit(unit);
  }, [unit]);

  const handleInputChange = useCallback((field: keyof Unit, value: any) => {
    setLocalUnit(prev => ({ ...prev, [field]: value }));
    setIsDirty(true);
  }, []);

  const handleSave = async () => {
    try {
      setIsSaving(true);
      // Apply all changes at once
      Object.keys(localUnit).forEach(key => {
        if (localUnit[key as keyof Unit] !== unit[key as keyof Unit]) {
          onUnitChange(key as keyof Unit, localUnit[key as keyof Unit]);
        }
      });
      setIsDirty(false);
    } catch (error) {
      console.error('Error saving changes:', error);
    } finally {
      setIsSaving(false);
    }
  };

  useEffect(() => {
    return () => {
      if (inputTimeoutRef.current) {
        clearTimeout(inputTimeoutRef.current);
      }
      cleanup();
    };
  }, [cleanup]);

  const progress = (() => {
    const fields = ['bulkTrash', 'paint', 'carpetFlooring', 'countertops', 'cabinets', 'tubReglaze', 'appliances', 'clean'];
    const completedTasks = fields.filter(field => localUnit[field as keyof Unit] === 'Done').length;
    return (completedTasks / fields.length) * 100;
  })();

  const isComplete = progress === 100;

  const statusFields = [
    { key: 'bulkTrash', label: 'Bulk Trash', icon: Trash2 },
    { key: 'paint', label: 'Paint', icon: Paintbrush2 },
    { key: 'carpetFlooring', label: 'Carpet/Flooring', icon: Axe },
    { key: 'countertops', label: 'Countertops', icon: ChefHat },
    { key: 'cabinets', label: 'Cabinets', icon: DoorOpen },
    { key: 'tubReglaze', label: 'Tub Reglaze', icon: Bath },
    { key: 'appliances', label: 'Appliances', icon: Refrigerator },
    { key: 'clean', label: 'Clean', icon: Sparkles }
  ];

  const remainingTasks = statusFields.filter(({ key }) => 
    localUnit[key as keyof Unit] === 'Not Started' || localUnit[key as keyof Unit] === 'Scheduled'
  );

  const handleExpandClick = useCallback((e: React.MouseEvent) => {
    e.preventDefault();
    updateScroll(() => {
      onToggleExpand();
    });
  }, [onToggleExpand, updateScroll]);

  const handleDateChange = useCallback((date: Date | null) => {
    handleInputChange('moveInDate', date ? date.toISOString().split('T')[0] : null);
  }, [handleInputChange]);

  return (
    <div className={`p-3 transition-colors ${isComplete ? 'bg-green-200' : ''}`}>
      <div className="flex items-center gap-4">
        <div className="grid grid-cols-5 gap-4 flex-1">
          {!isExpanded ? (
            <>
              <div className="py-2 px-3 rounded-md text-gray-700 font-bold">
                {localUnit.aptNumber || '—'}
              </div>
              <div className="py-2 px-3 rounded-md text-gray-700">
                {localUnit.moveInDate ? new Date(localUnit.moveInDate).toLocaleDateString() : '—'}
              </div>
              <div className="py-2 px-3 rounded-md text-gray-700 truncate">
                {localUnit.futureResident || '—'}
              </div>
              <div className="flex items-center gap-1 overflow-hidden">
                {remainingTasks.map(({ key, icon: Icon }) => {
                  const status = localUnit[key as keyof Unit];
                  return (
                    <div 
                      key={key}
                      className={`p-1 rounded ${
                        status === 'Scheduled' ? 'bg-yellow-100' : 'bg-gray-50'
                      }`}
                      title={`${key.replace(/([A-Z])/g, ' $1').trim()}: ${status}`}
                    >
                      <Icon 
                        className={`w-4 h-4 ${
                          status === 'Scheduled' ? 'text-yellow-600' : 'text-gray-500'
                        }`}
                      />
                    </div>
                  );
                })}
              </div>
            </>
          ) : (
            <>
              <div>
                <Input
                  type="text"
                  value={localUnit.aptNumber}
                  onChange={(e) => handleInputChange('aptNumber', e.target.value)}
                  placeholder="Apt #"
                  className="font-bold"
                />
              </div>
              <div>
                <DatePicker
                  selected={localUnit.moveInDate ? new Date(localUnit.moveInDate) : null}
                  onChange={handleDateChange}
                  dateFormat="MM/dd/yy"
                  placeholderText="Move-in Date"
                  className="w-full border rounded-md px-3 py-2"
                  isClearable
                />
              </div>
              <div>
                <Input
                  type="text"
                  value={localUnit.futureResident}
                  onChange={(e) => handleInputChange('futureResident', e.target.value)}
                  placeholder="Future Resident"
                />
              </div>
              <div className="flex items-center gap-1">
                {remainingTasks.length > 0 ? (
                  <div className="flex items-center text-sm text-gray-600">
                    <Clock className="w-4 h-4 mr-1" />
                    {remainingTasks.length} remaining
                  </div>
                ) : (
                  <div className="flex items-center text-sm text-green-600">
                    <Sparkles className="w-4 h-4 mr-1" />
                    All complete
                  </div>
                )}
              </div>
            </>
          )}
          <div className="flex items-center gap-2">
            <div className="flex-1 h-2 bg-gray-200 rounded-full">
              <div
                className={`h-2 rounded-full transition-all duration-300 ${
                  isComplete ? 'bg-green-500' : 'bg-blue-600'
                }`}
                style={{ width: `${progress}%` }}
              />
            </div>
            <span className="text-xs text-gray-500 whitespace-nowrap">{Math.round(progress)}%</span>
            <button
              onClick={handleExpandClick}
              className="p-1 hover:bg-gray-100 rounded-full transition-colors"
            >
              {isExpanded ? <ChevronUp size={20} /> : <ChevronDown size={20} />}
            </button>
          </div>
        </div>
      </div>

      {isExpanded && (
        <div className="mt-4">
          <div className="grid grid-cols-2 sm:grid-cols-4 gap-4">
            {statusFields.map(({ key, label, icon: Icon }) => (
              <div key={key}>
                <label className="block text-xs font-medium text-gray-700 mb-1 flex items-center">
                  <Icon className="w-4 h-4 mr-1.5 text-gray-500" />
                  {label}
                </label>
                <Dropdown
                  value={localUnit[key as keyof Unit] as string}
                  onChange={(e) => handleInputChange(key as keyof Unit, e.target.value)}
                  options={['Not Started', 'Scheduled', 'Done']}
                  onVendorChange={(value) => handleInputChange(`${key}Vendor` as keyof Unit, value)}
                  onScheduledDateChange={(date) => handleInputChange(`${key}Scheduled` as keyof Unit, date?.toISOString().split('T')[0])}
                  onCostChange={(value) => handleInputChange(`${key}Cost` as keyof Unit, value)}
                  vendorName={localUnit[`${key}Vendor` as keyof Unit] as string}
                  scheduledDate={localUnit[`${key}Scheduled` as keyof Unit] as string}
                  estimatedCost={localUnit[`${key}Cost` as keyof Unit] as number}
                />
              </div>
            ))}
          </div>
          <div className="mt-4">
            <label className="block text-xs font-medium text-gray-700 mb-1">Notes</label>
            <textarea
              value={localUnit.notes}
              onChange={(e) => handleInputChange('notes', e.target.value)}
              className="w-full p-2 border rounded text-sm"
              rows={2}
            />
          </div>
          <div className="mt-4 flex justify-end">
            <button
              onClick={handleSave}
              disabled={!isDirty || isSaving}
              className={`
                flex items-center px-4 py-2 rounded-md text-white
                ${isDirty ? 'bg-blue-600 hover:bg-blue-700' : 'bg-gray-400 cursor-not-allowed'}
                transition-colors
              `}
            >
              <Save className="w-4 h-4 mr-2" />
              {isSaving ? 'Saving...' : 'Save Changes'}
            </button>
          </div>
        </div>
      )}
    </div>
  );
});

CompactUnitCard.displayName = 'CompactUnitCard';

export default CompactUnitCard;