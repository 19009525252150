const PROPERTY_CODE_LENGTH = 8; // Not counting the hyphen
const CODE_PATTERN = /^[A-Z0-9]{4}-[A-Z0-9]{4}$/;

export const generatePropertyCode = (): string => {
  const chars = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789';
  let code = '';
  
  // Generate first 4 characters
  for (let i = 0; i < 4; i++) {
    code += chars.charAt(Math.floor(Math.random() * chars.length));
  }
  
  code += '-';
  
  // Generate last 4 characters
  for (let i = 0; i < 4; i++) {
    code += chars.charAt(Math.floor(Math.random() * chars.length));
  }
  
  return code;
};

export const isValidPropertyCode = (code: string): boolean => {
  return CODE_PATTERN.test(code);
};

export const formatPropertyCode = (code: string): string => {
  const cleaned = code.replace(/[^A-Z0-9]/g, '');
  if (cleaned.length > 4) {
    return `${cleaned.slice(0, 4)}-${cleaned.slice(4, 8)}`;
  }
  return cleaned;
};