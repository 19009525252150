import React from 'react';
import { User, Crown, Edit2, Eye, Trash2 } from 'lucide-react';
import type { Collaborator } from '../../types/collaboration';

interface CollaboratorsListProps {
  collaborators: Collaborator[];
  onRemove: (collaboratorId: string) => void;
  onUpdateRole: (collaboratorId: string, newRole: Collaborator['role']) => void;
}

const CollaboratorsList: React.FC<CollaboratorsListProps> = ({
  collaborators,
  onRemove,
  onUpdateRole
}) => {
  const getRoleIcon = (role: Collaborator['role']) => {
    switch (role) {
      case 'owner':
        return <Crown className="w-4 h-4 text-yellow-500" />;
      case 'editor':
        return <Edit2 className="w-4 h-4 text-blue-500" />;
      case 'viewer':
        return <Eye className="w-4 h-4 text-gray-500" />;
    }
  };

  return (
    <div className="space-y-4">
      {collaborators.map((collaborator) => (
        <div
          key={collaborator.id}
          className="flex items-center justify-between p-4 bg-white rounded-lg border border-gray-200"
        >
          <div className="flex items-center space-x-3">
            <div className="p-2 bg-gray-100 rounded-full">
              <User className="w-5 h-5 text-gray-600" />
            </div>
            <div>
              <div className="flex items-center space-x-2">
                <span className="font-medium">{collaborator.userId}</span>
                {getRoleIcon(collaborator.role)}
              </div>
              <p className="text-sm text-gray-500">
                Joined {new Date(collaborator.joinedAt).toLocaleDateString()}
              </p>
            </div>
          </div>

          <div className="flex items-center space-x-2">
            {collaborator.role !== 'owner' && (
              <>
                <select
                  value={collaborator.role}
                  onChange={(e) => onUpdateRole(collaborator.id, e.target.value as Collaborator['role'])}
                  className="text-sm border-gray-300 rounded-md focus:ring-blue-500 focus:border-blue-500"
                >
                  <option value="editor">Editor</option>
                  <option value="viewer">Viewer</option>
                </select>
                <button
                  onClick={() => onRemove(collaborator.id)}
                  className="p-1 text-red-600 hover:bg-red-50 rounded-full"
                  title="Remove collaborator"
                >
                  <Trash2 className="w-4 h-4" />
                </button>
              </>
            )}
          </div>
        </div>
      ))}

      {collaborators.length === 0 && (
        <p className="text-center text-gray-500 py-4">
          No collaborators yet
        </p>
      )}
    </div>
  );
};

export default CollaboratorsList;