import React, { useState, useEffect } from 'react';
import { Users, Plus } from 'lucide-react';
import { useProperty } from '../../contexts/PropertyContext';
import { supabase } from '../../lib/supabase';
import PropertyCodeDisplay from '../collaboration/PropertyCodeDisplay';
import CollaboratorsList from '../collaboration/CollaboratorsList';
import JoinPropertyForm from '../collaboration/JoinPropertyForm';
import type { Collaborator } from '../../types/collaboration';

const CollaborationSettings: React.FC = () => {
  const { selectedPropertyId } = useProperty();
  const [collaborators, setCollaborators] = useState<Collaborator[]>([]);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState<string | null>(null);
  const [showJoinForm, setShowJoinForm] = useState(false);

  useEffect(() => {
    const fetchCollaborators = async () => {
      if (!selectedPropertyId) return;

      try {
        setIsLoading(true);
        setError(null);

        const { data, error: fetchError } = await supabase
          .from('property_collaborators')
          .select('*')
          .eq('property_id', selectedPropertyId)
          .order('joined_at', { ascending: false });

        if (fetchError) throw fetchError;

        setCollaborators(data || []);
      } catch (err) {
        console.error('Error fetching collaborators:', err);
        setError('Failed to load collaborators');
      } finally {
        setIsLoading(false);
      }
    };

    fetchCollaborators();
  }, [selectedPropertyId]);

  const handleRemoveCollaborator = async (collaboratorId: string) => {
    if (!selectedPropertyId) return;

    try {
      const { error: removeError } = await supabase
        .from('property_collaborators')
        .delete()
        .eq('id', collaboratorId)
        .eq('property_id', selectedPropertyId);

      if (removeError) throw removeError;

      setCollaborators(prev => 
        prev.filter(c => c.id !== collaboratorId)
      );
    } catch (err) {
      console.error('Error removing collaborator:', err);
      setError('Failed to remove collaborator');
    }
  };

  const handleUpdateRole = async (collaboratorId: string, newRole: Collaborator['role']) => {
    if (!selectedPropertyId) return;

    try {
      const { error: updateError } = await supabase
        .from('property_collaborators')
        .update({ role: newRole })
        .eq('id', collaboratorId)
        .eq('property_id', selectedPropertyId);

      if (updateError) throw updateError;

      setCollaborators(prev =>
        prev.map(c =>
          c.id === collaboratorId ? { ...c, role: newRole } : c
        )
      );
    } catch (err) {
      console.error('Error updating collaborator role:', err);
      setError('Failed to update collaborator role');
    }
  };

  if (!selectedPropertyId) {
    return (
      <div className="text-center py-8 text-gray-500">
        Please select a property to manage collaborators
      </div>
    );
  }

  return (
    <div className="bg-white rounded-lg shadow-sm border border-gray-200 p-6">
      <div className="flex items-center justify-between mb-6">
        <div className="flex items-center">
          <Users className="w-5 h-5 text-blue-600 mr-2" />
          <h2 className="text-xl font-semibold">Collaboration</h2>
        </div>
        <button
          onClick={() => setShowJoinForm(!showJoinForm)}
          className="flex items-center px-4 py-2 text-sm bg-blue-600 text-white rounded-lg hover:bg-blue-700 transition-colors"
        >
          <Plus className="w-4 h-4 mr-2" />
          Join Property
        </button>
      </div>

      {error && (
        <div className="mb-4 p-3 bg-red-50 border border-red-200 rounded text-red-600 text-sm">
          {error}
        </div>
      )}

      {showJoinForm ? (
        <div className="mb-6">
          <h3 className="text-lg font-medium mb-4">Join a Property</h3>
          <JoinPropertyForm />
          <button
            onClick={() => setShowJoinForm(false)}
            className="mt-4 text-sm text-gray-600 hover:text-gray-800"
          >
            Cancel
          </button>
        </div>
      ) : (
        <div className="space-y-6">
          <div>
            <h3 className="text-lg font-medium mb-4">Property Access Code</h3>
            <PropertyCodeDisplay propertyId={selectedPropertyId} />
          </div>

          <div>
            <h3 className="text-lg font-medium mb-4">Collaborators</h3>
            {isLoading ? (
              <div className="animate-pulse space-y-4">
                {[...Array(3)].map((_, i) => (
                  <div key={i} className="h-16 bg-gray-100 rounded-lg"></div>
                ))}
              </div>
            ) : (
              <CollaboratorsList
                collaborators={collaborators}
                onRemove={handleRemoveCollaborator}
                onUpdateRole={handleUpdateRole}
              />
            )}
          </div>
        </div>
      )}
    </div>
  );
};

export default CollaborationSettings;