import React, { useState, useEffect } from 'react';
import { Copy, Check } from 'lucide-react';
import { usePropertyCollaboration } from '../../hooks/usePropertyCollaboration';

interface PropertyCodeDisplayProps {
  propertyId: string;
}

const PropertyCodeDisplay: React.FC<PropertyCodeDisplayProps> = ({ propertyId }) => {
  const [propertyCode, setPropertyCode] = useState<string | null>(null);
  const [copied, setCopied] = useState(false);
  const { getPropertyCode, isLoading, error } = usePropertyCollaboration(propertyId);

  useEffect(() => {
    const fetchCode = async () => {
      const code = await getPropertyCode();
      setPropertyCode(code);
    };
    fetchCode();
  }, [getPropertyCode]);

  const handleCopy = async () => {
    if (propertyCode) {
      await navigator.clipboard.writeText(propertyCode);
      setCopied(true);
      setTimeout(() => setCopied(false), 2000);
    }
  };

  if (isLoading) {
    return (
      <div className="animate-pulse bg-gray-100 h-12 rounded-md"></div>
    );
  }

  if (error) {
    return (
      <div className="text-red-600 text-sm">
        {error}
      </div>
    );
  }

  return (
    <div className="bg-gray-50 p-4 rounded-lg border border-gray-200">
      <div className="flex justify-between items-center">
        <div>
          <h3 className="text-sm font-medium text-gray-700">Property Code</h3>
          <p className="mt-1 text-2xl font-mono">{propertyCode}</p>
        </div>
        <button
          onClick={handleCopy}
          className="p-2 text-gray-500 hover:text-gray-700 rounded-md hover:bg-gray-100"
          title="Copy code"
        >
          {copied ? (
            <Check className="w-5 h-5 text-green-500" />
          ) : (
            <Copy className="w-5 h-5" />
          )}
        </button>
      </div>
      <p className="mt-2 text-sm text-gray-500">
        Share this code with collaborators to grant them access to this property.
      </p>
    </div>
  );
};

export default PropertyCodeDisplay;